import _ from "lodash";
import { updateSearchPanel, loadStation, updateStationDetails, updateCustomRegion } from "reducers/layoutSlice";
import {
    getAsyncResponse,
    getBestPaths,
    resetSearch,
    setAllowedPipelines,
    setAvoidSteps,
    setCommittedRates,
    setCommodityGrade,
    setCommodityType,
    setDestination,
    setExcludedPipelines,
    setLoading,
    setPage,
    setMaxPipelines,
    setOrigin,
    setSelectedCommodities,
    setSelectedJurisdiction,
    setSelectedProducts,
    setVolume,
} from "reducers/searchSlice";
import { clearSelectedRoute, getSelectedPath } from "reducers/bookmarksSlice";
import { AUTOCOMPLETE_TYPES } from "components/searcher/searchAutocomplete";

export const UpdateHash = (history, parts) => {
    const params = parts.join("/");
    if (!params) return;
    const path = `/map/${params}`;
    if (history.location.pathname !== path) {
        history.push(path);
    }
};

export const loadSearch = (location, dispatch) => {
    const query = location?.search?.slice(1);
    const params = query.split("&").reduce((obj, param) => {
        const [key, val] = param.split("=");
        obj[key] = isNaN(val) ? val : +val;
        return { ...obj };
    }, {});

    let origin = null;
    let destination = null;

    if (params.origin_polygon) {
        try {
            params.origin_polygon = JSON.parse(decodeURIComponent(params.origin_polygon));
            params.origin_polygon = {
                ...params.origin_polygon,
                name: params.origin_name || "Custom Region",
            };
            dispatch(
                updateCustomRegion({
                    type: AUTOCOMPLETE_TYPES.ORIGIN,
                    geometry: params.origin_polygon,
                }),
            );
            dispatch(
                setOrigin({
                    id: "custom",
                    name: params.origin_polygon.name || "Custom Region",
                    type: "polygon",
                    geometry: params.origin_polygon,
                }),
            );
        } catch (err) {
            params.origin_polygon = undefined;
            console.error(err);
        }
    } else {
        origin = {
            id: +params.origin_station || +params.origin_station_group,
            type: params.origin_station ? "station" : params.origin_station_group ? "station_group" : "",
        };
        origin?.id && dispatch(setOrigin(origin));
    }

    if (params.destination_polygon) {
        try {
            params.destination_polygon = JSON.parse(decodeURIComponent(params.destination_polygon));
            params.destination_polygon = {
                ...params.destination_polygon,
                name: params.destination_name || "Custom Region",
            };
            dispatch(
                updateCustomRegion({
                    type: AUTOCOMPLETE_TYPES.DESTINATION,
                    geometry: params.destination_polygon,
                }),
            );
            dispatch(
                setDestination({
                    id: "custom",
                    name: params.destination_polygon.name || "Custom Region",
                    type: "polygon",
                    geometry: params.destination_polygon,
                }),
            );
        } catch (err) {
            params.destination_polygon = undefined;
            console.error(err);
        }
    } else {
        destination = {
            id: +params.destination_station || +params.destination_station_group,
            type: params.destination_station ? "station" : params.destination_station_group ? "station_group" : "",
        };
        destination?.id && dispatch(setDestination(destination));
    }

    if (params.allowed_pipelines) {
        if (_.isString(params.allowed_pipelines)) {
            params.allowed_pipelines = params.allowed_pipelines.split(",");
            dispatch(setAllowedPipelines(params.allowed_pipelines.map((p) => +p)));
        } else {
            params.allowed_pipelines = [params.allowed_pipelines];
            dispatch(setAllowedPipelines(params.allowed_pipelines));
        }
    }

    if (params.forbidden_pipelines) {
        if (_.isString(params.forbidden_pipelines)) {
            params.forbidden_pipelines = params.forbidden_pipelines.split(",");
            dispatch(setExcludedPipelines(params.forbidden_pipelines.map((p) => +p)));
        } else {
            params.forbidden_pipelines = [params.forbidden_pipelines];
            dispatch(setExcludedPipelines(params.forbidden_pipelines));
        }
    }

    if (params.jurisdiction) {
        if (_.isString(params.jurisdiction)) {
            params.jurisdiction = params.jurisdiction.split(",");
            dispatch(setSelectedJurisdiction(params.jurisdiction.map((p) => +p)));
        } else {
            params.jurisdiction = [params.jurisdiction];
            dispatch(setSelectedJurisdiction(params.jurisdiction));
        }
    }

    if (params.commodities) {
        if (_.isString(params.commodities)) {
            params.commodities = params.commodities.split(",");
            dispatch(setSelectedCommodities(params.commodities.map((p) => +p)));
        } else {
            params.commodities = [params.commodities];
            dispatch(setSelectedCommodities(params.commodities));
        }
    }

    if (params.specific_products) {
        if (_.isString(params.specific_products)) {
            params.specific_products = params.specific_products.split(",");
            dispatch(setSelectedProducts(params.specific_products.map((p) => +p)));
        } else {
            params.specific_products = [params.specific_products];
            dispatch(setSelectedProducts(params.specific_products));
        }
    }
    params.committed && dispatch(setCommittedRates(params.committed));
    params.commodity_grade && dispatch(setCommodityGrade(params.commodity_grade));
    +params.commodity_types && dispatch(setCommodityType(+params.commodity_types));
    +params.commodity && dispatch(setCommodityType(+params.commodity));
    +params.volume && dispatch(setVolume(+params.volume));
    +params.max_pipelines && dispatch(setMaxPipelines(+params.max_pipelines));
    if (params.fssg_orig !== undefined || params.fssg_dest !== undefined) {
        dispatch(setAvoidSteps({ origin: params.fssg_orig === "true", destination: params.fssg_dest === "true" }));
    }
    dispatch(updateSearchPanel(true));
    dispatch(updateStationDetails());
    if (params.page) {
        dispatch(setPage(+params.page));
    }
    if (
        (origin?.id || destination?.id || params.destination_polygon || params.origin_polygon) &&
        +params.commodity_types
    ) {
        dispatch(setLoading(true));
        if (params.max_path_steps) {
            params.segment = true;
        }
        dispatch(getBestPaths(params));
    } else if (params.id) {
        dispatch(setLoading(true));
        dispatch(getAsyncResponse(params));
    } else if (params.tariff_id && +params.commodity_types) {
        params.segment = true;
        params.max_path_steps = 2;
        if (!params.allow_current_rates && !params.allow_future_rates) {
            params.allow_future_rates = "true";
            params.allow_current_rates = "true";
        } else {
            if (params.allow_future_rates != "false") {
                params.allow_future_rates = "true";
            }
            if (params.allow_current_rates != "false") {
                params.allow_current_rates = "true";
            }
        }
        dispatch(setLoading(true));
        dispatch(getBestPaths(params));
    }
};

const loadBookmark = (id, dispatch) => {
    dispatch(updateSearchPanel(true));
    dispatch(resetSearch());
    dispatch(clearSelectedRoute());
    dispatch(getSelectedPath(id));
};

const loadStationDetails = (id, dispatch) => {
    dispatch(loadStation(id));
    dispatch(updateSearchPanel(true));
    dispatch(resetSearch());
};

const setDefaults = (dispatch) => {
    dispatch(updateStationDetails());
    dispatch(updateSearchPanel(false));
    dispatch(resetSearch());
    dispatch(clearSelectedRoute());
};

export const loadFromHash = (location, dispatch) => {
    const path = location.pathname.slice(1).split("/");
    const page = path.shift();
    if (page !== "map") {
        return;
    }
    const type = path.shift();
    const [id] = path;
    switch (type) {
        case "path":
        case "search":
            loadSearch(location, dispatch);
            break;

        case "bookmark":
            loadBookmark(id, dispatch);
            break;

        case "station":
            loadStationDetails(id, dispatch);
            break;

        default:
            setDefaults(dispatch);
    }
};

export default UpdateHash;
