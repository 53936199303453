import React from "react";

import { Link } from "react-router-dom";
import { Button } from "antd";
import { ReactComponent as Plus } from "assets/Icon/Plus.svg";

import "./ActionLink.less";

export default function ActionLink({ title, url, onClick = () => {} }) {
    const getButton = () => (
        <Button className="action-link__button" type="link" onClick={onClick}>
            <Plus /> {title}
        </Button>
    );

    return <div className="action-link">{url ? <Link to={url}>{getButton()}</Link> : getButton()}</div>;
}
