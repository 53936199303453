import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiLayers from "../api/api.layers";

export const getLayerViews = createAsyncThunk("map/getLayerViews", async () => {
    const response = await ApiLayers.getLayerViews();
    return response.data;
});

export const mapSlice = createSlice({
    name: "map",
    initialState: {
        MVTInfo: null,
        MVTCategoriesInfo: null,
        layerViews: null,
        selectedLayerView: null,
        availableCategories: [],
        hiddenLayers: [],
    },
    reducers: {
        setMVTInfo: (state, { payload }) => {
            state.MVTInfo = payload;
        },
        addHiddenLayer: (state, { payload }) => {
            if (state.hiddenLayers.indexOf(payload) === -1) {
                state.hiddenLayers = [...state.hiddenLayers, payload];
            }
        },
        removeHiddenLayer: (state, { payload }) => {
            if (state.hiddenLayers.indexOf(payload) > -1) {
                const layers = [...state.hiddenLayers];
                layers.splice(state.hiddenLayers.indexOf(payload), 1);
                state.hiddenLayers = layers;
            }
        },
        setMVTCategoriesInfo: (state, { payload }) => {
            state.MVTCategoriesInfo = payload;
        },
        setSelectedLayerView: (state, { payload }) => {
            state.selectedLayerView = payload;
        },
        setAvailableCategoriesInfo: (state, { payload }) => {
            state.availableCategories = payload;
        },
    },
    extraReducers: {
        [getLayerViews.fulfilled]: (state, { payload }) => {
            state.layerViews = payload.results;
        },
    },
});

export const {
    setMVTInfo,
    setMVTCategoriesInfo,
    setSelectedLayerView,
    setAvailableCategoriesInfo,
    removeHiddenLayer,
    addHiddenLayer,
} = mapSlice.actions;

export default mapSlice.reducer;
