import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined, AimOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, Input, Row } from "antd";
import * as turf from "@turf/turf";

import { ReactComponent as Directions } from "assets/Icon/directions.svg";
import { ReactComponent as Pencil } from "assets/Icon/Pencil.svg";
import { ReactComponent as Station } from "assets/Icon/Station.svg";
import { ReactComponent as Suggestion } from "assets/Icon/Destination.svg";

import {
    setDrawingConfig,
    updateShowDrawOnMap,
    updateSelectCountyOnMap,
    updateCustomRegion,
} from "reducers/layoutSlice";
import { setDestination, setOrigin, setSearchSuggestions, loadStations } from "reducers/searchSlice";
import SearchPill from "./searchPill";
import ApiLiquids from "api/api.liquids";
import ApiMapbox from "api/api.mapbox";
import { store as mapStore } from "store/mapStore";
import { trackEvent } from "api/analytics";

export const AUTOCOMPLETE_TYPES = {
    ORIGIN: "origin",
    DESTINATION: "destination",
};

function SearchAutocomplete({
    type = AUTOCOMPLETE_TYPES.ORIGIN,
    setShowSearch,
    defaultValue,
    onSelect,
    onClear,
    isMainSearch = false,
    placeholder,
}) {
    const dispatch = useDispatch();
    const {
        state: { drawObject },
        dispatch: mapDispatch,
    } = React.useContext(mapStore);

    const [searchValue, setSearchValue] = useState(null);
    const [stationGroups, setStationGroups] = useState([]);
    const [savedStations, setSavedStations] = useState([]);
    const [suggestedByArbo, setSuggestedByArbo] = useState([]);
    const [suggestedStations, setSuggestedStations] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [pills, setPills] = useState([]);
    const [places, setPlaces] = useState([]);

    const [searchTimeout, updateSearchTimeout] = useState(null);

    const showDrawOnMap = useSelector((state) => state.layout.showDrawOnMap);
    const { searchSuggestions, stations } = useSelector(({ search }) => search);
    const customRegions = useSelector((state) => state.layout.customRegions);

    const { bookmarks } = useSelector(({ bookmarks }) => bookmarks);

    useEffect(() => {
        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPills(defaultValue ? [defaultValue] : []);
    }, [defaultValue]);

    const handleClear = (e) => {
        if (onClear) {
            onClear(e);
        }
        setSearchValue(null);
        setPills([]);
        trackEvent(`Map Search - Clear ${type}`);

        if (customRegions[AUTOCOMPLETE_TYPES.DESTINATION] || customRegions[AUTOCOMPLETE_TYPES.ORIGIN]) {
            if (drawObject) {
                try {
                    drawObject.deleteAll();
                } catch (exc) {}
            }
            mapDispatch({
                type: "set draw filter",
                payload: undefined,
            });
        }

        if (type === AUTOCOMPLETE_TYPES.DESTINATION && customRegions[AUTOCOMPLETE_TYPES.DESTINATION]) {
            dispatch(
                updateCustomRegion({
                    type: AUTOCOMPLETE_TYPES.DESTINATION,
                    geometry: null,
                }),
            );
            if (customRegions[AUTOCOMPLETE_TYPES.ORIGIN]) {
                drawObject.add(customRegions[AUTOCOMPLETE_TYPES.ORIGIN]);
                mapDispatch({
                    type: "set draw filter",
                    payload: ["within", customRegions[AUTOCOMPLETE_TYPES.ORIGIN]],
                });
            }
        } else if (customRegions[AUTOCOMPLETE_TYPES.ORIGIN]) {
            drawObject.deleteAll();
            dispatch(
                updateCustomRegion({
                    type: AUTOCOMPLETE_TYPES.ORIGIN,
                    geometry: null,
                }),
            );
            if (customRegions[AUTOCOMPLETE_TYPES.DESTINATION]) {
                drawObject.add(customRegions[AUTOCOMPLETE_TYPES.DESTINATION]);
                mapDispatch({
                    type: "set draw filter",
                    payload: ["within", customRegions[AUTOCOMPLETE_TYPES.DESTINATION]],
                });
            }
        }
    };

    const doSearch = async (value) => {
        trackEvent(`Map Search - ${type} Change`, { value });
        ApiMapbox.getCountyByName(value).then((resp) => {
            setPlaces(resp.features);
        });
        if (value && value.length > 2) {
            const searchResult = await ApiLiquids.getSearch(value);
            dispatch(setSearchSuggestions(searchResult.data));
        }
    };

    useEffect(() => {
        if (searchValue && searchSuggestions) {
            setSuggestedStations(searchSuggestions.stations?.features);
            setStationGroups(searchSuggestions.station_groups?.features);

            setSavedStations([]);
            setSuggestedByArbo([]);
        } else {
            setSuggestions([]);
            setStationGroups([]);
            setSuggestedStations([]);

            if (bookmarks?.station?.length && stations?.indexed) {
                const saved = bookmarks.station.map(({ object_id }) => stations?.indexed[object_id]);
                setSavedStations(saved);
            }

            if (bookmarks?.search?.length) {
                const publicSearches = bookmarks.search.filter((search) => search.properties.public);
                if (isMainSearch && publicSearches.length) {
                    setSuggestedByArbo(publicSearches);
                }
            }
        }
    }, [searchSuggestions, bookmarks, stations, searchValue, isMainSearch]);

    const handleSearch = useCallback(
        (value) => {
            setSearchValue(value);
            if (searchTimeout) {
                clearTimeout(searchTimeout);
                updateSearchTimeout(null);
            }
            if (value === "") {
                type === "origin" && dispatch(setOrigin());
                type === "destination" && dispatch(setDestination());
                return;
            }

            if (value && value.length) {
                updateSearchTimeout(
                    setTimeout(() => {
                        doSearch(value);
                    }, 1000),
                );
            }
        },
        [searchTimeout],
    );

    const handleSelect = (value, { object }) => {
        let coords = null;
        try {
            if (object?.geometry?.coordinates) {
                coords = turf.center(object.geometry)?.geometry.coordinates;
            }
        } catch (e) {
            console.error(e);
        }
        if (onSelect) {
            onSelect(object);
        }
        if (object.type === "suggestion") {
            coords = [object.position.lng, object.position.lat];
            setSearchValue(object?.properties?.name);
        }

        if (coords) {
            let [lng, lat] = coords;
            const BOUNDS_DIFF = 0.5;
            const bounds = [
                [lng - BOUNDS_DIFF, lat - BOUNDS_DIFF],
                [lng + BOUNDS_DIFF, lat + BOUNDS_DIFF],
            ];
            mapDispatch({ type: "set bounds", payload: bounds });
        }
        if (object.mapView) {
            const bounds = [
                [object.mapView.east, object.mapView.north],
                [object.mapView.west, object.mapView.south],
            ];
            mapDispatch({ type: "set bounds", payload: bounds });
        }
    };

    const onDraw = () => {
        dispatch(
            updateShowDrawOnMap({
                type,
            }),
        );

        dispatch(
            setDrawingConfig({
                isDrawing: true,
            }),
        );

        const { features } = drawObject.getAll();
        const filtered = features.filter(({ geometry }) => geometry.coordinates[0].length > 1); // Filtering drawn polygon without geometry, weird plugin behavior

        if (filtered.length === 1) {
            mapDispatch({
                type: "set draw filter",
                payload: undefined,
            });
        }
    };

    const onSelectCounty = () => {
        dispatch(
            updateSelectCountyOnMap({
                type,
            }),
        );
    };

    const renderLink = () => {
        return (
            <Button type="link" block={true} onClick={onDraw} className="draw-map-link">
                <Pencil />
                DRAW ON MAP
            </Button>
        );
    };

    const renderCountyLink = () => {
        return (
            <Button type="link" block={true} onClick={onSelectCounty} className="draw-map-link">
                <AimOutlined className="ml-2" />
                SELECT COUNTY
            </Button>
        );
    };

    const renderStations = (stationsList, key) => {
        return stationsList
            .map((station) => {
                return {
                    value: `${station.id}_station: ${station.properties?.name}`,
                    object: {
                        ...station,
                        type: "station",
                    },
                    label: (
                        <div className="auto-complete-option">
                            <label>
                                <strong>{station.properties?.name}</strong>{" "}
                                <i>{station.properties[`pipelines_as_${type}`]?.slice()?.shift()?.name}</i>
                            </label>
                            <span>{station.properties?.sub_station_name || `${station.properties?.county_name}`}</span>
                        </div>
                    ),
                };
            })
            .filter((d) => d); // Filter out duplicate records.
    };

    const renderStationGroups = () => {
        return stationGroups.map((group) => {
            return {
                value: "station_group:" + group.properties.name,
                object: {
                    ...group,
                    type: "station_group",
                },
                label: (
                    <div className="auto-complete-option">
                        <label>{group.properties.name}</label>
                        <span>{group.properties.description}</span>
                    </div>
                ),
            };
        });
    };

    const renderPlaces = () => {
        return places.map((place) => {
            return {
                value: "places:" + place.id,
                object: {
                    ...place,
                    type: "places",
                },
                label: (
                    <div className="auto-complete-option">
                        <label>{place.text}</label>
                        <span>{place.place_name}</span>
                    </div>
                ),
            };
        });
    };

    const renderSuggestedByArbo = () => {
        return suggestedByArbo.map((suggestion) => {
            return {
                value: `saved_search: ${suggestion.properties.description}`,
                object: suggestion,
                label: (
                    <div className="auto-complete-option">
                        <label>{suggestion.properties.description}</label>
                    </div>
                ),
            };
        });
    };

    const renderSuggestions = () => {
        const values = [];
        return suggestions
            .filter((d) => d.position)
            .map((suggestion) => {
                let value = suggestion.title;
                if (values.indexOf(value) >= 0) {
                    return false;
                }
                values.push(value);
                return {
                    value: value,
                    object: {
                        ...suggestion,
                        name: value,
                        type: "suggestion",
                    },
                    label: (
                        <div className="auto-complete-option">
                            <label>{value}</label>
                        </div>
                    ),
                };
            })
            .filter((d) => d); // Filter out duplicate records.
    };

    const renderTitle = (title, icon) => {
        return (
            <div className="auto-complete-group-title">
                {icon}
                {title}
            </div>
        );
    };

    const formattedOps = [
        {
            label: renderLink(),
            className: "draw-btn",
        },
        {
            label: renderCountyLink(),
            className: "draw-btn",
        },
        {
            label: renderTitle("STATION GROUPS", <Station />),
            options: renderStationGroups(),
        },
        {
            label: renderTitle("PLACES", <Suggestion />),
            options: renderPlaces(),
        },
        {
            label: renderTitle("SAVED STATIONS", <Station />),
            options: renderStations(savedStations, "saved"),
        },
        {
            label: renderTitle("STATIONS", <Station />),
            options: renderStations(suggestedStations, ""),
        },
        {
            label: renderTitle("SUGGESTED BY ARBO", <Suggestion />),
            options: renderSuggestedByArbo(),
        },
        {
            label: renderTitle("SUGGESTIONS", <Suggestion />),
            options: renderSuggestions(),
        },
    ];

    const filterOption = (ops) => {
        return ops.filter((op) => !op.options || op.options.length);
    };

    const renderDirectionBtn = () => {
        return (
            <Button
                size="small"
                className="directions-btn"
                disabled={showDrawOnMap}
                style={{
                    paddingTop: 6,
                    boxShadow: "none",
                }}
                onClick={() => {
                    setShowSearch(true);
                }}
            >
                <Directions />
            </Button>
        );
    };

    const renderAutoComplete = () => {
        if (pills?.length) {
            return pills.map((value, idx) => {
                const label = value.length > 22 ? value.slice(0, 20) + "…" : value;
                return (
                    <SearchPill
                        key={idx}
                        label={label}
                        onRemove={() => {
                            handleClear();
                        }}
                    />
                );
            });
        } else {
            return (
                <AutoComplete
                    mode="multiple"
                    options={filterOption(formattedOps)}
                    defaultActiveFirstOption={false}
                    style={{
                        width: "100%",
                        height: 48,
                    }}
                    height={400}
                    dropdownClassName="search-autocomplete-dropdown"
                    className="main-search-autocomplete"
                    onSelect={handleSelect}
                    onSearch={handleSearch}
                    value={searchValue}
                    dropdownMatchSelectWidth={false}
                >
                    <Input
                        size="large"
                        className="search-input"
                        style={{
                            height: 48,
                        }}
                        placeholder={placeholder}
                        prefix={isMainSearch ? <SearchOutlined /> : null}
                    />
                </AutoComplete>
            );
        }
    };

    return (
        <Row align="middle" justify="center">
            <Col flex="auto">{renderAutoComplete()}</Col>
            {isMainSearch && <Col flex="40px">{renderDirectionBtn()}</Col>}
        </Row>
    );
}

export default SearchAutocomplete;
