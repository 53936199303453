import axios from "axios";

const getDocuments = () =>
    axios
        .get("/content/document", {
            params: {
                ordering: "-published",
            },
        })
        .then((response) => response?.data || []);

const getDocument = async (id) => await axios.get(`/content/document/${id}`).then((response) => response.data);

const getDocumentPdf = async (id) => {
    const response = await axios.get(`/content/document/${id}/open`, {
        responseType: "arraybuffer",
    });
    return new Uint8Array(response.data);
};

const getArticles = (taxonomy = null) =>
    axios
        .get("/content/article", {
            params: {
                taxonomy__slug: taxonomy,
                ordering: "-published",
                published__isnull: false,
            },
        })
        .then((response) => response?.data || []);

const getArticle = async (id) => await axios.get(`/content/article/${id}`).then((response) => response.data);

const getArticlePdf = async (id) => {
    const response = await axios.get(`/content/article/${id}/open`, {
        responseType: "arraybuffer",
    });
    return new Uint8Array(response.data);
};
export default {
    getDocuments,
    getDocument,
    getDocumentPdf,
    getArticles,
    getArticle,
    getArticlePdf,
};
