import axios from "axios";
import { STRIPE_CHECKOUT_URL, getUserProfile } from "api";

export const createCheckoutSession = ({ plan, prices }) => axios.post(STRIPE_CHECKOUT_URL, { plan, prices });

export const PLANS = {
    FREE: "FREE",
    PRO: "PRO",
    TEAM: "TEAM",
    ENTERPRISE: "ENTERPRISE",
    OPTM: "OPTM",
    INSIGHTS: "INSIGHTS",
};

export const BILLING_FREQUENCY = {
    YEAR: "year",
    MONTH: "month",
};

const baseURL = axios.defaults.baseURL || `${process.env.REACT_APP_API_BASE_URL}api`;
const instance = axios.create({
    baseURL,
});

export const getProducts = async () => {
    const response = await instance.get("users/product");
    return response.data;
};

export const getPlans = async () => {
    const response = await instance.get("/users/plan");
    return response.data;
};

export const getCurrentSubscription = async () => {
    const { data } = await axios.get("users/subscription/current");
    return data;
};

export const getStripePortalUrl = async () => {
    const { id } = await getUserProfile();
    const response = await axios.post(`/users/profiles/${id}/get_stripe_portal_url`);
    return response.data;
};

export default {
    createCheckoutSession,
    getCurrentSubscription,
    getStripePortalUrl,
    getPlans,
    getProducts,
};
