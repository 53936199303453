import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { message, Alert, Button, Col, Row, Typography, Divider } from "antd";

import Api from "api";
import { linkedInInsightTrack, trackConfirmationPage } from "api/analytics";

const { Title } = Typography;

export default function RegisterMessage() {
    const [emailLoading, setEmailLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const SUCCESS_MESSAGE =
        "Click the link we just dropped in your inbox to verify your email address and continue to the platform.";

    linkedInInsightTrack();
    trackConfirmationPage();
    const query = new URLSearchParams(useLocation().search);
    const email = query.get("email");

    const submitEmailLogin = () => {
        setEmailLoading(true);
        Api.emailLogin(email)
            .then(() => {
                setEmailLoading(false);
                message.info(`A login link has been sent to your email ${email}.`);
            })
            .catch((err) => {
                setErrorMessages(Object.values(err));
                message.error(Object.values(err));
                setEmailLoading(false);
            });
    };

    return (
        <div className="register-form">
            <Row className="register-form__body">
                {errorMessages.length ? (
                    <Col span={24}>
                        {errorMessages.map(([msg, type], idx) => (
                            <div key={idx} span={24} style={{ marginBottom: 24 }}>
                                <Alert message={msg} type={type} closable showIcon />
                            </div>
                        ))}
                    </Col>
                ) : null}
                <Col span={24}>
                    <Title level={3}>Please verify your email address.</Title>
                </Col>
                <Col span={24}>
                    <p style={{ margin: 0 }}>{SUCCESS_MESSAGE}</p>
                </Col>
                <Divider style={{ margin: "12px 0" }} />
                <Col span={24}>
                    Didn’t receive an email?&nbsp;
                    <Button
                        type="link"
                        loading={emailLoading}
                        className="login-form__link--small"
                        onClick={submitEmailLogin}
                        style={{
                            color: "#126dff",
                            fontWeight: 600,
                            paddingLeft: 0,
                            fontSize: 14,
                        }}
                    >
                        Resend email.
                    </Button>
                </Col>
                <Col span={24} style={{ paddingTop: 24 }}>
                    <Link className="login-form__link--small" to="/login">
                        Back to Log in.
                    </Link>
                </Col>
            </Row>
        </div>
    );
}
