import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { getUserProfile } from "./";

export const logError = (err) => Sentry.captureException(err);

export const initErrorLogging = () => {
    const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
    if (sentryDsn) {
        Sentry.init({
            dsn: sentryDsn,
            environment: process.env.REACT_APP_ENV,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
            ignoreErrors: [
                "ResizeObserver loop limit exceeded",
                "ResizeObserver loop completed with undelivered notifications",
            ],
        });

        try {
            const userProfile = getUserProfile();
            Sentry.setUser({ email: userProfile?.user?.email });
        } catch (err) {
            logError(err);
        }
    }
};

const errorLogging = {
    init: initErrorLogging,
    log: logError,
};

export default errorLogging;
