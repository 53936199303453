import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import ApiBookmarks from "api/api.bookmarks";

import { getBookmarks } from "reducers/bookmarksSlice";
import { trackEvent } from "api/analytics";
import { capitalizeFirstLetter } from "Utils";

import { ReactComponent as TariffChainBell } from "assets/Icon/tariffChainBell.svg";
import { ReactComponent as TariffChainBellFilled } from "assets/Icon/tariffChainBellFilled.svg";

export default function BookmarkStar({
    app_label,
    model,
    object_id,
    data,
    onSubmit = () => {},
    tooltip,
    bellIcon = false,
    action_type = "bookmark",
}) {
    const dispatch = useDispatch();
    const [active, setActive] = useState();
    const [loading, setLoading] = useState();

    const { bookmarks } = useSelector(({ bookmarks }) => bookmarks);

    useEffect(() => {
        if (bookmarks?.indexed && model && !isNaN(object_id)) {
            const indexed = bookmarks.indexed[model];
            setActive(indexed && indexed[object_id]);
        }
    }, [bookmarks, model, object_id]);

    const handleClick = useCallback(
        (e) => {
            setLoading(true);
            e.preventDefault();
            e.stopPropagation();
            if (bookmarks?.loading) {
                return;
            }
            trackEvent(`${app_label} ${model} ${action_type} ${active ? "removed" : ""}`, {
                app_label,
                model,
                object_id,
            });
            if (active?.object_id === object_id) {
                ApiBookmarks.deleteBookmark(active.id).then(() => {
                    if (action_type === "bookmark") {
                        dispatch(getBookmarks()).then(() => {
                            setLoading(false);
                        });
                    } else if (action_type === "subscription") {
                        dispatch(getBookmarks()).then(() => {
                            setLoading(false);
                        });
                    }
                });
                if (onSubmit) {
                    onSubmit(false);
                }
            } else if (!active && !isNaN(object_id) && app_label) {
                if (onSubmit) {
                    onSubmit(true);
                }
                ApiBookmarks.saveBookmark({ app_label, model, object_id, data }).then((resp) => {
                    dispatch(getBookmarks()).then(() => {
                        setLoading(false);
                    });
                });
            }
        },
        [active, bookmarks, object_id, model],
    );

    const filledIcon = bellIcon ? <TariffChainBellFilled /> : <StarFilled className="star" />;
    const defaultIcon = bellIcon ? <TariffChainBell /> : <StarOutlined className="star" />;

    return (
        <div className="bookmark-star" onClick={handleClick}>
            {loading ? (
                <LoadingOutlined />
            ) : active ? (
                <Tooltip title={`Remove this ${action_type}`}>{filledIcon}</Tooltip>
            ) : (
                <Tooltip title={tooltip || capitalizeFirstLetter(action_type) + " this " + model}>
                    {defaultIcon}
                </Tooltip>
            )}
        </div>
    );
}
