import React from "react";
import { Button, Col, message, Modal, Row } from "antd";
import styles from "./ReferModal.module.css";
import { ReactComponent as Referral } from "assets/Icon/referal.svg";
import { copyToClipboard } from "Utils";
import { getReferralsUrl, getUserProfile } from "api";
import { trackEvent } from "api/analytics";

export default function ReferModal({ visible, onCancel }) {
    const userProfile = getUserProfile();

    let count = userProfile?.referrals_count || 0;
    let nextLevelCount = null;
    if (count === 0) {
        nextLevelCount = 1;
    } else if (count < 3) {
        nextLevelCount = 3 - count;
    } else if (count < 10) {
        nextLevelCount = 10 - count;
    } else if (count < 15) {
        nextLevelCount = 15 - count;
    }

    return (
        <Modal
            visible={visible}
            width={480}
            className="arbo-modal"
            footer={null}
            destroyOnClose={true}
            onCancel={onCancel}
            title="REFERRAL REWARDS"
        >
            <Row>
                <Col span={24}>
                    <p className={styles.modalText}>
                        <a
                            href="https://www.goarbo.com/referrals"
                            target="_blank"
                            rel="noreferrer"
                            className={styles.modalLink}
                        >
                            We reward you&nbsp;
                        </a>
                        for helping us grow by sharing <br /> Arbo with colleagues and counterparties.
                    </p>
                </Col>
                <Col span={24} style={{ marginTop: "20px", textAlign: "center" }}>
                    <label className={styles.modalLabel}>Your referral status</label>
                    <p className={styles.modalText}>
                        {count} of your friends have registered!
                        <br />
                        {nextLevelCount !== null
                            ? `Just ${nextLevelCount} more referrals and you’ll reach the next reward.`
                            : null}
                    </p>
                </Col>
                <Col span={24} style={{ marginTop: "25px", textAlign: "center" }}>
                    <Button
                        type="link"
                        className={styles.referFriendLink}
                        onClick={() => {
                            copyToClipboard(getReferralsUrl());
                            trackEvent("Account Mgmt - Copy Referral Link from Refer Friend Modal");
                            message.success("Referral link copied");
                        }}
                    >
                        <Referral />
                        Copy your unique referral link
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}
