import axios from "axios";
import { setFeatureCount, getFeatureCount } from "../api";

const SETTINGS_KEY = "tracker";
const UNITS = {
    DOLLARS_PER_BARREL: 1,
};
const DEFAULT_UNIT = UNITS.DOLLARS_PER_BARREL;
const UNIT_CONVERTER = {
    1: (value) => (+value / 100).toFixed(4),
};

export const FEATURE_EXPAND_LIMIT = 20;
const FEATURE_EXPAND_ID = "arb_tracker_expand";

const convertUnit = (value, unit) => {
    const converter = UNIT_CONVERTER[unit] || UNIT_CONVERTER[DEFAULT_UNIT];
    return converter(+value);
};

const getContractPrices = async (year, month) =>
    await axios
        .get(`liquids/price`, {
            params: {
                page_size: 100,
                contract_month: month,
                contract_year: year,
                most_recent: true,
            },
        })
        .then((response) => response.data);

const getContractPrice = (contracts, prices) => {
    const result = +contracts.reduce((acum, d) => (+prices[d.name]?.settle || 0) + acum, 0);
    return result;
};

const getDifferential = ({ origin, destination }, prices) => {
    const origPrice = getContractPrice(origin.contracts, prices);
    const destPrice = getContractPrice(destination.contracts, prices);
    const price = origPrice && destPrice ? +destPrice - +origPrice : 0;
    return +price.toFixed(4);
};

export const expandGetRemaining = () => FEATURE_EXPAND_LIMIT - getFeatureCount(FEATURE_EXPAND_ID);
export const expandIncreaseCount = () => {
    const count = setFeatureCount(FEATURE_EXPAND_ID);
    return count > FEATURE_EXPAND_LIMIT ? 0 : FEATURE_EXPAND_LIMIT - count;
};

export default {
    convertUnit,
    getDifferential,
    getContractPrice,
    getContractPrices,
    DEFAULT_UNIT,
    SETTINGS_KEY,
    UNITS,
    FEATURE_EXPAND_LIMIT,
    expandGetRemaining,
    expandIncreaseCount,
};
