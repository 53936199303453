import React, { useEffect, useState, Suspense } from "react";
import { Layout, Spin } from "antd";

import SubscribeModal from "components/organisms/SubscribeModal";
import HowToDrawer from "components/support/HowToDrawer";
import Header from "components/layout/Header";
import Sidebar from "components/layout/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "reducers/layoutSlice";
import { getBookmarks } from "reducers/bookmarksSlice";
import { useLocation } from "react-router-dom";
import { isAuthenticated } from "api";
import { trackEvent } from "api/analytics";
import { loadSubscription, loadPlans, loadProducts } from "reducers/layoutSlice";

export default function SiderLayout({ children, option, title, beta, isPublic, style = {} }) {
    const [classes, setClasses] = useState("");
    const { sidebarCollapsed, plans, products } = useSelector(({ layout }) => layout);
    const { bookmarks } = useSelector(({ bookmarks }) => bookmarks);
    const showDrawOnMap = useSelector(({ layout }) => layout.showDrawOnMap);
    const user = useSelector(({ layout }) => layout.user);
    const dispatch = useDispatch();
    const query = new URLSearchParams(useLocation().search);
    const alert_id = query.get("alert_id");
    const signal_id = query.get("signal_id");

    useEffect(() => {
        if (isAuthenticated() && !plans && !plans?.loading) {
            dispatch(loadSubscription());
            dispatch(loadPlans());
        }
    }, [plans, dispatch]);

    useEffect(() => {
        if (!bookmarks && !bookmarks?.loading) {
            dispatch(getBookmarks());
        }
    }, [bookmarks, dispatch]);

    useEffect(() => {
        if (!products && !products?.loading) {
            dispatch(loadProducts());
        }
    }, [products]);

    useEffect(() => {
        if (alert_id || signal_id) {
            trackEvent("Account Mgmt - Notification Opened", {
                "alert id": query.get("alert_id") || "",
                "signal id": query.get("signal_id") || "",
            });
        }
    }, [alert_id, signal_id]);

    useEffect(() => {
        if (!isPublic) {
            if (!user) {
                dispatch(loadUser());
            }
        }
    }, [dispatch, user, isPublic]);

    useEffect(() => {
        const classList = ["main-layout"];
        if (isPublic) {
            classList.push("collapsed-sider");
        } else {
            sidebarCollapsed && classList.push("collapsed-sider");
            !showDrawOnMap && classList.push("main-layout--has-header");
        }
        setClasses(classList.join(" "));
    }, [isPublic, showDrawOnMap, sidebarCollapsed]);

    useEffect(() => {
        document.title = `Arbo - ${title}`;
    }, [title]);

    return (
        <Layout hasSider={true}>
            <Sidebar option={option} isPublic={isPublic} />
            <HowToDrawer />
            <Layout style={style} className={classes}>
                {!showDrawOnMap && <Header title={title} beta={beta} isPublic={isPublic} />}
                <Suspense fallback={<Spin />}>{children}</Suspense>
            </Layout>
            <SubscribeModal />
        </Layout>
    );
}
