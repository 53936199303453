import React from "react";
import ReactStars from "react-stars";

import "./StarRating.css";

export default function ({ value, onChange, size = 24 }) {
    return (
        <ReactStars
            className="star-rating"
            count={5}
            half={false}
            value={value}
            color1="#ccc"
            color2="#AA8B4B"
            size={size}
            onChange={onChange}
        />
    );
}
