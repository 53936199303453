import React, { useEffect, useState } from "react";

import "./SubwayTiles.less";

const SubwayTiles = ({ path }) => {
    const [pipelines, setPipelines] = useState([]);

    const [subwayButtons, setSubwayButtons] = useState([]);

    useEffect(() => {
        if (path?.pipelines?.length) {
            setPipelines(path.pipelines);
        }
    }, [path]);

    useEffect(() => {
        if (pipelines?.length) {
            const now = new Date();
            const future = !!pipelines.filter((p) => p.steps.filter((s) => new Date(s.effective_date) > now).length)
                .length;
            const discount = !!pipelines.filter(
                (p) =>
                    p.steps.filter(
                        (s) =>
                            s.tariff_rate?.discounts?.filter((d) => !d.is_committed).length ||
                            s.total_discounts_count - s.committed_discounts_count > 0,
                    ).length,
            ).length;

            const buttons = [];
            future &&
                buttons.push(
                    <span key="f" className="subway-icon subway-icon--blue">
                        F
                    </span>,
                );
            discount &&
                buttons.push(
                    <span key="d" className="subway-icon subway-icon--green">
                        D
                    </span>,
                );
            setSubwayButtons(buttons);
        }
    }, [pipelines]);

    return subwayButtons;
};

export default SubwayTiles;
