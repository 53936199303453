import React, { useState } from "react";
import { Dropdown, Menu, message } from "antd";
import styles from "./InAppHelpIcon.module.css";
import { trackEvent } from "api/analytics";
import { ReactComponent as HelpIcon } from "assets/Icon/helpIcon.svg";
import SupportModal from "components/support/SupportModal";

export default function () {
    const [visibleHelpMenu, setVisibleHelpMenu] = useState(false);
    const [showSupportModal, setShowSupportModal] = useState(false);

    const helpMenu = (
        <Menu
            className={styles.menu}
            onClick={() => {
                setVisibleHelpMenu(true);
            }}
        >
            <Menu.Item key="0" className={styles.menuItem}>
                <a
                    style={{
                        color: "#535455",
                    }}
                    onClick={() => {
                        setShowSupportModal(true);
                        trackEvent("Account Mgmt - Contact Support");
                    }}
                >
                    Contact Support
                </a>
            </Menu.Item>
            <Menu.Item
                key="1"
                className={styles.menuItem}
                onClick={() => {
                    message.warning("In-app chat coming soon.");
                    trackEvent("Account Mgmt - Chat Selection");
                    // dispatch(toggleHowToDrawer());
                }}
            >
                In-app chat
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown
                overlay={helpMenu}
                onVisibleChange={(visible) => {
                    setVisibleHelpMenu(visible);
                    if (visible) {
                        trackEvent("Account Mgmt - Help Icon Click");
                    }
                }}
                visible={visibleHelpMenu}
            >
                <HelpIcon className={styles.helpIcon} />
            </Dropdown>
            <SupportModal
                visible={showSupportModal}
                onCancel={() => {
                    setShowSupportModal(false);
                }}
            />
        </>
    );
}
