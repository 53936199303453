import React from "react";
import PropTypes from "prop-types";
import { GREY_BASEMAP, SATELLITE_BASEMAP } from "components/geo/Map/basemaps";

const initialState = {
    mapObject: null,
    center: [-87.622088, 41.878781],
    bounds: [
        [-128.4877141620263, 20.225171618416297],
        [-63.58066837540579, 53.598458948430505],
    ],
    basemap: GREY_BASEMAP,
    zoom: [6],
    drawObject: null,
    drawnPolygons: {},
    drawFilter: undefined,
    paints: {
        stations: {
            "icon-opacity": 0.8,
        },
    },
    pricingHubs: null,
};

const store = React.createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer((state, action) => {
        switch (action.type) {
            case "set bounds":
                const bounds = action.payload || initialState.bounds;
                return { ...state, bounds };
            case "set center":
                return { ...state, center: action.payload, animationOptions: { essential: true } };
            case "set zoom":
                return { ...state, zoom: action.payload };
            case "set basemap":
                switch (true) {
                    case action.payload === "satellite":
                        return { ...state, basemap: SATELLITE_BASEMAP };
                    case action.payload === "grey":
                        return { ...state, basemap: GREY_BASEMAP };
                    default:
                        return { ...state, basemap: GREY_BASEMAP };
                }
            case "set draw object":
                return { ...state, drawObject: action.payload };
            case "set drawn polygons":
                return { ...state, drawnPolygons: action.payload };
            case "set draw filter":
                return { ...state, drawFilter: action.payload };
            case "set paint property":
                const { layer, name, value } = action.payload;
                return {
                    ...state,
                    paints: {
                        ...state.paints,
                        [layer]: {
                            ...state.paints[layer],
                            [name]: value,
                        },
                    },
                };
            case "setPricingHubs":
                return { ...state, pricingHubs: action.payload };
            default:
                return state;
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { store, StateProvider };
