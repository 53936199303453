import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer, Skeleton } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { LeftOutlined } from "@ant-design/icons";

import { checkCountyGeometry } from "components/geo/utils";
import SearchPanel from "./searchPanel";
import StationDetails from "./stationDetails";
import { updateSearchPanel, loadStation, updateSelectedFeature } from "reducers/layoutSlice";

import AsyncResults from "./asyncResults";

function LeftSearchDrawer() {
    const dispatch = useDispatch();
    const openSearchPanel = useSelector((state) => state.layout.openSearchPanel);
    const searchFields = useSelector((state) => state.search.searchFields);
    const stationDetails = useSelector((state) => state.layout.stationDetails);
    const loadingPricingHub = useSelector(({ layout }) => layout.loadingPricingHub);
    const loadingStation = useSelector(({ layout }) => layout.loadingStation);
    const loadingSelectedPath = useSelector(({ bookmarks }) => bookmarks.loadingSelectedPath);
    const { bestPaths } = useSelector(({ search }) => search);

    const [hideForm, updateHideForm] = useState(false);

    useEffect(() => {
        updateHideForm(!!stationDetails);
    }, [stationDetails]);

    useEffect(() => {
        if (stationDetails?.geometry) {
            dispatch(updateSelectedFeature(stationDetails));
        }
        if (!stationDetails?.geometry && stationDetails?.properties?.county_name) {
            checkCountyGeometry(stationDetails).then((response) => {
                const geometry = response?.features?.slice()?.shift()?.geometry;
                if (geometry) {
                    dispatch(updateSelectedFeature({ ...stationDetails, geometry }));
                }
            });
        }
        return () => dispatch(updateSelectedFeature({}));
    }, [dispatch, stationDetails]);

    const isLoading = useCallback(() => {
        return loadingSelectedPath || loadingPricingHub;
    }, [loadingSelectedPath, loadingPricingHub, loadingStation]);

    useEffect(() => {
        if (searchFields && searchFields.type && searchFields.id) {
            switch (searchFields.type) {
                case "Stations":
                    dispatch(loadStation(searchFields.id));
                    break;

                default:
            }
            dispatch(updateSearchPanel(true));
        }
    }, [dispatch, searchFields]);

    return (
        <Drawer
            placement="left"
            destroyOnClose={true}
            closable={true}
            getContainer={false}
            visible={openSearchPanel}
            closeIcon={<LeftOutlined />}
            mask={false}
            width={540}
            key="left"
            className="left-search-drawer absolute"
            onClose={() => {
                dispatch(updateSearchPanel(false));
            }}
        >
            {hideForm ? (
                <Button
                    type="primary"
                    onClick={() => {
                        updateHideForm(false);
                    }}
                    style={{
                        float: "right",
                        width: 40,
                        margin: 12,
                        marginTop: 24,
                    }}
                    shape="circle"
                    icon={<SearchOutlined />}
                />
            ) : (
                <SearchPanel />
            )}
            {isLoading() ? (
                <div style={{ margin: 32 }}>
                    <Skeleton active />
                </div>
            ) : (
                <>
                    {!bestPaths?.length && stationDetails && <StationDetails station={stationDetails} />}
                    <AsyncResults />
                </>
            )}
        </Drawer>
    );
}

export default LeftSearchDrawer;
