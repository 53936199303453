import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message, Col, Row } from "antd";

import ApiBookmarks from "api/api.bookmarks";
import SaveModal from "components/molecules/SaveModal";
import CircleButton from "components/utils/CircleButton";
import { ReactComponent as Share } from "assets/Icon/Share.svg";
import { ReactComponent as Directions } from "assets/Icon/directions.svg";
import { ReactComponent as Save } from "assets/Icon/Save.svg";
import { ReactComponent as Download } from "assets/Icon/Download.svg";
import ExportModal from "components/molecules/ExportModal";
import FeedbackSnippet from "../analytics/FeedbackSnippet";
import ExportRates from "../routes/ExportRates";
import FreeFeatureCount from "../organisms/FreeFeatureCount";
import { exportCSVFile } from "Utils";
import { trackEvent } from "../../api/analytics";
import { getBookmarks } from "../../reducers/bookmarksSlice";

function SearchMenu({ onFindRoutes, searchData }) {
    const dispatch = useDispatch();

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [rating] = useState();
    const { isMapSearchUser } = useSelector(({ layout }) => layout);
    const asyncResponse = useSelector(({ search }) => search.asyncResponse);
    const bestPaths = useSelector(({ search }) => search.bestPaths);
    const searchUrl = useSelector(({ search }) => search.searchUrl);
    const [defaultValue, setDefaultValue] = useState("");
    const [exportData, setExportData] = useState();
    const [asyncKey, setAsyncKey] = useState();
    const [showBanner, setShowBanner] = useState(false);
    const [tariffRateIds, setTariffRateIds] = useState();

    const saveSearch = useCallback(
        async (description) => {
            const { id, parameters } = Object.values(asyncResponse).pop();
            if (id) {
                await ApiBookmarks.saveBookmark({
                    app_label: "liquids",
                    model: "asyncresponsedata",
                    object_id: id,
                    description,
                    data: {
                        description,
                        parameters,
                    },
                });
                dispatch(getBookmarks());
            }
        },
        [asyncResponse],
    );

    const handleExportRoutes = useCallback(
        (e) => {
            if (!exportData?.length) {
                return;
            }
            if (!isMapSearchUser) {
                setShowBanner(true);
                return;
            }
            const fileTitle = `export-routes-${Object.keys(asyncResponse).join("-")}`;
            exportCSVFile(exportData, fileTitle);
            message.info(`CSV download started.`);
        },
        [isMapSearchUser, asyncResponse, exportData],
    );

    useEffect(() => {
        if (showBanner) {
            setTimeout(() => {
                setShowBanner(false);
            }, 5100);
        }
    }, [showBanner]);

    useEffect(() => {
        const response = Object.values(asyncResponse).pop();
        if (response?.data) {
            const { data, parameters } = response;
            const origin = [
                data?.origin_station?.properties?.name,
                data?.origin_station_group?.name,
                parameters?.origin_name ? parameters?.origin_name : data?.origin_polygon ? "Custom Region" : "",
            ]
                .filter((d) => d)
                .shift();

            const destination = [
                data?.destination_station?.properties?.name,
                data?.destination_station_group?.name,
                parameters?.destination_name
                    ? parameters?.destination_name
                    : data?.destination_polygon
                    ? "Custom Region"
                    : "",
            ]
                .filter((d) => d)
                .shift();
            const values = [];
            origin && values.push("From", origin);
            destination && values.push("To", destination);
            setDefaultValue(values.join(" "));
        }
        if (response?.paths?.length) {
            const data = [["Origin", "Destination", "Pipeline", "Min. Rate", "Walkup Rate", "URL"]];
            const ids = [];
            response.paths.forEach((d) => {
                d.path_steps.forEach((step) => {
                    ids.push(step.tariff_rate);
                });
                data.push([
                    d.origin_station.properties.name,
                    d.destination_station.properties.name,
                    d.path_steps?.slice().shift().pipeline.name,
                    d.total_rate,
                    d.total_walkup_rate,
                    `${document.location.origin}/path/${d.id}`,
                ]);
            });
            setTariffRateIds(ids.join(","));
            setExportData(data);
        }
        const asyncKey = Object.keys(asyncResponse).pop();
        setAsyncKey(asyncKey);
    }, [asyncResponse]);

    return (
        <Row
            className="search-menu"
            style={
                showBanner
                    ? { padding: "0 24px 24px 24px", borderBottom: "1px solid #9ca5b4" }
                    : { padding: 24, borderBottom: "1px solid #9ca5b4" }
            }
        >
            {showBanner ? (
                <Row>
                    <Col span={24}>
                        <FreeFeatureCount
                            contactLink={true}
                            banner={true}
                            count={0}
                            text="Upgrade to export rate tables."
                        />
                    </Col>
                </Row>
            ) : null}
            <SaveModal
                visible={showSaveModal}
                defaultValue={defaultValue}
                onCancel={() => {
                    setShowSaveModal(false);
                }}
                entityName="Search"
                saveMethod={saveSearch}
            />
            {onFindRoutes ? (
                <Col span={8}>
                    <CircleButton onClick={onFindRoutes} icon={<Directions />}>
                        <span>Find Routes</span>
                    </CircleButton>
                </Col>
            ) : (
                <>
                    <Col span={12} style={{ paddingRight: 15, alignSelf: "center" }}>
                        {bestPaths?.length ? (
                            asyncKey ? (
                                <FeedbackSnippet
                                    value={rating}
                                    id={asyncKey}
                                    event="Search Results Feedback"
                                    title="Search Results Feedback"
                                    question="Help improve Arbo by rating results for this query."
                                    type="stars"
                                    data={{ searchUrl }}
                                />
                            ) : null
                        ) : asyncKey ? (
                            <FeedbackSnippet
                                event="Search Results Feedback"
                                title="Search Results Feedback"
                                id={asyncKey}
                                value={rating}
                                question="Was this what you expected? Is this accurate?"
                                type="radio"
                                data={{ searchUrl }}
                            />
                        ) : null}
                    </Col>

                    <Col span={12} style={{ paddingTop: 8, paddingBottom: 8, alignSelf: "center" }}>
                        <div className="buttons-area">
                            <CircleButton icon={<Save />} onClick={() => setShowSaveModal(true)}>
                                <span>Save</span>
                                <span>Search</span>
                            </CircleButton>
                            <CircleButton
                                icon={<Share />}
                                onClick={() => {
                                    setExportModalVisible(true);
                                }}
                            >
                                <span>Share</span>
                                <span>Search</span>
                            </CircleButton>
                            <ExportRates
                                tariffRateIds={tariffRateIds}
                                showBanner={() => {
                                    setShowBanner(true);
                                }}
                                onAction={(type) => {
                                    switch (type) {
                                        case "rates":
                                            trackEvent("Map Search - Route Search Export Tariff Rates");
                                            break;
                                        case "discounts":
                                            trackEvent("Map Search - Route Search Export Tariff Rate Discounts");
                                            break;
                                        case "charges":
                                            trackEvent("Map Search - Route Search Export Tariff Rate Extra Charges");
                                            break;
                                    }
                                }}
                            >
                                <div className={"circle-btn-wrapper " + (!exportData ? "cursor-loading" : "")}>
                                    <CircleButton icon={<Download />} blue={true} loading={!exportData}>
                                        <span>Export</span>
                                        <span>Rates</span>
                                    </CircleButton>
                                </div>
                            </ExportRates>
                        </div>
                    </Col>
                </>
            )}
            <ExportModal
                data={exportData}
                url={searchUrl}
                visible={exportModalVisible}
                onClose={() => setExportModalVisible(false)}
            />
        </Row>
    );
}

export default SearchMenu;
