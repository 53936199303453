import React, { useCallback, useState } from "react";
import { Alert, Button, Col, Input, message as Message, Modal, Row } from "antd";
import Text from "antd/es/typography/Text";
import Title from "antd/es/typography/Title";

import { trackEvent } from "api/analytics";
import { saveAction } from "api/api.bookmarks";
import Api from "api/";

const { TextArea } = Input;

export default function SupportModal({ visible, onCancel }) {
    const [message, setMessage] = useState("");
    const [alerts, setAlerts] = useState([]);
    const [loading, setLoading] = useState(false);

    const sendAction = useCallback(
        (e) => {
            const profile = Api.getUserProfile();
            setLoading(true);
            trackEvent("Account Mgmt - Contact Support", { message });
            saveAction({
                action_type: "feedback",
                app_label: "users",
                model: "profile",
                object_id: profile.id,
                data: {
                    source: "Account Mgmt - Contact Support",
                    message,
                },
            });
            Api.contactSupport({ message })
                .then((resp) => {
                    Message.success(resp.data.detail);
                    onCancel();
                })
                .catch((resp) => {
                    setAlerts(resp?.message || "Error sending support message");
                })
                .finally((d) => {
                    setLoading(false);
                });
        },
        [message, onCancel],
    );

    return (
        <Modal
            visible={visible}
            width={480}
            className="arbo-modal"
            footer={null}
            destroyOnClose={true}
            onCancel={onCancel}
            title="Contact Support"
        >
            <Row>
                <Col span={24}>
                    <Title level={4}>We're here to help</Title>
                </Col>
                <Col span={24} style={{}}>
                    <Text>
                        Please let us know about any issues you are experiencing. Our customer success team will receive
                        your message immediately and we strive to respond to all inquiries within 24 hours.
                    </Text>
                </Col>
                {alerts.length ? (
                    <Col span={24} style={{ paddingTop: 15 }}>
                        <Alert message={alerts} type={"warning"} closable showIcon />
                    </Col>
                ) : (
                    ""
                )}
                <Col span={24} style={{ paddingTop: 15 }}>
                    <Text className="input-label">Your message</Text>
                    <TextArea
                        rows={4}
                        style={{
                            marginTop: 8,
                        }}
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        value={message}
                    />
                </Col>
                <Col span={12} style={{ marginTop: 12 }}>
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={sendAction}
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            marginRight: 15,
                        }}
                    >
                        Send
                    </Button>
                </Col>
                <Col
                    span={12}
                    style={{
                        marginTop: 15,
                        textAlign: "right",
                    }}
                >
                    <Button type="link" onClick={onCancel} className="cancel-btn">
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}
