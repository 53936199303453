import React from "react";
import { Button, Typography } from "antd";
import clsx from "clsx";

const { Text } = Typography;

export default function CircleButton({ children, icon, onClick, horizontal, blue }) {
    return (
        <div
            onClick={onClick}
            className={clsx("circle-btn-wrapper", horizontal && "circle-btn-wrapper--horizontal", blue && "blue-btn")}
        >
            <Button shape="circle">{icon}</Button>
            <Text className="circle-btn-text">{children}</Text>
        </div>
    );
}
