import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiLiquids from "api/api.liquids";
import Api from "api";
import { getSelectedPath } from "./bookmarksSlice";

export const loadCommodityTypes = createAsyncThunk("search/loadCommodityTypes", async () => {
    const response = await ApiLiquids.getCommodityTypes();
    return response.data;
});

export const loadCommodities = createAsyncThunk("search/loadCommodities", async (commodityType) => {
    const params = { page_size: 5000 };
    params.commodity_type_id = commodityType instanceof Array ? commodityType[0] : commodityType;
    const response = await ApiLiquids.getCommodities(params);
    return response.data;
});

export const loadTariffHeadingTypes = createAsyncThunk("search/loadTariffHeadingTypes", async (params = {}) => {
    const response = await ApiLiquids.getTariffHeadingTypes({ page_size: 100, ...params });
    return response.data;
});

export const loadTariffTypes = createAsyncThunk("search/loadTariffTypes", async (params = {}) => {
    const response = await ApiLiquids.getTariffTypes({ page_size: 100, ...params });
    return response.data;
});

export const loadProducts = createAsyncThunk("search/loadProducts", async () => {
    const response = await ApiLiquids.getProducts({ ordering: "name", page_size: 5000 });
    return response.data;
});

export const loadStations = createAsyncThunk("search/loadStations", async (ids) => {
    const params = { ordering: "name", id__in: ids };
    const response = await ApiLiquids.getStations(params);
    return response.data;
});

export const getHasFullAccess = createAsyncThunk(
    "tracker/getHasFullAccess",
    async () => await Api.userHasGroup("TARIFF-SEARCH"),
);

export const getBestPaths = createAsyncThunk("search/bestPaths", async (params, thunkAPI) => {
    try {
        const response = await ApiLiquids.getBestPath(params);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const loadNextPage = createAsyncThunk("search/loadNextPage", async (params, thunkAPI) => {
    try {
        const response = await ApiLiquids.getBestPaths(params);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getPath = createAsyncThunk("search/getPath", async (id, thunkAPI) => {
    try {
        const response = await ApiLiquids.getPath(id);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getTariffs = createAsyncThunk("search/getTariffs", async (ids, thunkAPI) => {
    try {
        const response = await ApiLiquids.getTariffs(ids);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getRouteSuggestions = createAsyncThunk("search/getRouteSuggestions", async (id, thunkAPI) => {
    try {
        const response = await ApiLiquids.getCorridors({ category__name: "Suggestions" });
        const items = [];
        const results = response.data?.results;
        await Promise.all(results.map((d) => ApiLiquids.getCorridor(d.id))).then((responses) => {
            responses.map(({ data }) => {
                items.push(data.latest_result.paths.slice().shift());
            });
        });
        return items;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getAsyncResponse = createAsyncThunk("search/getAsyncResponse", async (params, thunkAPI) => {
    try {
        const response = await ApiLiquids.getAsyncResponse(params);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getSearchHistory = createAsyncThunk("search/getSearchHistory", async (params, thunkAPI) => {
    const response = await ApiLiquids.getSearchHistory(params);
    return response.data;
});

export const loadPipelines = createAsyncThunk("search/loadPipelines", async () => {
    const response = await ApiLiquids.getPipelines({ ordering: "name", page_size: 5000 }); //FIXME: request all pipelines
    return response.data;
});

export const loadMarketHubs = createAsyncThunk("search/loadMarketHubs", async () => {
    const response = await ApiLiquids.getStationsGroups({
        public: true,
        limit: 100,
        category__name: "Market Hub",
    });
    return response.data;
});

export const searchSlice = createSlice({
    name: "search",
    initialState: {
        allowedPipelines: null,
        asyncLoading: null,
        asyncResponse: {},
        asyncSearch: {},
        avoidSteps: {
            origin: true,
            destination: true,
        },
        bannerPipeline: null,
        bestPathResults: [],
        bestPaths: [],
        committedRates: undefined,
        commodities: [],
        commodityGrade: null,
        commodityType: null,
        commodityTypes: [],
        destination: null,
        excludedPipelines: null,
        indexedMarketHubs: {},
        isSegmentSearch: false,
        loading: false,
        loadingCommodities: false,
        marketHubs: null,
        maxPipelines: null,
        message: "",
        modalBannerPipeline: null,
        openRouteDetails: false,
        origin: null,
        page: null,
        path: null,
        paths: {},
        pipelines: null,
        products: [],
        routeSuggestions: null,
        searchHistory: [],
        searchSuggestions: null,
        searchUrl: "",
        selectedCommodities: null,
        selectedEffective: null,
        selectedPath: null,
        selectedProducts: null,
        selectedJurisdiction: null,
        selectedStation: null,
        stations: null,
        tariffHeadingTypes: null,
        tariffId: null,
        tariffs: null,
        tariffTypes: null,
        volume: null,
        mapSearchCount: 8,
    },
    reducers: {
        countMapSearch: (state) => {
            state.mapSearchCount = state.mapSearchCount - 1;
        },
        clearAsyncResponse: (state) => {
            state.asyncResponse = {};
        },
        setOrigin: (state, { payload }) => {
            if (state.origin?.id === payload?.id) {
                state.origin = { ...state.origin, ...payload };
            } else {
                state.origin = { ...payload };
            }
            if (payload?.type && !payload.name && state.origin && !state.origin.name) {
                if (state.origin.type === "station_group" && state.origin.id) {
                    state.origin.name = `Station Group: ${state.origin.id}`;
                } else {
                    state.origin.name = "Loading origin";
                }
            }
        },
        setDestination: (state, { payload }) => {
            if (state.destination?.id === payload?.id) {
                state.destination = { ...state.destination, ...payload };
            } else {
                state.destination = { ...payload };
            }
            if (payload?.type && !payload.name && state.destination && !state.destination.name) {
                if (state.destination.type === "station_group" && state.destination.id) {
                    state.destination.name = `Station Group: ${state.destination.id}`;
                } else {
                    state.destination.name = "Loading destination";
                }
            }
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setPage: (state, { payload }) => {
            state.page = payload;
        },
        setVolume: (state, { payload }) => {
            state.volume = payload;
        },
        setCommodityType: (state, { payload }) => {
            if (payload instanceof Array) {
                state.commodityType = payload;
            } else {
                state.commodityType = [payload];
            }
        },
        setCommodityGrade: (state, { payload }) => {
            state.commodityGrade = payload;
        },
        setCommittedRates: (state, { payload }) => {
            state.committedRates = payload;
        },
        setSelectedEffective: (state, { payload }) => {
            state.selectedEffective = payload;
        },
        setAvoidSteps: (state, { payload }) => {
            state.avoidSteps = { ...state.avoidSteps, ...payload };
        },
        setMaxPipelines: (state, { payload }) => {
            state.maxPipelines = payload;
        },
        setSelectedStation: (state, { payload }) => {
            state.selectedStation = payload;
        },
        setSelectedCommodities: (state, { payload }) => {
            state.selectedCommodities = payload;
        },
        setSelectedProducts: (state, { payload }) => {
            state.selectedProducts = payload;
        },
        setSelectedJurisdiction: (state, { payload }) => {
            state.selectedJurisdiction = payload;
        },
        setBestPaths: (state, { payload }) => {
            state.bestPaths = payload;
            if (state.bestPaths?.length) {
                state.selectedPath = state.bestPaths[0];
            }
        },
        setMessage: (state, { payload }) => {
            state.message = payload;
        },
        setSelectedPath: (state, { payload }) => {
            state.selectedPath = payload;
        },
        resetSearch: (state) => {
            state.allowedPipelines = null;
            state.asyncResponse = {};
            state.avoidSteps = { origin: true, destination: true };
            state.bestPathResults = [];
            state.bestPaths = [];
            state.commodityGrade = null;
            state.commodityType = null;
            state.destination = null;
            state.excludedPipelines = null;
            state.message = null;
            state.origin = null;
            state.selectedCommodities = null;
            state.selectedEffective = null;
            state.selectedJurisdiction = null;
            state.selectedPath = null;
            state.selectedProducts = null;
            state.tariffId = null;
            state.volume = null;
        },
        updateSearchFields: (state, action) => {
            state.searchFields = action.payload;
        },
        setRouteDetailOpen: (state, { payload }) => {
            state.openRouteDetails = payload;
        },
        updateRouteDetailPanel: (state, { payload }) => {
            // state.selectedPath = payload !== false ? { ...payload } : null;
        },
        setAsyncResponse: (state, { payload }) => {
            const { id } = payload;
            state.asyncResponse = { ...state.asyncResponse, [id]: payload };
        },
        setAllowedPipelines: (state, { payload }) => {
            state.allowedPipelines = payload;
        },
        setExcludedPipelines: (state, { payload }) => {
            state.excludedPipelines = payload;
        },
        setSearchSuggestions: (state, { payload }) => {
            state.searchSuggestions = payload;
        },
        setSearchUrl: (state, { payload }) => {
            state.searchUrl = payload;
        },
        setBannerPipeline: (state, { payload }) => {
            state.bannerPipeline = payload;
        },
        setModalBannerPipeline: (state, { payload }) => {
            state.modalBannerPipeline = payload;
        },
        setTariffId: (state, { payload }) => {
            state.tariffId = payload;
        },
    },
    extraReducers: {
        [loadCommodityTypes.fulfilled]: (state, action) => {
            state.commodityTypes = action.payload?.results || [];
        },
        [loadCommodities.pending]: (state) => {
            state.loadingCommodities = true;
        },
        [loadCommodities.fulfilled]: (state, action) => {
            state.commodities = action.payload?.results || [];
            state.loadingCommodities = false;
        },
        [loadNextPage.pending]: (state) => {
            state.loadNextPage = true;
        },
        [loadNextPage.fulfilled]: (state, action) => {
            const results = action.payload?.results || [];
        },
        [loadMarketHubs.pending]: (state) => {
            state.marketHubs = {
                ...state.marketHubs,
                loading: true,
            };
        },
        [loadMarketHubs.fulfilled]: (state, { payload }) => {
            state.indexedMarketHubs = payload?.results?.features.reduce((acum, obj) => {
                acum[obj.id] = obj;
                return acum;
            }, {});
            state.marketHubs = {
                loading: false,
                results: payload?.results?.features.map((d) => ({ id: d.id, ...d.properties })) || [],
            };
        },
        [loadPipelines.pending]: (state) => {
            state.pipelines = {
                ...state.pipelines,
                loading: true,
            };
        },
        [loadPipelines.fulfilled]: (state, { payload }) => {
            const indexed = payload?.results.reduce((acum, obj) => {
                acum[obj.id] = obj;
                return acum;
            }, {});
            state.pipelines = {
                loading: false,
                results: payload?.results || [],
                indexed,
            };
        },
        [loadStations.pending]: (state) => {
            state.stations = {
                ...state.stations,
                loading: true,
            };
        },
        [loadStations.fulfilled]: (state, { payload }) => {
            const indexed = payload?.features.reduce((acum, obj) => {
                acum[obj.id] = obj;
                return acum;
            }, state.stations?.indexed || {});
            state.stations = {
                indexed,
                loading: false,
                features: payload?.features || [],
            };
            if (payload?.count === 1) {
                state.stationDetails = payload?.features.slice().shift();
            }
        },
        [loadProducts.fulfilled]: (state, action) => {
            state.products = action.payload?.results || [];
        },
        [getBestPaths.pending]: (state, { payload }) => {
            state.loading = true;
            state.bestPaths = [];
            state.message = null;
        },
        [getBestPaths.rejected]: (state, { payload }) => {
            state.loading = false;
            state.asyncLoading = false;
            const body = ["Please check search parameters and try again."];
            if (payload) {
                Object.values(payload).forEach((errorText) => {
                    if (typeof errorText === "string") {
                        body.push(errorText);
                    }
                });
            }
            const title = "Search failed";
            state.message = { title, body };
            state.bestPaths = [];
        },
        [getBestPaths.fulfilled]: (state, { payload }) => {
            state.loading = false;
            const { id } = payload;
            state.asyncSearch[id] = payload;
        },
        [getAsyncResponse.pending]: (state) => {
            state.bestPaths = [];
            state.loading = false;
            state.asyncLoading = true;
        },
        [getAsyncResponse.rejected]: (state, { payload }) => {
            state.asyncLoading = false;
            const body = ["Please check search parameters and try again."];
            const title = "Request failed";
            state.message = { title, body };
        },
        [getAsyncResponse.fulfilled]: (state, { payload }) => {
            const { id, data, paths, success, max_path_steps, parameters } = payload;
            state.asyncResponse = { ...state.asyncResponse, [id]: payload };
            state.asyncSearch = {};
            if (data) {
                state.openRouteDetails = false;
                state.asyncLoading = false;
                state.bestPaths = paths || [];
                state.isSegmentSearch = !!parameters?.max_path_steps;
                state.bestPathResults = [...state.bestPaths];
                if (state.bestPaths?.length) {
                    state.selectedPath = state.bestPaths[0];
                }
            } else if (success === false) {
                state.asyncLoading = false;
                const body = ["Please check search parameters and try again."];
                const title = "Request failed";
                state.message = { title, body };
            }
        },
        [getHasFullAccess.fulfilled]: (state, { payload }) => {
            state.hasFullAccess = payload;
        },
        [getPath.pending]: (state, { payload }) => {
            state.selectedPath = {
                loading: true,
            };
        },
        [getPath.fulfilled]: (state, { payload }) => {
            state.selectedPath = payload;
            if (!state.bestPaths?.length) {
                state.bestPaths = [state.selectedPath];
                state.isSegmentSearch = false;
            }
        },
        [getRouteSuggestions.pending]: (state, { payload }) => {
            state.routeSuggestions = {
                loading: true,
            };
        },
        [getRouteSuggestions.fulfilled]: (state, { payload }) => {
            state.routeSuggestions = {
                results: payload,
            };
        },
        [getTariffs.pending]: (state, { payload }) => {
            state.tariffs = {
                loading: true,
            };
        },
        [getTariffs.fulfilled]: (state, { payload }) => {
            const tariffs = {
                ...payload,
            };
            tariffs.indexed = payload?.results.reduce((acum, obj) => {
                acum[obj.id] = obj;
                return acum;
            }, {});
            state.tariffs = tariffs;
        },
        [getSearchHistory.fulfilled]: (state, { payload }) => {
            state.searchHistory = payload;
        },
        [getSelectedPath.fulfilled]: (state, { payload }) => {
            state.openRouteDetails = false;
            state.bestPaths = [payload];
            state.isSegmentSearch = false;
            if (payload?.origin_station?.properties) {
                state.origin = payload.origin_station.properties;
            }
            if (payload?.destination_station?.properties) {
                state.destination = payload.destination_station.properties;
            }
        },
        [loadTariffHeadingTypes.fulfilled]: (state, { payload }) => {
            state.tariffHeadingTypes = payload.results.reduce((acum, d) => {
                acum[d.id] = d.name;
                return acum;
            }, {});
        },
        [loadTariffTypes.fulfilled]: (state, { payload }) => {
            state.tariffTypes = payload.results.reduce((acum, d) => {
                acum[d.id] = d.name;
                return acum;
            }, {});
        },
    },
});

export const {
    clearAsyncResponse,
    resetSearch,
    setAllowedPipelines,
    setAsyncResponse,
    setAvoidSteps,
    setBestPaths,
    setCommittedRates,
    setCommodityGrade,
    setCommodityType,
    setDestination,
    setExcludedPipelines,
    setLoading,
    setMaxPipelines,
    setMessage,
    setOrigin,
    setPage,
    setRouteDetailOpen,
    setSearchSuggestions,
    setSearchUrl,
    setSelectedCommodities,
    setSelectedPath,
    setSelectedProducts,
    setSelectedJurisdiction,
    setSelectedStation,
    setVolume,
    updateRouteDetailPanel,
    updateSearchFields,
    setBannerPipeline,
    setModalBannerPipeline,
    setSelectedEffective,
    setTariffId,
    countMapSearch,
} = searchSlice.actions;

export default searchSlice.reducer;
