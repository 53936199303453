import axios from "axios";

export const TOKEN_KEY = process.env.REACT_APP_ARBO_TOKEN;
export const ARBO_PROFILE_KEY = process.env.REACT_APP_ARBO_PROFILE_KEY;
export const ARBO_SETTINGS_KEY = process.env.REACT_APP_ARBO_SETTINGS_KEY;
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) && localStorage.getItem(ARBO_PROFILE_KEY);
export const MVT_PATHS = ["/mvt/liquids/station/", "/mvt/layers/records/"];
export const STRIPE_CHECKOUT_URL = process.env.REACT_APP_STRIPE_CHECKOUT_URL;

let accessToken = null;
let refreshTokenPromise = null;

const API_URL = `${process.env.REACT_APP_API_BASE_URL}api`;

axios.defaults.baseURL = `${API_URL}/`;

const refreshInstance = axios.create({
    baseURL: API_URL,
});

const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ARBO_PROFILE_KEY);
    window.location.replace("/login");
};

const getToken = () => {
    return accessToken;
};

export const getUserProfile = () => {
    try {
        return JSON.parse(localStorage.getItem(ARBO_PROFILE_KEY));
    } catch (e) {
        logout();
    }
};
export const getReferralsUrl = () => JSON.parse(localStorage.getItem(ARBO_PROFILE_KEY))?.referrals_url;

export const useIsFirstLogin = () => {
    const userProfile = getUserProfile();
    if (userProfile?.attributes) {
        const { last_login, first_login } = userProfile?.attributes;
        return last_login === first_login;
    }

    return false;
};

export const getAuthorizationHeaders = () => {
    const accessToken = getToken();
    return { authorization: `Bearer ${accessToken}` };
};

function refreshToken(cb) {
    if (isAuthenticated()) {
        const storageToken = localStorage.getItem(TOKEN_KEY);
        refreshTokenPromise = refreshInstance
            .post("/refresh/", { refresh: storageToken })
            .catch(() => {
                logout();
            })
            .then((response) => {
                if (response?.data?.access) {
                    accessToken = response.data.access;
                    return Promise.resolve(accessToken);
                }
            })
            .then(cb);
    } else {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(ARBO_PROFILE_KEY);
        window.location.replace("/");
    }

    return refreshTokenPromise;
}

axios.interceptors.request.use(async (config) => {
    let token = accessToken;
    if (!token) {
        token = refreshTokenPromise ? await refreshTokenPromise : await refreshToken();
        refreshTokenPromise = null;
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

refreshInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error && error.response) {
            switch (error.response.status) {
                case /^5\d{2}/.test(error.response.status): {
                    return Promise.reject(error);
                }
                default: {
                    return Promise.reject(error.response.data);
                }
            }
        } else {
            return Promise.reject("Network error");
        }
    },
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = error.response ? error.response.status : null;
        if (status === 401 && error.config.isRetry) {
            logout();
            // Retry only once, it prevents infinite loops
            return Promise.reject("Auth error");
        }
        if (status === 401 && !error.config.isRetry) {
            return refreshToken(() => {
                return axios.request({ ...error.config, isRetry: true }); // Re try
            });
        } else if (error && error.response) {
            switch (error.response.status) {
                case /^5\d{2}/.test(error.response.status): {
                    return Promise.reject(error);
                }
                default: {
                    return Promise.reject(error.response.data);
                }
            }
        } else {
            return Promise.reject(error);
        }
    },
);

const handleTokenResponse = (response) => {
    if (response?.data.refresh) {
        localStorage.setItem(TOKEN_KEY, `${response.data.refresh}`);
        localStorage.setItem(
            ARBO_PROFILE_KEY,
            JSON.stringify({
                ...response.data.profile,
                subscription: response.data.subscription,
            }),
        );
        accessToken = response.data.access;
    }
    return response.data;
};

const login = (email, password) =>
    refreshInstance.post("/token/", { username: email, password: password }).then(handleTokenResponse);

const userRegister = ({ email, password, firstName, lastName, company, phone, ref, jobRole, plan, prices }) => {
    const params = {
        email: email.toLowerCase(),
        first_name: firstName,
        last_name: lastName,
        job_role: jobRole,
        password,
        plan,
        prices,
        phone_number: phone,
        company_name: company,
        ref,
    };
    return refreshInstance.post("/register/", params).then(({ data }) => data);
};

const passwordReset = async ([new_password1, new_password2]) =>
    await axios.post(`/password/change/`, { new_password1, new_password2 });

const phoneLogin = (email) => refreshInstance.post("/users/phone/login", { email }).then(({ data }) => data);

const phoneVerify = async ({ phone_number, session_token, security_code }) => {
    return refreshInstance
        .post("/users/phone/verify", { phone_number, session_token, security_code })
        .then(handleTokenResponse);
};

const emailInvite = async ({ emails }) => axios.post("/invite/", { emails }).then(({ data }) => data);
const emailShare = async ({ emails }) => axios.post("/share/", { emails }).then(({ data }) => data);

const emailLogin = (email) => refreshInstance.post("/users/magic-link/login", { email }).then(({ data }) => data);
const forgotPassword = (email) =>
    refreshInstance.post("/users/magic-link/forgot_password", { email }).then(({ data }) => data);
const emailVerify = async ({ email, key }) =>
    refreshInstance.post("/users/magic-link/verify", { email, key }).then(handleTokenResponse);

const patchUserProfile = async ({ id, data }) => {
    const response = await axios.patch(`/users/profiles/${id}`, data);
    const response_data = response?.data ? response.data : {};
    const profile = getUserProfile();
    const newData = { ...response_data, subscription: profile.subscription };
    localStorage.setItem(ARBO_PROFILE_KEY, JSON.stringify(newData));
    return response.data;
};

export const patchUserAttributes = async (data = {}) => {
    const { id, attributes } = getUserProfile();
    return await patchUserProfile({
        id,
        data: {
            attributes: {
                ...attributes,
                ...data,
            },
        },
    });
};

export const patchUser = async (data = {}) => {
    const { id } = getUserProfile();
    return await patchUserProfile({
        id,
        data,
    });
};

const getAccounts = async () => {
    const response = await axios.get("/users/account");
    return response.data;
};
const getAccount = async (id) => await axios.get(`/users/account/${id}`)?.data;
const updateAccount = async ({ id, name, description }) => {
    const response = await axios.patch(`/users/account/${id}`, {
        name,
        description,
    });
    return response.data;
};

const getInviteLinks = async () => await axios.get("/users/account-invite-link");

const createInviteLinks = async ({ id, email, first_name, last_name }) => {
    const response = await axios.post("/users/account-invite-link", {
        id,
        email,
        first_name,
        last_name,
    });
    return response.data;
};

const verifyInviteLink = async ({ account, key, email }) =>
    await refreshInstance
        .post("/users/account-invite-link/verify", {
            account,
            email,
            key,
        })
        .then(handleTokenResponse);

const deleteInviteLink = async (id) => await axios.delete(`/users/account-invite-link/${id}`);
const getJobRoles = async () => await refreshInstance.get(`/users/role`);

const contactSupport = async ({ message }) => {
    const response = await axios.post("/contact-support/", { message });
    await axios.post("/support/ticket/", { content: message });

    return response;
};

export const getLocalPreferences = async (id) => {
    const store = JSON.parse(localStorage.getItem(ARBO_SETTINGS_KEY)) || {};
    return id ? store[id] : store;
};

export const saveLocalPreferences = async ({ id, data }) => {
    const store = getLocalPreferences();
    const newStore = { ...store };
    newStore[id] = data;
    localStorage.setItem(ARBO_SETTINGS_KEY, JSON.stringify(newStore));
    return newStore;
};

export const setFeatureCount = (id) => {
    const { attributes } = getUserProfile();
    const count = +attributes[id] || 0;

    const data = {};
    data[id] = count + 1;
    patchUserAttributes(data);
    return data[id];
};

export const userHasGroup = (group) => getUserProfile()?.groups?.find((d) => d.name === group);

export const getFeatureCount = (id) => {
    const { attributes } = getUserProfile();
    const count = +attributes[id] || 0;

    return count;
};

export default {
    contactSupport,
    createInviteLinks,
    deleteInviteLink,
    emailInvite,
    emailLogin,
    emailShare,
    emailVerify,
    forgotPassword,
    getAccount,
    getAccounts,
    getFeatureCount,
    getInviteLinks,
    getLocalPreferences,
    getUserProfile,
    login,
    logout,
    passwordReset,
    patchUserAttributes,
    patchUserProfile,
    phoneLogin,
    phoneVerify,
    saveLocalPreferences,
    setFeatureCount,
    updateAccount,
    userRegister,
    userHasGroup,
    verifyInviteLink,
    getJobRoles,
};
