import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "antd";
import _ from "lodash";
import { trackEvent } from "api/analytics";

import { loadCommodityTypes } from "reducers/searchSlice";
const { Option } = Select;

export default function SelectFiler({ value, onChange, disabled = false, context = "" }) {
    const dispatch = useDispatch();
    const { commodityTypes, loadingCommodities } = useSelector(({ search }) => search);
    const [textValue, setTextValue] = useState("");
    const [commodityType, setCommodityType] = useState();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!commodityTypes.length) {
            dispatch(loadCommodityTypes());
        } else {
            const opts = commodityTypes?.map((item) => ({
                label: item.name,
                value: item.id,
                selected: value ? value === item.id : false,
            }));
            setOptions(_.orderBy(opts, ["selected"], ["desc"]));
        }
    }, [commodityTypes, dispatch]);

    useEffect(() => {
        if (value) {
            setCommodityType(value);
            setTextValue(value);
        }
        if (commodityTypes) {
            const opts = commodityTypes?.map((item) => ({
                label: item.name,
                value: item.id,
                selected: value ? value === item.id : false,
            }));
            setOptions(_.orderBy(opts, ["selected"], ["desc"]));
        }
    }, [value, commodityTypes]);

    return commodityTypes?.length || loadingCommodities ? (
        <Select
            allowClear
            value={commodityTypes.length ? commodityType : undefined}
            style={{
                width: "100%",
                maxWidth: 374,
            }}
            placeholder="Select"
            size="large"
            loading={!commodityTypes.length}
            className="commodity-type-select"
            onChange={(value) => {
                trackEvent(
                    `${context} Search change commodity type`,
                    commodityTypes.find(({ id }) => id === value),
                );
                onChange(value);
            }}
        >
            {commodityTypes.map((item) => (
                <Option key={item.id} value={item.id}>
                    {item.name}
                </Option>
            ))}
        </Select>
    ) : (
        <Input
            defaultValue={textValue}
            value={textValue}
            placeholder="Commodity"
            onChange={({ target: { value } }) => setTextValue(value)}
            onPressEnter={() => onChange(textValue)}
        />
    );
}
