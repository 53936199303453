import React from "react";
import { Layout } from "antd";
import { Row, Col } from "antd";

import "../account/styles.scss";

import PublicPage from "../templates/PublicPage";
import Pricing from "./Pricing";
import { setAppTitle } from "Utils";

const { Content } = Layout;

export default function StaticPage() {
    setAppTitle(["Pricing"]);

    return (
        <PublicPage>
            <Content>
                <Row justify="space-around" align="middle" style={{ paddingTop: "80px", paddingBottom: "100px" }}>
                    <Col>
                        <h3 className="public-page-head-sub-title">LIQUIDS PLATFORM PRICING</h3>
                        <h1 className="public-page-head-title">
                            Master the midstream on the plan that’s best for you.
                        </h1>
                    </Col>
                </Row>
                <Row justify="space-around" align="middle">
                    <Col span={20}>
                        <Pricing page="Pricing" publicPage={true} />
                    </Col>
                </Row>
            </Content>
        </PublicPage>
    );
}
