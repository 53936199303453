import axios from "axios";

const getShapefiles = async ({ id, category, type }) => {
    const response = await axios.get(`/layers/records?layer=${id}&record_type=${type}&page_size=50`);
    return { ...response, type, category };
};

const getLayerViews = () =>
    axios.get("/layers/layer-views", {
        params: {
            ordering: "name",
        },
    });

const getRecursiveShapefile = async (endpoint) => {
    return await axios.get(endpoint);
};

const getLayerCategories = async () => {
    const response = await axios.get("/layers/layer-categories");
    const results = response.data?.results || [];
    return results.map((category) => {
        return { id: category.name, category: category.id };
    });
};

const getLayers = async () => {
    const response = await axios.get("/layers/layers");

    return { ...response.data };
};

const getPresignedPost = async (object_name) => {
    const response = await axios.post("/layers/zipped-shape-files/new_presigned_post", { object_name });
    return { ...response.data };
};

const postZippedShapeFiles = async ({ name, description, file }) =>
    await axios.post("/layers/zipped-shape-files", { name, description, file });

const getZippedShapeFiles = async (id) => await axios.get(`/layers/zipped-shape-files/${id}`);

export default {
    getLayerCategories,
    getLayers,
    getLayerViews,
    getPresignedPost,
    getRecursiveShapefile,
    getShapefiles,
    getZippedShapeFiles,
    postZippedShapeFiles,
};
