import React from "react";

import { Button, Modal } from "antd";

export default function ModalConfirm({
    title = "Confirm",
    text = "Are you sure you want to execute this action?",
    visible,
    onOk,
    onCancel,
    loading,
}) {
    return (
        <Modal
            className="arbo-modal"
            title={title}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            loading={loading}
            footer={[
                <Button key="submit" type="secondary" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key={1} type="primary" loading={loading} onClick={onOk}>
                    OK
                </Button>,
            ]}
        >
            <div>{text}</div>
        </Modal>
    );
}
