import axios from "axios";

const exportFinancialsFilings = (respondent, section_name, base64) => {
    const params = { respondent };
    if (section_name) {
        params.section_name = section_name;
    }
    if (base64) {
        params.output = "base64";
    }
    return axios
        .get("/legacy/v2/ferc/form6/financials_compared/export", {
            params,
        })
        .then((response) => response.data);
};

const getOwnedCompanies = ({ search }) =>
    axios
        .get("/legacy/v1/entities/companies/", {
            params: {
                active: true,
                expand: true,
                fields: "is_public,companies_as_owner",
                is_respondent_in: "form6",
                search,
                sort: "name",
                // has_owned: true,
                // is_public: true,
                // owned_is_respondent_in: "form6",
            },
        })
        .then((response) => response.data);

const getCompanies = ({ search, next }) => {
    const url = next || "/legacy/v1/entities/companies/";
    return axios
        .get(url, {
            params: {
                active: true,
                expand: true,
                fields: "is_public,companies_as_owner",
                is_respondent_in: "form6",
                search,
                sort: "name",
            },
        })
        .then((response) => response.data);
};

const getCompany = (id) => axios.get(`/legacy/v1/entities/companies/${id}`).then((response) => response.data);

const getCompanyAlias = (id) =>
    axios.get(`/legacy/v1/entities/company_aliases/${id}`).then((response) => response.data);

const getDocument = async (url) => {
    const response = await axios.get(url).then((response) => response.data);
    if (response.files) {
        return axios
            .get(response.files)
            .then((d) => d.data)
            .then((resp) => {
                return resp.data.map((doc) => {
                    return doc;
                });
            });
    }
    return response;
};

const getDocumentPdf = async (id) => {
    const response = await axios.get(`/legacy/documents/${id}`, {
        responseType: "arraybuffer",
    });
    return new Uint8Array(response.data);
};

const getDocumentHtml = async (id) => {
    const response = await axios.get(`/legacy/documents/${id}`);
    return response.data;
};

const getForms6 = ({ annual, offset, search, sort, year, limit }) =>
    axios
        .get(`/legacy/v2/ferc/form6/forms/`, {
            params: {
                approved: "true",
                expand: true,
                limit,
                offset,
                quarter: annual === "true" ? 4 : undefined,
                search,
                sort: sort || "-year",
                year,
            },
        })
        .then((response) => response.data);

const getForms6ById = (id) => axios.get(`/legacy/v2/ferc/form6/forms/${id}`).then((response) => response.data);

const getForms6ByRespondent = (respondent) =>
    axios
        .get(`/legacy/v2/ferc/form6/forms/`, {
            params: {
                sort: "-year",
                respondent,
                expand: true,
            },
        })
        .then((response) => response.data);

const getFinancials = (id) =>
    axios.get("/legacy/v2/ferc/form6/financials/", { params: { respondent: id } }).then((response) => response.data);

const getFinancialsFilings = (respondent, section_name) => {
    return axios
        .get("/legacy/v2/ferc/form6/financials_compared/", {
            params: {
                respondent,
                section_name,
            },
        })
        .then((response) => response.data);
};

const getShippers = (id) =>
    axios
        .get("/legacy/v1/ferc/contracts/liquid_shippers/", {
            params: {
                company: id,
                expand: true,
                limit: 0,
            },
        })
        .then((response) => response.data);

const getOwnerships = (id) =>
    axios
        .get("/legacy/v1/entities/ownerships/", {
            params: {
                owned: id,
                fields: "report_type,control_type_name,owner_name",
                active: true,
                expand: true,
            },
        })
        .then((response) => response.data);

export default {
    exportFinancialsFilings,
    getCompanies,
    getCompany,
    getCompanyAlias,
    getDocument,
    getDocumentHtml,
    getDocumentPdf,
    getFinancials,
    getFinancialsFilings,
    getForms6,
    getForms6ById,
    getForms6ByRespondent,
    getOwnedCompanies,
    getOwnerships,
    getShippers,
};
