import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { message, Col, Button, Pagination, Row, Typography } from "antd";

import { getCorridors } from "reducers/trackerSlice";
import { loadStationGroups } from "reducers/layoutSlice";
import ApiBookmarks from "api/api.bookmarks";
import { ReactComponent as Trash } from "assets/Icon/Trash.svg";
import BasicPage from "pages/templates/BasicPage";
import ActionLink from "components/atoms/ActionLink";
import BackButton from "components/atoms/BackButton";
import AdminTable from "components/molecules/AdminTable";
import ModalConfirm from "components/molecules/ModalConfirm";

const { Title } = Typography;

const ManageCorridors = () => {
    const dispatch = useDispatch();

    const stationGroups = useSelector(({ layout }) => layout.stationGroups);
    const corridors = useSelector(({ bookmarks }) => bookmarks.corridors);

    const [dataSource, setDataSource] = useState([]);
    const [deleteCorridor, setDeleteCorridor] = useState();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [pagination, setPagination] = useState({ pageSize: 10, total: 0, current: 1 });

    useEffect(() => {
        if (!stationGroups) {
            dispatch(loadStationGroups());
        }
    }, [stationGroups, dispatch]);

    const handleDelete = useCallback(() => {
        if (deleteCorridor) {
            setConfirmLoading(true);
            ApiBookmarks.deleteSavedSearch(deleteCorridor.id).then((response) => {
                message.success("Corridor deleted successfully");
                setDeleteCorridor();
                dispatch(getCorridors());
            });
        }
    }, [deleteCorridor, dispatch]);

    const stationGroupName = useCallback((id) => stationGroups?.find((d) => d.id === id)?.name, [stationGroups]);

    const columns = [
        {
            title: "Origin",
            dataIndex: "origin",
            key: "origin",
            width: "33%",
            render: (id) => stationGroupName(id),
        },
        {
            title: "Destination",
            dataIndex: "destination",
            key: "destination",
            width: "33%",
            render: (id) => stationGroupName(id),
        },
        {
            title: "Open Arbs",
            dataIndex: "paths_count",
            key: "paths_count",
            width: "33%",
        },
        {
            title: "",
            align: "center",
            dataIndex: "",
            key: "view-map",
            width: "15%",
            className: "text-right",
            render: (item) => {
                return (
                    <Button className="action-btn" type="link" onClick={(e) => setDeleteCorridor(item)}>
                        <Trash />
                    </Button>
                );
            },
        },
    ];

    useEffect(() => {
        if (!corridors) {
            dispatch(getCorridors({ ordering: "-created", page_size: pagination.pageSize }));
        } else {
            const data = corridors?.results?.map((row, key) => {
                const item = {
                    id: row.id,
                    key,
                    origin: row.parameters.origin_station_group,
                    destination: row.parameters.destination_station_group,
                    paths_count: row.latest_result?.paths_count,
                };
                return item;
            });
            setDataSource(data);
        }
        // eslint-disable-next-line
    }, [corridors, dispatch]);

    const onPaginationChange = useCallback(
        (page) => {
            setPagination({ ...pagination, current: page, total: corridors.count });
        },
        // eslint-disable-next-line
        [corridors],
    );

    useEffect(() => {
        if (pagination.total) {
            dispatch(getCorridors({ ordering: "-created", page_size: pagination.pageSize, page: pagination.current }));
        }
        // eslint-disable-next-line
    }, [pagination]);

    return (
        <BasicPage>
            <Row className="manage-corridors">
                <Col span={24} xxl={12} xl={16}>
                    <Row style={{ marginBottom: 30 }}>
                        <Col span={24}>
                            <BackButton url="/tracker" title="Back to Arb Tracker" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Title level={3}>Manage Corridors</Title>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <ActionLink url="/corridors/add" title="Add Corridor(s)" />
                        </Col>
                    </Row>
                    <AdminTable
                        className="manage-corridors__table no-wrap"
                        showHeader={true}
                        pagination={false}
                        loading={corridors?.loading}
                        columns={columns}
                        dataSource={dataSource}
                    >
                        <Pagination
                            total={corridors?.count}
                            onChange={onPaginationChange}
                            defaultCurrent={1}
                            current={pagination.current}
                            pageSize={pagination.pageSize}
                            showSizeChanger={false}
                            style={{ marginTop: 15 }}
                        />
                    </AdminTable>
                </Col>
            </Row>
            <ModalConfirm
                confirmLoading={confirmLoading}
                visible={!!deleteCorridor}
                text={
                    <>
                        <p>Are you sure you want to delete this corridor?</p>
                        <strong>{deleteCorridor?.origin}</strong>
                    </>
                }
                handleOk={handleDelete}
                handleCancel={() => setDeleteCorridor(0)}
            />
        </BasicPage>
    );
};

export default ManageCorridors;
