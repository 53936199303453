import "./App.less";
import "./assets/tailwind.css";
import "assets/main.css";

import React, { useEffect, useState, lazy, Suspense } from "react";

import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import Login from "pages/User/Login";
import PasswordReset from "pages/User/PasswordReset";
import CreatePassword from "pages/User/CreatePassword";
import UserRegister from "pages/User/Register";
import { initGtm, initAppcues, updateSession } from "api/analytics";

import SiderLayout from "pages/SiderLayout";
import { isAuthenticated } from "./api";
import LeftSearchDrawer from "components/searcher/leftSearchDrawer";
import NotFound from "pages/NotFound";
import PricingPage from "pages/pricing";

import AddCorridors from "pages/manage/AddCorridors";
import ManageCorridors from "pages/manage/ManageCorridors";

import OPTMLogin from "pages/optm/login";
import amplitude from "amplitude-js";
import { Spin } from "antd";
import { Helmet } from "react-helmet";

const Account = lazy(() => import("pages/account"));
const Settings = lazy(() => import("pages/settings/Settings"));
const Content = lazy(() => import("pages/content"));
const Document = lazy(() => import("pages/content/document"));
const OPTM = lazy(() => import("pages/optm"));
const MapPage = lazy(() => import("pages/map/Map"));
const Search = lazy(() => import("pages/search/Search"));
const RoutePage = lazy(() => import("pages/Route"));
const Form6Search = lazy(() => import("pages/form6"));
const NewTracker = lazy(() => import("pages/dashboard/tracker/NewTracker"));
const TermsPage = lazy(() => import("pages/Terms"));
const PrivacyPage = lazy(() => import("pages/Privacy"));
const Dashboard = lazy(() => import("pages/dashboard/Dashboard"));
const Tariff = lazy(() => import("pages/Tariff"));
const Models = lazy(() => import("pages/models"));

amplitude.getInstance().init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`);
initGtm();
if (isAuthenticated()) {
    initAppcues();
}

export default function Arbo() {
    const [showReset, setShowReset] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const [sessionUpdated, setSessionUpdated] = useState();

    const PrivateRoute = ({ redirectRoute, children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={(obj) => {
                    const { location } = obj;
                    if (isAuthenticated()) {
                        return children;
                    } else {
                        return (
                            <Redirect
                                to={{
                                    pathname: redirectRoute ? redirectRoute : "/login",
                                    state: { from: location },
                                }}
                            />
                        );
                    }
                }}
            />
        );
    };

    useEffect(() => {
        const searchParams = new URL(document.location).searchParams;
        if (searchParams.get("password_reset_modal")) {
            setShowReset(true);
        }
        if (searchParams.get("create_password_modal")) {
            setShowCreate(true);
        }
    }, [location]);

    return (
        <>
            <Helmet>
                <title>Arbo</title>
                <meta
                    name="description"
                    content={isAuthenticated() ? "Arbo" : "Sign-in to Arbo's Liquid Commerce Platform"}
                />
            </Helmet>

            {showReset ? (
                <PasswordReset
                    showModal={showReset}
                    onClose={() => {
                        setShowReset(false);
                        history.replace("/map/search");
                    }}
                />
            ) : (
                ""
            )}

            {showCreate ? (
                <CreatePassword
                    showModal={showCreate}
                    onClose={() => {
                        setShowCreate(false);
                        history.replace("/user/account");
                    }}
                />
            ) : (
                ""
            )}

            <Switch>
                <Route exact path="/">
                    <Redirect
                        to={{
                            pathname: isAuthenticated() ? "/map/search" : "/login",
                        }}
                    />
                </Route>

                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/login/:action">
                    <Login />
                </Route>

                <Route exact path="/optm/login">
                    <SiderLayout option={"optm"} title="Oil Pipeline Tariff Monitor" isPublic={true}>
                        <OPTMLogin />
                    </SiderLayout>
                </Route>
                <Route exact path="/register">
                    <UserRegister />
                </Route>
                <Route exact path="/register/verify">
                    <UserRegister success={true} />
                </Route>

                <Route exact path="/terms">
                    <Suspense fallback={<Spin />}>
                        <TermsPage />
                    </Suspense>
                </Route>

                <Route exact path="/privacy">
                    <Suspense fallback={<Spin />}>
                        <PrivacyPage />
                    </Suspense>
                </Route>

                <PrivateRoute path="/plans">
                    <Redirect to="/user/account" />
                </PrivateRoute>

                <PrivateRoute path="/tracker/:id">
                    <SiderLayout option={"tracker"} title="Arb Tracker">
                        <NewTracker />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/tracker">
                    <SiderLayout option={"tracker"} title="Arb Tracker">
                        <NewTracker />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/dashboard">
                    <SiderLayout option={"dashboard"} title="Dashboard">
                        <Dashboard />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/map">
                    <SiderLayout option={"map"} title="Map Search">
                        <LeftSearchDrawer />
                        <MapPage />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/path/:path_id">
                    <SiderLayout option={"path"} title="Route Details">
                        <RoutePage />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/search">
                    <SiderLayout option={"tariff"} title="Tariff Search">
                        <Search />
                    </SiderLayout>
                </PrivateRoute>
                <PrivateRoute path="/tariff/search">
                    <SiderLayout option={"tariff"} title="Tariff Search">
                        <Search />
                    </SiderLayout>
                </PrivateRoute>
                <PrivateRoute path="/tariff/:tariff_id">
                    <SiderLayout option={"tariff"} title="Tariff Details">
                        <Tariff />
                    </SiderLayout>
                </PrivateRoute>
                <PrivateRoute path="/tariff">
                    <SiderLayout option={"tariff"} title="Tariff Search">
                        <Search />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/form6/:action/:id">
                    <SiderLayout option={"form6"} title="Form 6 Search">
                        <Form6Search />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/form6">
                    <SiderLayout option={"form6"} title="Form 6 Search">
                        <Form6Search />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/models/throughput/:id">
                    <SiderLayout option={"models"} title="Throughput Models">
                        <Models />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/models/throughput">
                    <SiderLayout option={"models"} title="Throughput Models">
                        <Models />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/optm/document/:id">
                    <SiderLayout option={"optm"}>
                        <Document taxonomy="optm" />
                    </SiderLayout>
                </PrivateRoute>
                <PrivateRoute path="/optm/document" redirectRoute="/optm/login">
                    <SiderLayout option={"optm"} title="Oil Pipeline Tariff Monitor">
                        <Document />
                    </SiderLayout>
                </PrivateRoute>
                <PrivateRoute path="/optm/article" redirectRoute="/optm/login">
                    <SiderLayout option={"optm"} title="Oil Pipeline Tariff Monitor">
                        <Content taxonomy="optm" />
                    </SiderLayout>
                </PrivateRoute>
                <PrivateRoute path="/optm/:id" redirectRoute="/optm/login">
                    <SiderLayout option={"optm"} title="Oil Pipeline Tariff Monitor">
                        <OPTM />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/optm" redirectRoute="/optm/login">
                    <SiderLayout option={"optm"} title="Oil Pipeline Tariff Monitor">
                        <OPTM />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/models">
                    <Redirect
                        to={{
                            pathname: "/models/throughput",
                        }}
                    />
                </PrivateRoute>

                <PrivateRoute path="/user/password">
                    <SiderLayout option={"passwordReset"} title="Password reset">
                        <PasswordReset />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/user/settings">
                    <Redirect to="/user/account" />
                </PrivateRoute>

                <PrivateRoute path="/user/account/:action">
                    <SiderLayout option={"account"} title="Favorites & Notifications">
                        <Account />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/user/account">
                    <SiderLayout option={"account"} title="Favorites & Notifications">
                        <Account />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/corridors/add">
                    <SiderLayout option={"add-corridors"}>
                        <AddCorridors />
                    </SiderLayout>
                </PrivateRoute>

                <PrivateRoute path="/corridors">
                    <SiderLayout option={"corridors"}>
                        <ManageCorridors />
                    </SiderLayout>
                </PrivateRoute>

                <Route component={NotFound} />
            </Switch>
        </>
    );
}
