import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { message, Col, Row, Card, Skeleton, Typography } from "antd";

import ApiBookmarks from "api/api.bookmarks";
import ApiLiquids from "api/api.liquids";
import { getPreferences } from "reducers/settingsSlice";
import { getCorridors } from "reducers/trackerSlice";
import { loadStationGroups } from "reducers/layoutSlice";
import BasicPage from "pages/templates/BasicPage";
import BackButton from "components/atoms/BackButton";
import Button from "components/atoms/Button";
import SelectableCard from "components/molecules/SelectableCard";

const USER_COMMODITY_TYPES = [3];
const USER_VOLUME = 10000;
const SCHEDULE_ID = 1;

const { Text, Title } = Typography;

const AddCorridors = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [originGroups, setOrigin] = useState([]);
    const [originSelected, setOriginSelected] = useState([]);
    const [destinationGroups, setDestination] = useState([]);
    const [destinationSelected, setDestinationSelected] = useState([]);
    const savedStationGroups = useSelector(({ bookmarks }) => bookmarks.savedStationGroups);
    const stationGroups = useSelector(({ layout }) => layout.stationGroups);
    // Filter origins by Market Hub sgs

    useEffect(() => {
        if (!savedStationGroups && !loading) {
            dispatch(getPreferences());
        }
        // eslint-disable-next-line
    }, [savedStationGroups, dispatch]);

    useEffect(() => {
        if (!stationGroups && !loading) {
            setLoading(true);
            dispatch(loadStationGroups());
        }
        // eslint-disable-next-line
    }, [stationGroups, dispatch]);

    useEffect(() => {
        if (stationGroups && savedStationGroups?.results) {
            const savedIds = savedStationGroups.results.map((d) => d.id);
            const filtered = stationGroups.filter((d) => !savedIds.includes(d.id));
            setOrigin(filtered);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [stationGroups, savedStationGroups]);

    useEffect(() => {
        if (stationGroups) {
            const originIds = originSelected.map((d) => d.id);
            const filtered = stationGroups.filter((d) => !originIds.includes(d.id));
            setDestination(filtered);
        }
        // eslint-disable-next-line
    }, [stationGroups, originSelected]);

    const selectOrigin = useCallback((region) => {
        setOriginSelected([{ ...region, selected: true }]);
    }, []);

    const selectDestination = useCallback(
        (region) => {
            if (destinationSelected.includes(region.id)) {
                setDestinationSelected([...destinationSelected.filter((d) => d !== region.id)]);
            } else {
                setDestinationSelected([...destinationSelected, region.id]);
            }
        },
        [destinationSelected],
    );

    const saveCorridor = useCallback(() => {
        setSaving(true);
        const promises = originSelected
            .map((origin) =>
                destinationSelected.map((destination_id) => {
                    const params = {
                        origin_station_group: origin.id,
                        destination_station_group: destination_id,
                        commodity_types: USER_COMMODITY_TYPES,
                        volume: USER_VOLUME,
                    };
                    return ApiLiquids.getBestPath(params);
                }),
            )
            .flat();

        Promise.all(promises).then((responses) => {
            const corridorPromises = responses
                .map(({ data }) => {
                    const originName = stationGroups
                        .filter((d) => d.id === data.parameters.origin_station_group)
                        .shift()?.name;
                    const destinationName = stationGroups
                        .filter((d) => d.id === data.parameters.destination_station_group)
                        .shift()?.name;
                    const description = ["From", originName, "to", destinationName].join(" ");
                    return ApiBookmarks.saveCorridor({
                        description,
                        saved_id: data.id,
                        public: true,
                        schedule_id: SCHEDULE_ID,
                    }).then(() => {
                        message.success(`Saving corridor: ${description}`);
                    });
                })
                .flat();
            Promise.all(corridorPromises).then(() => {
                dispatch(getCorridors());
                setSaving(false);
                history.push("/corridors");
            });
        });

        // Api.saveSearch({});
    }, [originSelected, destinationSelected, stationGroups, dispatch, history]);

    const RenderOrigin = () => (
        <>
            <Row>
                <Col span={24}>
                    <Title level={2}>Step 1 of 2: select origin</Title>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ marginTop: 24 }}>
                    <Title level={3}>Your regions of interest</Title>
                </Col>
            </Row>
            <Row>
                {savedStationGroups?.loading ? (
                    <Col span={24} md={12} xl={8}>
                        <Card>
                            <Skeleton loading />
                        </Card>
                    </Col>
                ) : (
                    savedStationGroups?.results?.map((region, idx) => (
                        <Col key={idx} span={24} md={12} xl={8} style={{ paddingRight: 15, paddingBottom: 15 }}>
                            <SelectableCard onClick={() => selectOrigin(region)}>{region.name}</SelectableCard>
                        </Col>
                    ))
                )}
            </Row>
            <Row>
                <Col span={24} style={{ marginTop: 24 }}>
                    <Title level={3}>All regions</Title>
                </Col>
                {loading ? (
                    <Col span={24} md={12} xl={8}>
                        <Card>
                            <Skeleton loading />
                        </Card>
                    </Col>
                ) : (
                    originGroups.map((region, idx) => (
                        <Col key={idx} span={24} md={12} xl={8} style={{ paddingRight: 15, paddingBottom: 15 }}>
                            <SelectableCard onClick={() => selectOrigin(region)}>{region.name}</SelectableCard>
                        </Col>
                    ))
                )}
            </Row>
        </>
    );

    const RenderDestination = () => (
        <>
            <Row>
                <Col span={24}>
                    <Title level={2}>Step 2 of 2: select destination</Title>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ marginTop: 24 }}>
                    <Title level={3}>Selected origin</Title>
                </Col>
            </Row>
            <Row>
                {originSelected.map((region, idx) => (
                    <Col key={idx} span={24} md={12} xl={8} style={{ paddingRight: 15, paddingBottom: 15 }}>
                        <SelectableCard selected={true} onClick={() => {}}>
                            {region.name}
                        </SelectableCard>
                    </Col>
                ))}
            </Row>
            <Row>
                <Col span={24} style={{ marginTop: 24, marginBottom: 15 }}>
                    <Title level={3}>All regions</Title>
                </Col>
                <Col span={24} style={{ marginBottom: 30 }}>
                    <Text>Select one or more.</Text>
                </Col>
                {loading ? (
                    <Col span={24} md={12} xl={8}>
                        <Card>
                            <Skeleton loading />
                        </Card>
                    </Col>
                ) : (
                    destinationGroups?.map((region, idx) => (
                        <Col key={idx} span={24} md={12} xl={8} style={{ paddingRight: 15, paddingBottom: 15 }}>
                            <SelectableCard
                                selected={destinationSelected.includes(region.id)}
                                onClick={() => selectDestination(region)}
                            >
                                {region.name}
                            </SelectableCard>
                        </Col>
                    ))
                )}
            </Row>
            <Row
                style={{
                    marginBottom: 30,
                    marginTop: 30,
                }}
            >
                <Col
                    span={12}
                    style={{
                        textAlign: "left",
                    }}
                >
                    <Button
                        type="secondary"
                        onClick={() => {
                            setOriginSelected([]);
                        }}
                    >
                        Back
                    </Button>
                </Col>
                <Col
                    span={12}
                    style={{
                        textAlign: "right",
                    }}
                >
                    <Button
                        type="primary"
                        disabled={!destinationSelected.length}
                        loading={saving}
                        onClick={() => {
                            saveCorridor();
                        }}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </>
    );

    return (
        <BasicPage>
            <Row className="manage-corridors">
                <Col span={24} xxl={20}>
                    <Row style={{ marginBottom: 30 }}>
                        <Col span={24} md={12} xl={8}>
                            <BackButton url="/corridors" title="Back to Manage Corridors" />
                        </Col>
                    </Row>
                    {originSelected?.length ? RenderDestination() : RenderOrigin()}
                </Col>
            </Row>
        </BasicPage>
    );
};

export default AddCorridors;
