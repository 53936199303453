import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiLiquids from "api/api.liquids";

export const getTariffStatus = createAsyncThunk("tariff/getTariffStatus", ApiLiquids.getTariffStatus);

export const tariffSlice = createSlice({
    name: "tariff",
    initialState: {
        tariffStatus: null,
    },
    extraReducers: {
        [getTariffStatus.pending]: (state, { payload }) => {
            state.tariffStatus = {
                loading: true,
            };
        },
        [getTariffStatus.fulfilled]: (state, { payload }) => {
            state.tariffStatus = {
                ...payload,
                indexed: payload?.results.reduce((acum, obj) => {
                    acum[obj.id] = obj;
                    return acum;
                }, {}),
            };
        },
    },
    reducers: {},
});

// eslint-disable-next-line
export const {} = tariffSlice.actions;

export default tariffSlice.reducer;
