import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Radio, Row, Space, Input } from "antd";
import Text from "antd/es/typography/Text";

import Api from "api";
import { trackEvent } from "api/analytics";
import { saveAction } from "api/api.bookmarks";

import "./AppcueModal.less";

const { TextArea } = Input;

const AppcueFeedbackForm = ({
    event,
    id,
    onClose,
    title,
    description,
    question,
    allowFeedback = false,
    props = {},
}) => {
    const [value, setValue] = useState();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [message, setMessage] = useState(null);

    const storeId = `feedback-form-${id}`;

    const sendAction = async () => {
        setLoading(true);
        const eventId = `${event}-feedback-form`;
        trackEvent(eventId, {
            value,
            message,
            ...props,
        });
        const data = { sent: true, event, id, value, message };
        setData(data);
        Api.saveLocalPreferences({ id: storeId, data });
        saveAction({
            action_type: "feedback",
            app_label: "liquids",
            model: "asyncresponsedata",
            object_id: id,
            data,
        });
    };

    const closeAction = useCallback(() => {
        const data = { sent: false, event, id, value };
        setData(data);
        Api.saveLocalPreferences({ id: storeId, data });
    }, [storeId, id, event, value]);

    useEffect(() => {
        if (data) {
            setTimeout(
                () => {
                    onClose();
                },
                data?.sent ? 1000 : 0,
            ); // Apply delay only when submitting.
        } else {
            Api.getLocalPreferences(storeId).then(setData);
        }
    }, [data, storeId]);

    return (
        <>
            {title ? (
                <Row style={{ justifyContent: "center" }}>
                    <Col span={24} style={{ maxWidth: 480, margin: 15 }} className="appcue-modal__title">
                        {title}
                    </Col>
                </Row>
            ) : null}
            {!data?.sent ? (
                <>
                    {description ? (
                        <Row style={{ justifyContent: "center" }}>
                            <Col
                                span={24}
                                style={{ maxWidth: 480, marginBottom: 30 }}
                                className="appcue-modal__description"
                            >
                                {description}
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={24} style={{ maxWidth: 400, margin: "0 auto" }}>
                            <Row>
                                {!!errorMessages.length && (
                                    <Col span={24}>
                                        {errorMessages.map((err, idx) => (
                                            <div key={idx} style={{ marginBottom: 24 }}>
                                                <Alert message={err} type="error" closable showIcon />
                                            </div>
                                        ))}
                                    </Col>
                                )}
                                {question ? (
                                    <>
                                        <Col span={24}>
                                            <Text className="input-label">{question}</Text>
                                        </Col>
                                        <Col span={24} style={{ paddingTop: 15 }}>
                                            <Radio.Group onChange={(e) => setValue(e.target.value)}>
                                                <Space direction="vertical">
                                                    <Radio value="yes">Yes</Radio>
                                                    <Radio value="no">No</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Col>
                                    </>
                                ) : (
                                    ""
                                )}
                            </Row>
                        </Col>
                    </Row>
                    {allowFeedback ? (
                        <Row style={{ justifyContent: "center", paddingTop: 15 }}>
                            <Col span={24} style={{ maxWidth: 480, marginBottom: 0 }} className="appcue-modal__input">
                                Tell us more:
                            </Col>
                            <Col span={24}>
                                <TextArea rows={3} value={message} onChange={(e) => setMessage(e.target.value)} />
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={24} style={{ marginTop: 30, position: "relative" }}>
                            <Button
                                type="primary"
                                disabled={(question && !value) || !message}
                                loading={loading}
                                onClick={sendAction}
                                style={{
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                    marginRight: 15,
                                }}
                            >
                                Submit
                            </Button>
                            <Button
                                type="link"
                                onClick={closeAction}
                                className="cancel-btn"
                                style={{ position: "absolute", right: 0 }}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row style={{ justifyContent: "center" }}>
                        <Col
                            span={24}
                            style={{ textAlign: "center", maxWidth: 480, margin: 15 }}
                            className="input-label"
                        >
                            Thanks for your feedback!
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} align="right" style={{ marginTop: 100, textAlign: "right" }}>
                            <Button type="link" onClick={closeAction} className="cancel-btn">
                                Close
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default AppcueFeedbackForm;
