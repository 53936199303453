import React, { useState } from "react";
import { Col, Modal, Row } from "antd";
import { message, Button, Input } from "antd";
import Text from "antd/es/typography/Text";

function SaveModal({ visible, onCancel, defaultValue, saveMethod, entityName, readOnly }) {
    const [name, setName] = useState();
    const [loading, setLoading] = useState(false);

    const saveAction = async () => {
        try {
            setLoading(true);
            await saveMethod(name || defaultValue);
            onCancel();
            message.success(`${entityName} saved`);
        } catch (e) {
            console.error(e);
            if (e.non_field_errors) {
                if (
                    e.non_field_errors?.slice()?.shift() === "The fields saved_id, created_by must make a unique set."
                ) {
                    message.warning(`${entityName} already saved.`);
                } else {
                    message.warning(e.non_field_errors);
                }
                onCancel();
            } else {
                message.error(`Error saving ${entityName}`);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={visible}
            width={480}
            className="arbo-modal"
            footer={null}
            destroyOnClose={true}
            afterClose={() => {
                setName(undefined);
            }}
            onCancel={onCancel}
            title={`SAVE ${entityName}`}
        >
            <Row>
                <Col
                    span={24}
                    style={{
                        marginBottom: 24,
                    }}
                >
                    <Text className="description">Do you want to name this {entityName}?</Text>
                </Col>
                <Col span={24}>
                    <Text className="input-label">Saved {entityName} Name</Text>
                    <Input
                        disabled={readOnly}
                        size="large"
                        style={{
                            marginTop: 8,
                        }}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                        value={name}
                        defaultValue={defaultValue}
                    />
                </Col>
            </Row>
            <Row>
                <Col
                    span={24}
                    style={{
                        marginTop: 33,
                    }}
                >
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={() => {
                            saveAction();
                        }}
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            marginRight: 15,
                        }}
                    >
                        Save
                    </Button>
                    <Button type="link" onClick={onCancel} className="cancel-btn">
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}

export default SaveModal;
