import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { Col, Row, Spin, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";

import ApiLiquids from "api/api.liquids";
import ApiSearch from "api/api.search";
import { downloadPdf } from "Utils";
import { trackEvent } from "api/analytics";
import { saveAction } from "api/api.bookmarks";
import { getTariffStatus } from "reducers/tariffSlice";
import BookmarkStar from "components/bookmarks/BookmarkStar";

import "./SubwayTiles.less";

import ExportRates from "./ExportRates";
import CircleButton from "../utils/CircleButton";
import { ReactComponent as Download } from "assets/Icon/Download.svg";
import { ReactComponent as MapSearchIcon } from "assets/Icon/Destination.svg";
import FreeFeatureCount from "../organisms/FreeFeatureCount";
import "./styles.less";

const PdfViewer = lazy(() => import("components/utils/PdfViewer"));

export default function TariffDetail({ tariff, children }) {
    const dispatch = useDispatch();
    const location = useLocation();

    const [showBanner, setShowBanner] = useState(false);
    const { isStaffUser } = useSelector(({ layout }) => layout);
    const { tariffStatus } = useSelector(({ tariff }) => tariff);

    const { data: related } = useQuery(["getRelatedTariffs", tariff.id], () => ApiLiquids.getRelatedTariffs(tariff.id));
    const { data: updatedTariff } = useQuery(["getTariff", tariff.id], () => ApiLiquids.getTariff(tariff.id));

    const url = tariff?.id ? `${process.env.REACT_APP_API_BASE_URL}api/liquids/tariff/${tariff?.id}/tariff` : "";

    const [pipeline, setPipeline] = useState(tariff?.pipeline);
    const [pdfFile, setPdfFile] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [commodityType, setCommodityType] = useState();
    const [selectedTariff, setSelectedTariff] = useState();

    const { isLoading: loadingPipelines, data: pipelines } = useQuery("getPipelineAliases", () =>
        ApiLiquids.getPipelines({ ordering: "name", page_size: 5000 }),
    );

    useEffect(() => {
        if (!pipeline?.name && !loadingPipelines && pipelines?.data?.results) {
            const pipelineObj = pipelines.data.results.find((pipe) => {
                return pipe.id === pipeline;
            });

            setPipeline(pipelineObj);
        }
    }, [pipeline, loadingPipelines, pipelines]);

    useEffect(() => {
        if (selectedTariff?.commodities?.length) {
            setCommodityType(selectedTariff?.commodities[0]?.commodity_type);
        }
    }, [selectedTariff]);

    useEffect(() => {
        if (updatedTariff?.data) {
            setSelectedTariff(updatedTariff.data);
        }
    }, [updatedTariff]);

    const adminUrl =
        isStaffUser && tariff?.id ? process.env.REACT_APP_API_BASE_URL + "admin/liquids/tariff/" + tariff?.id : "";

    useEffect(() => {
        if (tariff && !loaded) {
            setLoaded(true);
            try {
                ApiSearch.getDocumentPdf(tariff?.id).then((response) => {
                    const base64String = new Uint8Array(response);
                    setPdfFile(base64String);
                });
            } catch (e) {
                console.log("error loading document PDF: ");
                console.log(e);
                setPdfFile("Error");
            }
        }
    }, [tariff]);

    useEffect(() => {
        if (tariff && location?.hash === "#download") {
            trackEvent("Download Tariff PDF", `${pipeline.name} - ${tariff?.name}`);
            saveAction({
                action_type: "download",
                app_label: "liquids",
                model: "tariff",
                object_id: tariff.id,
            });
            saveAction({
                action_type: "download",
                app_label: "liquids",
                model: "pipeline",
                object_id: tariff.pipeline.id || tariff.pipeline,
                data: {
                    tariff_id: tariff.id,
                },
            });
            document.location.hash = "";
            ApiSearch.getDocumentPdf(tariff?.id).then((response) => {
                const base64String = new Uint8Array(response);
                downloadPdf(base64String, `${pipeline.name} - ${tariff?.name}`);
            });
        }
    }, [location, tariff]);

    useEffect(() => {
        if (!tariffStatus) {
            dispatch(getTariffStatus());
        }
    }, [tariffStatus]);

    useEffect(() => {
        if (tariff && pipeline) {
            trackEvent("Tariff Search - Open View Modal", { name: tariff?.name, pipeline: pipeline.name });
            saveAction({ action_type: "open", app_label: "liquids", model: "tariff", object_id: tariff.id });
            saveAction({
                action_type: "open",
                app_label: "liquids",
                model: "pipeline",
                object_id: tariff.pipeline.id || tariff.pipeline,
                data: {
                    tariff_id: tariff.id,
                },
            });
        } else if (tariff && !tariff?.url) {
            trackEvent("Tariff Search - PDF Unavailable", { name: tariff?.name, pipeline: pipeline.name });
        }
    }, [tariff, pipeline]);

    useEffect(() => {
        if (showBanner) {
            setTimeout(() => {
                setShowBanner(false);
            }, 5100);
        }
    }, [showBanner]);

    return tariff?.name ? (
        <>
            {children}
            {showBanner ? (
                <Row>
                    <Col span={24}>
                        <FreeFeatureCount
                            banner={true}
                            count={0}
                            contactLink={true}
                            text="You discovered a PRO feature! Upgrade to export rate tables."
                        />
                    </Col>
                </Row>
            ) : null}
            <Row className="tariff-details mb-4" style={children ? { marginTop: "80px" } : undefined}>
                <Col span={18} style={{ paddingBottom: 15, paddingRight: 15 }}>
                    <Link style={{ color: "#126dff" }} className="-link" to={`/tariff/search?pipeline=${pipeline?.id}`}>
                        {tariff?.pipeline.name ? (
                            <div className="modal-title flex items-center font-medium text-2xl mb-2">
                                <BookmarkStar
                                    app_label="liquids"
                                    model="pipeline"
                                    object_id={tariff?.pipeline.id}
                                    bellIcon={true}
                                />
                                {tariff?.pipeline.name}
                            </div>
                        ) : null}
                    </Link>
                    <div className="modal-subtitle flex items-center mb-2">
                        <BookmarkStar app_label="liquids" model="tariff" object_id={tariff?.id} />
                        <span className="text-lg font-medium">{tariff?.name}</span>
                        <span className="text-lg ml-8">
                            <span className="font-semibold">Effective</span>{" "}
                            {moment(tariff?.effective_date).utc().format("MM/DD/YYYY")}
                        </span>
                    </div>
                    <div className="mb-2">
                        {(tariffStatus?.indexed && tariff?.tariff_status) || tariff?.status ? (
                            <>
                                <span className="font-semibold">Status: </span>
                                {tariff?.status || tariffStatus.indexed[tariff?.tariff_status]?.name}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="modal-description flex items-center mb-4 text-lg">{tariff?.summary}</div>
                    <div className="">
                        {tariff?.filing?.amendment_protest_summary &&
                        tariff?.filing?.amendment_protest_summary != "None" ? (
                            <div>
                                <span className="font-semibold">Summary of Amendments or Protests: </span>
                                {tariff?.filing?.amendment_protest_summary}
                            </div>
                        ) : (
                            ""
                        )}
                        {tariff?.filing?.commission_action && tariff?.filing?.commission_action != "None" ? (
                            <div>
                                <span className="font-semibold">Summary of Commission Action: </span>
                                {tariff?.filing?.commission_action}
                            </div>
                        ) : (
                            ""
                        )}
                        <div>
                            {tariff?.filing?.docket_number ? (
                                <>
                                    <span className="font-semibold">Docket Number: </span>
                                    {tariff?.filing?.docket_number}
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </Col>
                <Col span={6} style={{ paddingBottom: 15, textAlign: "right" }}>
                    <div className="flex items-end flex-row items-center justify-end">
                        {adminUrl && (
                            <div className="pr-8 inline-block">
                                <Tooltip title="Manage tariff">
                                    <a href={adminUrl} target="_blank">
                                        <EditOutlined style={{ fontSize: 18 }} />
                                    </a>
                                </Tooltip>
                            </div>
                        )}
                        {selectedTariff?.min_rate ? (
                            <div className="pr-4 inline-block">
                                <Link
                                    to={`/map/search?tariff_id=${tariff?.id}&commodity_types=${commodityType}`}
                                    className="flex font-bold"
                                >
                                    <MapSearchIcon
                                        className="blue-icon"
                                        title="View Tariff Rates Map"
                                        horizontal={true}
                                        onClick={() => {
                                            trackEvent(
                                                "Tariff Search - Map Search",
                                                `${pipeline.name} - ${tariff?.name}`,
                                            );
                                            saveAction({
                                                action_type: "map_search",
                                                app_label: "liquids",
                                                model: "tariff",
                                                object_id: tariff.id,
                                            });
                                        }}
                                    >
                                        View <br /> Rates <br /> Map
                                    </MapSearchIcon>
                                </Link>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="pr-4 inline-block">
                            <CircleButton
                                horizontal={true}
                                onClick={() => {
                                    trackEvent("Tariff Search - Download PDF", `${pipeline.name} - ${tariff?.name}`);
                                    saveAction({
                                        action_type: "download",
                                        app_label: "liquids",
                                        model: "tariff",
                                        object_id: tariff.id,
                                    });
                                    saveAction({
                                        action_type: "download",
                                        app_label: "liquids",
                                        model: "pipeline",
                                        object_id: tariff.pipeline.id || tariff.pipeline,
                                        data: {
                                            tariff_id: tariff.id,
                                        },
                                    });
                                    downloadPdf(pdfFile, `${pipeline.name} - ${tariff?.name}`);
                                }}
                                icon={<Download />}
                            >
                                <span>PDF</span>
                            </CircleButton>
                        </div>
                        <ExportRates
                            tariff={tariff}
                            showBanner={() => {
                                setShowBanner(true);
                            }}
                            onAction={(type) => {
                                saveAction({
                                    action_type: "export",
                                    app_label: "liquids",
                                    model: "tariff",
                                    object_id: tariff.id,
                                });
                                saveAction({
                                    action_type: "export",
                                    app_label: "liquids",
                                    model: "pipeline",
                                    object_id: tariff.pipeline.id || tariff.pipeline,
                                    data: {
                                        tariff_id: tariff.id,
                                    },
                                });
                                switch (type) {
                                    case "rates":
                                        trackEvent("Tariff Search - Export Tariff Rates");
                                        break;
                                    case "discounts":
                                        trackEvent("Tariff Search - Export Tariff Rate Discounts");
                                        break;
                                    case "charges":
                                        trackEvent("Tariff Search - Export Tariff Rate Extra Charges");
                                        break;
                                }
                            }}
                        >
                            <div className="flex items-end flex-col">
                                <CircleButton
                                    horizontal={true}
                                    onClick={() => {}}
                                    blue={true}
                                    icon={<Download />}
                                    className=""
                                >
                                    <span>Export</span>
                                    <span>Rates</span>
                                </CircleButton>
                            </div>
                        </ExportRates>
                    </div>
                </Col>
                <div className="items-center mb-4">
                    {related?.length ? <div className="font-bold text-lg mb-2">Related Tariffs</div> : ""}
                    {related?.map((obj, key) => (
                        <div key={key} className="flex mb-2">
                            {obj.tariff_id !== tariff.id ? (
                                <div className="mr-1">
                                    <Link to={`/tariff/${obj.tariff_id}`} className="flex font-bold">
                                        <BookmarkStar app_label="liquids" model="tariff" object_id={obj.tariff_id} />
                                        {obj.tariff_name}
                                    </Link>
                                </div>
                            ) : (
                                ""
                            )}
                            <div className="mr-1">{obj.related_tariff_type.name}</div>
                            {obj.related_tariff_id !== tariff.id ? (
                                <div className="mr-1 flex">
                                    <Link to={`/tariff/${obj.related_tariff_id}`} className="flex font-bold">
                                        <BookmarkStar
                                            app_label="liquids"
                                            model="tariff"
                                            object_id={obj.related_tariff_id}
                                        />
                                        {obj.related_tariff_name}
                                    </Link>
                                </div>
                            ) : (
                                " this tariff."
                            )}
                        </div>
                    ))}
                </div>
            </Row>
            <Row>
                {(url && pdfFile && pdfFile !== "Error") || (url && !pdfFile) ? (
                    <Col span={24}>
                        <Suspense fallback={<Spin />}>
                            <PdfViewer pdfFile={pdfFile} />
                        </Suspense>
                    </Col>
                ) : (
                    <Col span={24} style={{ padding: 24 }}>
                        Tariff PDF currently unavailable.
                    </Col>
                )}
            </Row>
        </>
    ) : (
        <Spin />
    );
}
