import axios from "axios";
import { MAP_TOKEN } from "components/geo/config";

const BASE_URL = "https://api.mapbox.com/geocoding/v5";

const mapboxApi = axios.create({
    baseURL: BASE_URL,
});

export const getCountyByName = async (text, lat, lng) => {
    return await mapboxApi
        .get(`/mapbox.places/${text}.json`, {
            params: {
                country: "us",
                access_token: MAP_TOKEN,
                types: ["neighborhood", "district", "locality", "place", "region"],
            },
        })
        .then((response) => response.data);
};

export default {
    getCountyByName,
};
