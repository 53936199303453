import { getCountyByName } from "api/api.mapbox";
import ApiLiquids from "api/api.liquids";

export const checkCountyGeometry = async (station) => {
    if (station.geometry) {
        return station;
    }
    const name = station?.properties?.county_name;
    const state = !isNaN(station?.properties?.state)
        ? await ApiLiquids.getStateById(station.properties.state)
        : station?.properties?.state;
    const countyData = await getCountyByName([name, state?.name].join(", "));
    const geometry = countyData.features[0]?.geometry;
    return { ...station, geometry };
};

export default {
    checkCountyGeometry,
};
