import _ from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { message, Alert, Button, Col, Form, Input, Row, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { ReactComponent as LoginBack } from "assets/Icon/Arrow/loginBack.svg";

import Api, { getUserProfile } from "api";
import { trackEvent, initAppcues } from "api/analytics";
import moment from "moment";

const { Title } = Typography;

export default function Login({ onLoginSuccess, title, resetPassword }) {
    const [alertMessages, setAlertMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [form] = Form.useForm();

    const userWelcome = (response) => {
        const name = response?.profile?.user?.first_name;
        message.success(`Welcome ${name}!`);
    };

    const handleSubmit = (values) => {
        const { email, password } = values;

        setLoading(true);
        Api.login(email.toLowerCase(), password)
            .then((resp) => {
                userWelcome(resp);
                const userProfile = getUserProfile();
                trackEvent("Account Mgmt - Log in", { method: "password" });
                Api.patchUserAttributes({ last_login: moment().toISOString() });
                onLoginSuccess(userProfile);
                initAppcues();
            })
            .catch((resp) => {
                setLoading(false);
                handleApiErrors(resp);
            });
    };

    const handlePasswordReset = (values) => {
        const { email } = values;

        setLoading(true);
        Api.forgotPassword(email.toLowerCase())
            .then((resp) => {
                message.info(`A login email reset link has been sent to your email ${email}.`);
                setLoading(false);
            })
            .catch((resp) => {
                setLoading(false);
                handleApiErrors(resp);
            });
    };

    const submitEmailLogin = () => {
        setEmailLoading(true);
        const email = form.getFieldValue("email");
        if (!email) {
            form.validateFields(["email"]);
            message.warning("Please enter your email.");
            setEmailLoading(false);
            return;
        }

        Api.emailLogin(email)
            .then(() => {
                setEmailLoading(false);
                message.info(`A login link has been sent to your email ${email}.`);
                trackEvent("Account Mgmt - Log in", { method: "email" });
            })
            .catch((err) => {
                handleApiErrors(err);
                console.log("ERR", err);
                setEmailLoading(false);
            });
    };

    const handleApiErrors = (err) => {
        console.log("ERR", err);
        const errors = [];
        if (err && _.isString(err)) {
            errors.push("The connection to the server cannot be established. Please try again.");
            setAlertMessages([
                "There is currently a degradation issue that may be affecting our platform. The issue has been identified and the resolution is in progress.",
            ]);
        } else {
            setAlertMessages([]);
            Object.values(err).forEach((error) => {
                errors.push(error);
            });
        }
        message.error(errors);
    };

    return resetPassword ? (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handlePasswordReset}
            layout="vertical"
            form={form}
        >
            <Row className="login-form__body">
                <Col span={24}>
                    <Title
                        level={1}
                        style={{
                            marginBottom: "15px",
                        }}
                    >
                        Reset password
                    </Title>
                </Col>
                <Col span={24} style={{ paddingBottom: 24 }}>
                    <span
                        style={{
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "#535455",
                        }}
                    >
                        Enter the email address you used to register for Arbo.
                    </span>
                    <br />
                    <span
                        style={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "19px",
                            color: "#535455",
                        }}
                    >
                        If we recognize this email, we’ll send you a magic login link to reset your password in the
                        platform.
                    </span>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, type: "email", message: "Please enter your email address." }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email Address" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className="flex items-center" style={{ marginBottom: 15 }}>
                        <div className="flex" style={{ flexGrow: 1 }}>
                            <Button
                                type="primary"
                                loading={loading}
                                style={{ width: "220px" }}
                                htmlType="submit"
                                className="login-form__button"
                            >
                                Email password reset link
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col span={24} style={{ paddingTop: "40px" }}>
                    <Link to={"/login"} className="loginBackBtn">
                        <LoginBack />
                        Back to login
                    </Link>
                </Col>
            </Row>
        </Form>
    ) : (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            layout="vertical"
            form={form}
        >
            <Row>
                {!!alertMessages.length && (
                    <Col span={24}>
                        {alertMessages.map((msg, idx) => {
                            const type = "error";
                            return (
                                <div key={idx} style={{ marginBottom: 24 }}>
                                    <Alert message={msg} type={type} closable showIcon />
                                </div>
                            );
                        })}
                    </Col>
                )}
            </Row>
            <Row className="login-form__body">
                <Col span={24}>{title ? <Title level={3}>{title}</Title> : <Title level={1}>Sign in</Title>}</Col>
                <Col span={24} style={{ paddingBottom: 24, paddingTop: 12 }}>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Don't have an account? <Link to="/register">Sign up.</Link>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, type: "email", message: "Please enter your email address." }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email Address" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, min: 2, message: "Please enter your password." }]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password (Optional)"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <div className="flex items-center" style={{ marginBottom: 15 }}>
                        <div className="flex" style={{ flexGrow: 1 }}>
                            <Button
                                type="primary"
                                loading={loading}
                                style={{ width: "200px" }}
                                htmlType="submit"
                                className="login-form__button"
                            >
                                Log in
                            </Button>
                        </div>
                        <div className="flex">
                            <Link to={"/login/reset"}>Reset password</Link>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            type="primary"
                            loading={emailLoading}
                            onClick={submitEmailLogin}
                            style={{
                                color: "#126dff",
                                fontSize: 14,
                                border: "1px solid #126dff",
                                width: "200px",
                                backgroundColor: "white",
                            }}
                        >
                            Email magic login link
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
