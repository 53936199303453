import _ from "lodash";
import moment from "moment-timezone";

export const API_DATE_FORMAT = "YYYY-MM-DD";

export const PLANS_COLORS = {
    FREE: "rgb(254, 128, 101)",
    PRO: "rgb(170, 139, 75)",
    TEAM: "rgb(18, 109, 255)",
    ENTERPRISE: "rgb(151, 151, 151)",
    "OPTM+INSIGHTS": "rgb(151, 151, 151)",
};

export function generateRouteLabel(origin_station, destination_station, pipelines) {
    let label = "";
    if (origin_station && destination_station) {
        label = `${origin_station.properties?.name} to ${destination_station.properties?.name}`;
    } else if (origin_station) {
        label = `From ${origin_station.properties?.name}`;
    } else if (destination_station) {
        label = `To ${destination_station.properties?.name}`;
    }

    if (pipelines && pipelines.length) {
        label += `, Via ${pipelines.map((pipeline) => pipeline.name).join(", ")}`;
    }

    return label;
}

export const roundToTwo = (num) => +(Math.round(num + "e+2") + "e-2");
export const formatCurrency = (num) =>
    new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(num);
export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const calcPricingHubContractDifferentials = (prices, contractName) => {
    const contractPrices = prices.filter((priceItem) => priceItem.contract === contractName);
    let valueToDisplay = null;
    let difference = null;

    if (contractPrices.length > 1) {
        const latestValObj = contractPrices[contractPrices.length - 1];
        const dayBackValObj = contractPrices[contractPrices.length - 2];
        if (contractName === "CL") {
            difference = latestValObj.outright_price - dayBackValObj.outright_price;
            valueToDisplay = `$${parseFloat(latestValObj.outright_price).toFixed(3)}`;
        } else {
            difference = latestValObj.value - dayBackValObj.value;
            valueToDisplay = parseFloat(latestValObj.value).toFixed(3);
        }

        const outrightPrice = latestValObj.outright_price;

        return {
            valueToDisplay,
            difference,
            outrightPrice,
        };
    }

    console.warn("Not enough pricing data for contract: ", contractName);
    return null;
};

export const getPricingLastUpdatedMomentDate = (prices) => {
    const lastUpdatedObj = _.sortBy(prices, (p) => moment(p?.last_updated).valueOf()).pop();
    return moment(lastUpdatedObj?.last_updated);
};

export const copyToClipboard = (text) => {
    const textField = document.createElement("textarea");
    textField.innerHTML = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
};

export const maskPhone = (phone) => {
    return phone.slice(0, 2) + " [***] **** " + phone.slice(9, 12);
};

export const getRouteUrl = (path, commodity_types = [3]) => {
    return `/map/search?origin_station=${path?.origin_station?.id}&destination_station=${path?.destination_station?.id}&commodity_types=${commodity_types}`;
};

export const buildSearchUrlFromSavedProperties = (properties) => {
    const arrayKeys = ["allowed_pipelines", "forbidden_pipelines", "commodities", "specific_products"];
    const parts = [];

    Object.keys(properties).forEach((key) => {
        if (key.indexOf("station") > -1 && properties[key]) {
            parts.push(`${key}=${properties[key]}`);
            return;
        }

        if (key === "commodity_types" && properties[key].length) {
            parts.push(`commodity_types=${properties[key][0]}`);
            return;
        }

        if (key === "volume" && properties[key].length) {
            parts.push(`volume=${properties[key]}`);
            return;
        }

        if (arrayKeys.indexOf(key) > -1 && properties[key].length) {
            parts.push(`${key}=${properties[key]}`);
            return;
        }

        if (key.indexOf("polygon") > -1 && properties[key]) {
            parts.push(`${key}=${JSON.stringify(properties[key])}`);
        }
    });

    const query = parts.join("&");
    return `/map/search/?${query}`;
};

export const moneyFormat = (value) => {
    return value ? `$${value}` : null;
};

const dec2hex = (dec) => dec.toString(16).padStart(2, "0");

export const randomString = (length = 12) => {
    var arr = new Uint8Array(length / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const setAppTitle = (parts = []) => {
    parts.unshift("Arbo");
    document.title = parts.filter((d) => d).join(" - ");
};

export const downloadBase64File = (dataURI, filename) => {
    const byteString = atob(dataURI.split(",")[1]);

    const ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab]);
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export const convertToCSV = (array) => {
    var str = "";
    for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
            if (line !== "") line += ",";

            line += '"' + array[i][index] + '"';
        }

        str += line + "\r\n";
    }

    return str;
};

export const exportCSVFile = (items, fileTitle) => {
    // Convert Object to JSON
    var csv = convertToCSV(items);
    downloadCsvFile(csv, fileTitle);
};

export const downloadCsvFile = (csv, fileTitle) => {
    var exportedFilename = (fileTitle || "export") + ".csv";
    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    downloadFile(blob, exportedFilename);
};

export const downloadFile = (blob, filename) => {
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

export const downloadPdf = (data, filename = `document-${+new Date()}`) => {
    const blob = new Blob([data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${filename}.pdf`;
    link.click();
};

export const filterEmptyKeys = (d) =>
    Object.keys(d)
        .filter((key) => d[key])
        .reduce((obj, key) => {
            obj[key] = d[key];
            return obj;
        }, {});

export const uniqueValues = (list) =>
    list.flatMap((e) => e).reduce((acum, d) => (acum.includes(d) ? acum : [...acum, d]), []);

export const getIdFromUrl = (url) =>
    url
        .split("/")
        .filter((d) => d)
        .pop();

export const getFilterDates = () => {
    const dates = [];
    const now = moment();
    for (let i = 0; i < 10; i++) {
        dates.push([now.format("YYYY"), now.format("MMMM")]);
        now.add(1, "M");
    }
    return dates;
};
