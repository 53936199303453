import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Divider, Typography, Tag, Row, Col, Collapse } from "antd";
import { ReactComponent as Directions } from "assets/Icon/directions.svg";
import { ReactComponent as Save } from "assets/Icon/Save.svg";
import CircleButton from "components/utils/CircleButton";
import { useDispatch } from "react-redux";
import { clearStationDetails } from "reducers/layoutSlice";
import { setOrigin } from "reducers/searchSlice";
import SaveModal from "components/molecules/SaveModal";
import ApiBookmarks from "api/api.bookmarks";
import { ReactComponent as Minus } from "assets/Icon/Minus.svg";
import { ReactComponent as Plus } from "assets/Icon/Plus.svg";
import { getBookmarks } from "reducers/bookmarksSlice";
import { setAppTitle } from "Utils";
import DocumentModal from "components/routes/DocumentModal";
import BookmarkStar from "components/bookmarks/BookmarkStar";

const { Title, Text } = Typography;
const { Panel } = Collapse;

function StationDetails({ station }) {
    const dispatch = useDispatch();
    const [showSaveStation, setShowSaveStation] = useState(false);
    const [modalDocument, setModalDocument] = useState();

    const saveStation = async (nameToSave) => {
        await ApiBookmarks.saveBookmark({
            app_label: "liquids",
            model: "station",
            description: nameToSave,
            object_id: station.id,
            data: {
                name: station?.properties?.name,
                sub_station_name: station?.properties?.sub_station_name,
                address_street: station?.properties?.address_street,
                address_number: station?.properties?.address_number,
                city: station?.properties?.city,
                location: station?.properties?.county_name,
                description: nameToSave,
            },
        });

        dispatch(getBookmarks("station"));
    };

    useEffect(() => {
        setAppTitle(["Station", station?.properties?.name]);
    }, [station]);

    const renderTags = (props) => {
        const commoditiesNames = _.concat(props.commodity_types_as_origin, props.commodity_types_as_destination).map(
            (item) => item.name,
        );

        return _.uniq(commoditiesNames).map((name) => {
            return (
                <Tag key={`tag-${name}`} color="#aa8b4b">
                    {name}
                </Tag>
            );
        });
    };

    return (
        <div className="station-details">
            {modalDocument ? (
                <DocumentModal
                    document={modalDocument}
                    visible={!!modalDocument}
                    onCancel={() => {
                        setModalDocument(false);
                    }}
                />
            ) : null}
            <SaveModal
                visible={showSaveStation}
                defaultValue={station?.properties?.name}
                onCancel={() => {
                    setShowSaveStation(false);
                }}
                entityName="Station"
                saveMethod={saveStation}
            />
            <div className="section">
                <Text
                    strong
                    style={{
                        textTransform: "uppercase",
                    }}
                    className="header-label"
                >
                    STATION
                </Text>
                <Title level={4} className="flex">
                    <BookmarkStar app_label="liquids" model="station" object_id={station?.id} />
                    {station.properties.name}
                </Title>
                <Text className="subheader-label">{station.properties.sub_station_name}</Text>
            </div>
            <Divider />
            <div className="section">{renderTags(station.properties)}</div>
            <Divider />
            <div className="section">
                <Collapse
                    className="collapse-filter station-details"
                    expandIconPosition="right"
                    expandIcon={(props) => {
                        return props.isActive ? <Minus /> : <Plus />;
                    }}
                >
                    <Panel header="As Origin" key="1">
                        <ul className="details">
                            {station.properties.pipelines_as_origin.length > 0 && (
                                <li>
                                    <Text strong className="header-label">
                                        PIPELINES
                                    </Text>
                                    {station.properties.pipelines_as_origin.map((item) => (
                                        <Text key={`pipelines_as_origin-${item.name}`} className="subheader-label">
                                            {item.name}
                                        </Text>
                                    ))}
                                </li>
                            )}
                            {station.properties.tariffs_as_origin?.length > 0 && (
                                <li>
                                    <Text strong className="header-label">
                                        TARIFFS
                                    </Text>
                                    {station.properties.tariffs_as_origin.map((item) => (
                                        <Text
                                            key={`tariffs_as_origin-${item.name}`}
                                            className="subheader-link"
                                            onClick={() => {
                                                setModalDocument(item);
                                            }}
                                        >
                                            {item.name}
                                        </Text>
                                    ))}
                                </li>
                            )}
                            {station.properties.commodity_types_as_origin.length > 0 && (
                                <li>
                                    <Text strong className="header-label">
                                        COMMODITIES
                                    </Text>
                                    {station.properties.commodity_types_as_origin.map((item) => (
                                        <Text
                                            key={`commodity_types_as_origin-${item.name}`}
                                            className="subheader-label"
                                        >
                                            {item.name}
                                        </Text>
                                    ))}
                                </li>
                            )}
                            {station.properties.origin_neighbors.features.length > 0 && (
                                <li>
                                    <Text strong className="header-label">
                                        NEIGHBORS
                                    </Text>
                                    {station.properties.origin_neighbors.features.map((item) => (
                                        <Text
                                            key={`origin_neighbors-${item.properties.id}`}
                                            className="subheader-label"
                                        >
                                            {item.properties.name}
                                        </Text>
                                    ))}
                                </li>
                            )}
                        </ul>
                    </Panel>
                </Collapse>
                <Divider />
                <Collapse
                    className="collapse-filter station-details"
                    expandIconPosition="right"
                    expandIcon={(props) => {
                        return props.isActive ? <Minus /> : <Plus />;
                    }}
                >
                    <Panel header="As Destination" key="1">
                        <ul className="details">
                            {station.properties.pipelines_as_destination.length > 0 && (
                                <li>
                                    <Text strong className="header-label">
                                        PIPELINES
                                    </Text>
                                    {station.properties.pipelines_as_destination.map((item) => (
                                        <Text key={`pipelines_as_destination-${item.name}`} className="subheader-label">
                                            {item.name}
                                        </Text>
                                    ))}
                                </li>
                            )}
                            {station.properties.tariffs_as_destination?.length > 0 && (
                                <li>
                                    <Text strong className="header-label">
                                        TARIFFS
                                    </Text>
                                    {station.properties.tariffs_as_destination.map((item) => (
                                        <Text
                                            key={`tariffs_as_destination-${item.name}`}
                                            className="subheader-link"
                                            onClick={() => {
                                                setModalDocument(item);
                                            }}
                                        >
                                            {item.name}
                                        </Text>
                                    ))}
                                </li>
                            )}
                            {station.properties.commodity_types_as_destination.length > 0 && (
                                <li>
                                    <Text strong className="header-label">
                                        COMMODITIES
                                    </Text>
                                    {station.properties.commodity_types_as_destination.map((item) => (
                                        <Text
                                            key={`commodity_types_as_destination-${item.name}`}
                                            className="subheader-label"
                                        >
                                            {item.name}
                                        </Text>
                                    ))}
                                </li>
                            )}
                            {station.properties.destination_neighbors.features.length > 0 && (
                                <li>
                                    <Text strong className="header-label">
                                        NEIGHBORS
                                    </Text>
                                    {station.properties.destination_neighbors.features.map((item) => (
                                        <Text
                                            key={`destination_neighbors-${item.properties.id}`}
                                            className="subheader-label"
                                        >
                                            {item.properties.name}
                                        </Text>
                                    ))}
                                </li>
                            )}
                        </ul>
                    </Panel>
                </Collapse>
                <Divider
                    style={{
                        marginBottom: 15,
                    }}
                />
                <ul className="details">
                    {station.properties.operator && (
                        <li>
                            <Text strong className="header-label">
                                OPERATOR
                            </Text>
                            <Text className="subheader-label">{station.properties.operator}</Text>
                        </li>
                    )}

                    {!!station.geometry?.coordinates && (
                        <li>
                            <Text strong className="header-label">
                                LOCATION
                            </Text>
                            <Text className="subheader-label">
                                {station.geometry.coordinates[0]} , {station.geometry.coordinates[1]}
                            </Text>
                        </li>
                    )}

                    {station.properties.types.length > 0 && (
                        <li>
                            <Text strong className="header-label">
                                TYPES
                            </Text>
                            {station.properties.types.map((item) => (
                                <Text key={`type-${item.name}`} className="subheader-label">
                                    {item.name}
                                </Text>
                            ))}
                        </li>
                    )}
                    <li>
                        <Text strong className="header-label">
                            PIPELINE DIAMETERS
                        </Text>
                        {station.properties.diameter ? (
                            <Text className="subheader-label">{station.properties.diameter}</Text>
                        ) : (
                            <Text
                                style={{
                                    fontStyle: "italic",
                                }}
                                className="subheader-label"
                            >
                                Unknown by Arbo
                            </Text>
                        )}
                    </li>
                    <li>
                        <Text strong className="header-label">
                            PIPE vs TRUCK CONNECTED
                        </Text>
                        <Text
                            className="subheader-label"
                            style={{
                                fontStyle: "italic",
                            }}
                        >
                            Coming Soon
                        </Text>
                    </li>
                </ul>
            </div>
            <Divider />
            <div className="section" style={{ minHeight: 150 }}>
                <Row justify="center" style={{ textAlign: "center" }}>
                    {/*<Col span={8}>
                        <CircleButton icon={<AllPaths />}>View All Paths</CircleButton>
                    </Col>*/}
                    <Col span={8}>
                        <CircleButton
                            onClick={() => {
                                dispatch(clearStationDetails());
                                dispatch(
                                    setOrigin({ id: station.id, name: station.properties?.name, type: "station" }),
                                );
                            }}
                            icon={<Directions />}
                        >
                            <span>Find</span>
                            <span>Routes</span>
                        </CircleButton>
                    </Col>
                    <Col span={8}>
                        <CircleButton
                            icon={<Save />}
                            onClick={() => {
                                setShowSaveStation(true);
                            }}
                        >
                            <span>Save</span>
                            <span>Station</span>
                        </CircleButton>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default StationDetails;
