import { getLayers, getLayerType, getPaintAndLayoutByType } from "./utils/MVT";

export const MAP_TOKEN = "pk.eyJ1IjoidmljbWl0Y2giLCJhIjoiY2tlb2QwZTNjMjZoYjJ0cGM4bHc4Y3JzcyJ9.dhOGrS7tP7NvwgU68poXvA";

export const MAP_CONFIG = {
    palette: {
        basemap: {
            land: "#8C919A",
            water: "#7B808A",
        },
        layers: {
            stations: {
                main: "#fe8065",
                rest: "#aa8b4b",
                stroke: "#fff",
            },
            counties: {
                primary: "#FE8065",
                secondary: "#fff",
            },
            routes: {
                primary: "#2D538E",
            },
        },
    },
};

const DYNAMIC_PAINT_LAYOUT = {
    lines: {
        paint: {
            "line-color": MAP_CONFIG.palette.layers.routes.primary,
            "line-width": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 1, 22, 3],
            "line-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0.4],
        },
        layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
        },
    },
    points: {
        paint: {
            "circle-color": "#2579ff",
            "circle-stroke-width": ["case", ["boolean", ["feature-state", "hover"], false], 2, 0],
            "circle-stroke-color": "#0044b3",
            "circle-radius": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 5, 22, 8],
        },
        layout: {
            visibility: "visible",
        },
    },
    polygons: {
        paint: {
            "line-color": "#586374",
            "line-width": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 1, 22, 3],
            "line-opacity": 0.9,
        },
        layout: {
            visibility: "visible",
        },
    },
};

function dynamicLayersInfo({ id, type, category, name }) {
    const layers = [];

    for (const t in type) {
        const subId = Number(t) + 1;
        layers.push({
            id: `Dynamic-layer-${id}-${subId}`,
            isVector: true,
            sourceId: `dynamic-source-${id}-${subId}`,
            sourceLayer: "default",
            url: [
                `${process.env.REACT_APP_API_BASE_URL}mvt/layers/records/?layer=${id}&record_type=${type[t]}&tile={z}/{x}/{y}`,
            ],
            type: getLayerType(type[t]),
            ...getPaintAndLayoutByType(type[t], DYNAMIC_PAINT_LAYOUT),
            promoteId: "id",
            category: category?.id,
            name,
            disableClick: true,
            popupFieldsConfig: {
                Pipename: {
                    label: "Pipeline",
                },
                Opername: {
                    label: "Operator",
                },
            },
        });
    }

    return layers;
}

export const LAYERS_SOURCES = [
    {
        sourceId: "Pricing_Hubs",
        url: "mapbox://vicmitch.ckk5wfuw618pe27nq3j9pfb98-1nx7c",
        promoteId: "id",
    },
];

export const LAYERS_INFO = {
    dynamicMVT: async () => {
        try {
            const layersInfo = await getLayers();
            const layersConfig = layersInfo.map((layer) => dynamicLayersInfo(layer));
            return layersConfig.flatMap((l) => l);
        } catch (e) {
            console.log("error");
            console.log(e);
            return [];
        }
    },
    selectedStation: {
        layout: {
            "icon-offset": [0, -10],
            "icon-image": "selected_marker",
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
            visibility: "visible",
        },
    },
    routesLayer: {
        paths: {
            paint: {
                "line-width": 4,
                "line-opacity": 1,
                "line-color": "#162947",
            },
        },
        pathsStroked: {
            paint: {
                "line-width": 7,
                "line-opacity": 1,
                "line-color": "#fff",
            },
        },
        origin: {
            layout: {
                "icon-offset": [0, -10],
                "icon-image": "selected_origin",
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                visibility: "visible",
            },
        },
        destination: {
            layout: {
                "icon-offset": [0, -10],
                "icon-image": "selected_destination",
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                visibility: "visible",
            },
        },
    },
    pricing: {
        paths: {
            paint: {
                "line-width": 4,
                "line-opacity": 1,
                "line-gradient": [
                    "interpolate",
                    ["linear"],
                    ["line-progress"],
                    0,
                    "#fff",
                    1,
                    MAP_CONFIG.palette.layers.stations.rest,
                ],
            },
        },
        main: {
            paint: {
                "circle-color": MAP_CONFIG.palette.layers.stations.main,
                "circle-stroke-width": 2,
                "circle-stroke-color": MAP_CONFIG.palette.layers.stations.stroke,
                "circle-radius": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 5, 22, 8],
            },
        },
        mainHalo: {
            paint: {
                "circle-color": MAP_CONFIG.palette.layers.stations.main,
                "circle-opacity": 0.6,
                "circle-stroke-width": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 10, 22, 15],
                "circle-stroke-color": MAP_CONFIG.palette.layers.stations.main,
                "circle-stroke-opacity": 0.3,
                "circle-radius": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 16, 22, 22],
            },
        },
        rest: {
            paint: {
                "circle-color": MAP_CONFIG.palette.layers.stations.rest,
                "circle-stroke-width": 2,
                "circle-stroke-color": "#FFFFFF",
                "circle-radius": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 5, 22, 8],
            },
        },
        restHalo: {
            paint: {
                "circle-color": MAP_CONFIG.palette.layers.stations.rest,
                "circle-opacity": 0.3,
                "circle-stroke-width": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 10, 22, 15],
                "circle-stroke-color": "lightgrey",
                "circle-stroke-opacity": 0.1,
                "circle-radius": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 16, 22, 22],
            },
        },
    },
    pricingHubs: {
        id: "Pricing_Hubs",
        isVector: true,
        static: true,
        sourceLayer: "Pricing_Hubs",
        sourceId: "Pricing_Hubs",
        type: "circle",
        paint: {
            "circle-color": [
                "case",
                ["==", ["get", "name"], "Cushing"],
                MAP_CONFIG.palette.layers.stations.main,
                MAP_CONFIG.palette.layers.stations.rest,
            ],
            "circle-stroke-width": 2,
            "circle-stroke-color": "#FFFFFF",
            "circle-radius": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 5, 22, 8],
        },
        layout: {
            visibility: "visible",
        },
        category: -2,
        disableClick: false,
        popupFieldsConfig: {
            name: {
                fieldLabel: "name",
                label: "Name",
            },
        },
    },
    pricingHubs_halo: {
        id: "Pricing_Hubs_styles",
        isVector: true,
        static: true,
        sourceId: "Pricing_Hubs",
        sourceLayer: "Pricing_Hubs",
        type: "circle",
        duplicate: true,
        paint: {
            "circle-color": [
                "case",
                ["==", ["get", "name"], "Cushing"],
                MAP_CONFIG.palette.layers.stations.main,
                MAP_CONFIG.palette.layers.stations.rest,
            ],
            "circle-opacity": 0.3,
            "circle-stroke-width": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 10, 22, 15],
            "circle-stroke-color": "lightgrey",
            "circle-stroke-opacity": 0.1,
            "circle-radius": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 16, 22, 22],
        },
        layout: {
            visibility: "visible",
        },
        category: -2,
        disableClick: false,
        popupFieldsConfig: {
            name: {
                fieldLabel: "name",
                label: "Name",
            },
        },
    },
    counties: {
        id: "Counties",
        isVector: true,
        static: true,
        sourceId: "counties_source_id",
        sourceLayer: "USA_counties-134wyr",
        url: "mapbox://vicmitch.dxnofl7v",
        type: "line",
        paint: {
            "line-color": [
                "interpolate",
                ["linear"],
                ["zoom"],
                4,
                MAP_CONFIG.palette.layers.counties.secondary,
                12,
                MAP_CONFIG.palette.layers.counties.primary,
            ],
            "line-opacity": ["interpolate", ["linear"], ["zoom"], 4, 0.3, 12, 1],
            "line-width": 1,
        },
        layout: {
            visibility: "visible",
        },
        promoteId: "ID_2",
        category: -2,
        disableClick: true,
    },
    counties_fill: {
        id: "CountiesFill",
        isVector: true,
        duplicate: true,
        sourceId: "counties_source_id",
        sourceLayer: "USA_counties-134wyr",
        url: "mapbox://vicmitch.dxnofl7v",
        type: "fill",
        paint: {
            "fill-color": "transparent",
        },
        layout: {
            visibility: "visible",
        },
        promoteId: "ID_2",
        category: -2,
        disableClick: true,
    },
    counties_labels: {
        id: "Counties_labels",
        sourceId: "counties_source_id",
        sourceLayer: "USA_counties-134wyr",
        duplicate: true,
        type: "symbol",
        layout: {
            "text-field": "{NAME_2}",
            "text-size": 10,
            "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
            "text-letter-spacing": 0.1,
            "text-max-width": 7,
            visibility: "visible",
        },
        paint: {
            "text-color": MAP_CONFIG.palette.layers.counties.secondary,
            "text-halo-color": [
                "interpolate",
                ["linear"],
                ["zoom"],
                4,
                MAP_CONFIG.palette.layers.counties.secondary,
                12,
                MAP_CONFIG.palette.layers.counties.primary,
            ],
            "text-halo-width": 1,
        },
        promoteId: "ID_2",
        maxZoom: 15,
        category: -2,
        disableClick: true,
    },
    shp: {
        lines: {
            linePaint: {
                "line-color": MAP_CONFIG.palette.layers.routes.primary,
                "line-width": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 1, 22, 3],
                "line-opacity": 0.4,
            },
            lineLayout: {
                "line-cap": "round",
                "line-join": "round",
                visibility: "visible",
            },
        },
        points: {
            circlePaint: {
                "circle-color": "#2579ff",
                "circle-stroke-width": ["case", ["boolean", ["feature-state", "hover"], false], 2, 0],
                "circle-stroke-color": "#0044b3",
                "circle-radius": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 5, 22, 8],
            },
            circleLayout: {
                visibility: "visible",
            },
        },
        polygons: {
            linePaint: {
                "line-color": "#9CA5B4",
                "line-width": ["interpolate", ["exponential", 0.7], ["zoom"], 1, 1, 22, 5],
                "line-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0.7],
            },
            lineLayout: {
                visibility: "visible",
            },
        },
    },
    stations: {
        id: "Stations",
        isVector: true,
        static: true,
        sourceId: "stations_source_id",
        sourceLayer: "default",
        url: [`${process.env.REACT_APP_API_BASE_URL}mvt/liquids/station/?tile={z}/{x}/{y}`],
        type: "symbol",
        paint: {
            "icon-opacity": 0.8,
        },
        layout: {
            "icon-image": "stations_marker",
            "icon-allow-overlap": {
                stops: [
                    [4, false],
                    [5, true],
                ],
            },
            "text-allow-overlap": true,
            visibility: "visible",
        },
        popupFieldsConfig: {
            name: {
                fieldLabel: "name",
                label: "Name",
            },
            sub_station_name: {
                fieldLabel: "substation name",
                label: "Substation",
            },
        },
        promoteId: "id",
        category: -1,
    },
};
