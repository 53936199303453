import axios from "axios";
import amplitude from "amplitude-js";
import moment from "moment";

export const saveAction = ({
    action_type = "open",
    app_label,
    model,
    object_id,
    data = {},
    description = "",
    weight = undefined,
}) => {
    const params = {
        action_type,
        frequency: 0,
        content_type: 1,
        app_label,
        model,
        object_id,
        description,
        data,
        weight,
    };
    try {
        return axios.post("/events/actions", params);
    } catch (error) {
        return Promise.reject({ error: true });
    }
};

const saveBookmark = ({ app_label, model, object_id, data, description, action_type = "bookmark" }) => {
    const params = {
        action_type,
        frequency: 2, // daily
        content_type: 1,
        app_label,
        model,
        object_id,
        description,
        data,
    };
    try {
        return axios.post("/events/actions", params);
    } catch (error) {
        return Promise.reject({ error: true });
    }
};
const deleteBookmark = (id) => axios.delete(`/events/actions/${id}`);
const getBookmarks = (model) => {
    const params = {
        action_type: "bookmark",
        page_size: 500,
    };
    if (model) {
        params.content_type__model = model;
    }
    return axios.get("events/actions", { params });
};
const updateBookmark = ({ id, frequency, description }) =>
    axios.put(`events/actions/${id}`, {
        frequency,
        description,
        action_type: "bookmark",
    });

const getPreferences = async (params = {}) => {
    const response = await axios.get("/events/actions", {
        params: {
            ...params,
            action_type: "preferences",
        },
    });
    return response;
};
const savePreferences = ({ object_id, app_label, model, data }) => {
    amplitude.getInstance().logEvent(`preferences ${model}`, {
        type: "station group",
        date: moment().toISOString(),
        object_id,
        app_label,
        model,
    });
    return axios.post("/events/actions", {
        object_id,
        app_label,
        model,
        action_type: "preferences",
        data,
        frequency: 0,
        content_type: 1,
    });
};

const getSubscriptions = () => axios.get("events/actions?action_type=subscription");
const updateSubscription = (id, type) =>
    axios.put(`events/actions/${id}`, {
        data: { frequency: type || "disabled" },
    });

export default {
    deleteBookmark,
    getBookmarks,
    getPreferences,
    getSubscriptions,
    saveAction,
    saveBookmark,
    savePreferences,
    updateBookmark,
    updateSubscription,
};
