import React from "react";

import NarrowPage from "pages/templates/NarrowPage";
import RegisterForm from "./RegisterForm";
import RegisterMessage from "./RegisterMessage";

import "./User.less";
import { Helmet } from "react-helmet";

export default function Register({ success, ...args }) {
    return (
        <NarrowPage lg={10} xxl={9}>
            <Helmet>
                <title>Arbo - Sign up</title>
                <meta name="description" content="Sign up for Arbo's Liquids Commerce Platform" />
            </Helmet>

            {success ? <RegisterMessage {...args} /> : <RegisterForm {...args} />}
        </NarrowPage>
    );
}
