import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiLiquids from "api/api.liquids";
import ApiTracker from "api/api.tracker";
import Api from "api/";
import moment from "moment-timezone";

export const getCorridor = createAsyncThunk("tracker/getCorridor", async (id) => {
    const response = await ApiLiquids.getCorridor(id);
    return response.data;
});

export const expandGetRemaining = createAsyncThunk(
    "tracker/expandGetRemaining",
    async () => await ApiTracker.expandGetRemaining(),
);

export const expandIncreaseCount = createAsyncThunk(
    "tracker/expandIncreaseCount",
    async () => await ApiTracker.expandIncreaseCount(),
);

export const getIsFreeUser = createAsyncThunk(
    "tracker/getIsFreeUser",
    async () => await !Api.userHasGroup("ARB-TRACKER"),
);

export const getCorridors = createAsyncThunk(
    "tracker/getCorridors",
    async (params = { ordering: "-created", page_size: 100 }) => {
        const response = await ApiLiquids.getCorridors(params);
        return response.data;
    },
);

export const getContractPrices = createAsyncThunk("tracker/getContractPrices", async ({ year, month }) => {
    const response = await ApiTracker.getContractPrices(year, month);
    const prices = {};
    if (response?.results) {
        response.results.forEach((d) => {
            if (!prices[d.contract] || moment(d.date) > moment(prices[d.contract]?.date)) {
                prices[d.contract] = d;
            }
        });
    }
    return prices;
});

export const RATE_TYPES = {
    MINIMUM: "minCommitted",
    UNCOMMITTED: "Uncommitted",
};

export const HIDDEN_CORRIDORS = [86, 88, 90, 94, 95, 98, 100];

export const trackerSlice = createSlice({
    name: "tracker",
    initialState: {
        unit: ApiTracker.DEFAULT_UNIT,
        corridors: null,
        corridorDetails: null,
        selectedCorridor: null,
        settings: {},
        prices: {},
        rateType: RATE_TYPES.MINIMUM,
        expandRemaining: null,
        expandIsAllowed: true,
    },
    extraReducers: {
        [getContractPrices.pending]: (state, { payload }) => {
            state.prices = {
                loading: true,
            };
        },
        [getContractPrices.fulfilled]: (state, { payload }) => {
            state.prices = payload;
        },
        [getCorridor.pending]: (state, { payload }) => {
            state.corridorDetails = {
                loading: true,
            };
        },
        [getCorridor.fulfilled]: (state, { payload }) => {
            state.corridorDetails = {
                ...payload,
            };
        },
        [getCorridors.pending]: (state, { payload }) => {
            state.corridors = {
                ...state.corridors,
                loading: true,
            };
        },
        [getCorridors.fulfilled]: (state, { payload }) => {
            const corridors = {
                ...payload,
                loading: false,
            };
            corridors.indexed = payload?.results.reduce((acum, obj) => {
                acum[obj.id] = obj;
                return acum;
            }, {});
            state.corridors = corridors;
        },
        [expandGetRemaining.fulfilled]: (state, { payload }) => {
            state.expandRemaining = +payload > 0 ? +payload : 0;
            state.expandIsAllowed = state.expandRemaining > 0;
        },
        [expandIncreaseCount.fulfilled]: (state, { payload }) => {
            state.expandRemaining = payload;
            state.expandIsAllowed = state.expandRemaining > 0;
        },
        [getIsFreeUser.fulfilled]: (state, { payload }) => {
            state.isFreeUser = payload;
        },
    },
    reducers: {
        updateRateType: (state, { payload }) => {
            state.rateType = payload;
        },
        setSelectedCorridor: (state, { payload }) => {
            state.selectedCorridor = payload;
        },
        setSettings: (state, { payload }) => {
            state.settings = payload;
        },
    },
});

export const { setSelectedCorridor, setSettings, updateRateType } = trackerSlice.actions;

export default trackerSlice.reducer;
