import React from "react";
import { Drawer } from "antd";
import { List } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { toggleHowToDrawer } from "../../reducers/layoutSlice";
const data = [
    {
        title: "Report a problem",
    },
    {
        title: "Find route",
    },
    {
        title: "Find tariffs",
    },
    {
        title: "View arbs*",
    },
    {
        title: "Find see the route of a specific pipeline",
    },
    {
        title: "Find a station",
    },
    {
        title: "See station connectivity",
    },
];
export default function HowToDrawer() {
    const openHowToDrawer = useSelector(({ layout }) => layout.openHowToDrawer);
    const dispatch = useDispatch();
    return (
        <Drawer
            title="How To"
            placement="right"
            closable={true}
            width={400}
            onClose={() => {
                dispatch(toggleHowToDrawer());
            }}
            visible={openHowToDrawer}
            key="right"
        >
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<QuestionCircleOutlined style={{ fontSize: 38 }} />}
                            title={<a href="https://www.goarbo.com/">{item.title}</a>}
                            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam id est a tortor auctor egestas quis nec purus. "
                        />
                    </List.Item>
                )}
            />
        </Drawer>
    );
}
