import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiBookmarks from "api/api.bookmarks";
import { buildSearchUrlFromSavedProperties } from "Utils";
import moment from "moment";

// Async actions
export const getBookmarks = createAsyncThunk("bookmarks/getBookmarks", async (params) => {
    const response = await ApiBookmarks.getBookmarks(params);
    return response.data;
});

// Async actions
export const getSavedPaths = createAsyncThunk("bookmarks/getSavedPaths", async (params = {}) => {
    const response = await ApiBookmarks.getSavedPaths(params);
    return response.data;
});

export const getSavedArbs = createAsyncThunk("bookmarks/getSavedArbs", async (params = {}) => {
    const response = await ApiBookmarks.getSavedArbs(params);
    return response.data;
});

export const getSelectedPath = createAsyncThunk("bookmarks/getSelectedPath", async (id) => {
    const response = await ApiBookmarks.getSavedPath(id);
    return response.data;
});

export const loadCompareRoutes = createAsyncThunk("bookmarks/loadCompareRoutes", async (ids) => {
    const responses = await Promise.all(ids.map((id) => ApiBookmarks.getSavedPath(id)));
    return responses.map((obj) => obj.data);
});

export const getSavedPricingHubs = createAsyncThunk("bookmarks/getSavedPricingHubs", async (params = {}) => {
    const response = await ApiBookmarks.getSavedPricingHubs(params);
    return response.data;
});

export const getSavedPipelines = createAsyncThunk("bookmarks/getSavedPipelines", async (params = {}) => {
    const response = await ApiBookmarks.getSavedPipelines(params);
    return response.data;
});

export const getSavedStations = createAsyncThunk("bookmarks/getSavedStations", async (params = {}) => {
    const response = await ApiBookmarks.getSavedStations(params);
    return response.data;
});

export const getSavedSearches = createAsyncThunk("bookmarks/getSavedSearches", async (params = {}) => {
    params = { ...params, created_by__is_staff: true };
    const response = await ApiBookmarks.getSavedSearches(params);
    return response.data;
});

export const getSearchHistory = createAsyncThunk("bookmarks/getSearchHistory", async () => {
    const response = await ApiBookmarks.getDocumentSearchHistory();
    return response.data;
});

export const getSavedForm6s = createAsyncThunk("bookmarks/getForm6Documents", async (params = {}) => {
    const response = await ApiBookmarks.getForm6Documents(params);
    return response.data;
});
export const bookmarksSlice = createSlice({
    name: "bookmarks",
    initialState: {
        bookmarks: null,
        uiTariffsBookmarks: null,
        uiPipelineBookmarks: null,
        savedArbs: null,
        savedPaths: null,
        savedPricingHubs: null,
        savedPipelines: [],
        savedStations: null,
        savedStationGroups: null,
        savedSearches: null,
        searchHistory: null,
        savedTariffs: null,
        savedForm6s: null,
        selectedRoute: null,
        loadingSelectedPath: false,
        openSelectionDrawer: false,
        selectedToCompare: [],
        compareRouteList: [],
    },
    reducers: {
        clearSelectedRoute: (state) => {
            state.selectedRoute = null;
        },
        updateOpenSelectionDrawer: (state, { payload }) => {
            state.openSelectionDrawer = !!payload;
            if (!state.openSelectionDrawer) {
                state.selectedToCompare = [];
            }
        },
        toggleSelectedToCompare: (state, { payload }) => {
            const index = state.selectedToCompare.findIndex((item) => item.id === payload.id);
            if (index > -1) {
                const toUpdate = state.selectedToCompare.slice();
                toUpdate.splice(index, 1);
                state.selectedToCompare = toUpdate;
            } else if (state.selectedToCompare.length < 4) {
                state.selectedToCompare = [...state.selectedToCompare, payload];
            }
        },
        updateCompareRoutesList: (state, { payload }) => {
            state.compareRouteList = payload;
        },
        updateTariffBookmarks: (state, { payload }) => {
            const index = state.uiTariffsBookmarks.findIndex((bookmark) => bookmark.id === payload.id);
            state.uiTariffsBookmarks[index] = { ...state.uiTariffsBookmarks[index], frequency: payload.frequency };
        },
        updatePipelineBookmarks: (state, { payload }) => {
            const index = state.uiPipelineBookmarks.findIndex((bookmark) => bookmark.id === payload.id);
            state.uiPipelineBookmarks[index] = { ...state.uiPipelineBookmarks[index], frequency: payload.frequency };
        },
    },
    extraReducers: {
        [getSavedArbs.pending]: (state) => {
            state.savedArbs = {
                ...state.savedArbs,
                loading: true,
            };
        },
        [getSavedArbs.fulfilled]: (state, { payload }) => {
            state.savedArbs = {
                loading: false,
                count: payload?.count,
                results: payload.results,
                indexed: payload?.results.reduce((acum, d) => {
                    acum[d.saved.id] = d;
                    return acum;
                }, {}),
            };
        },
        [getSavedPaths.pending]: (state) => {
            state.savedPaths = {
                ...state.savedPaths,
                loading: true,
            };
        },
        [getSavedPaths.fulfilled]: (state, action) => {
            state.savedPaths = {
                loading: false,
                count: action?.payload?.count,
                results: action.payload.results,
            };
        },
        [getSelectedPath.pending]: (state) => {
            state.loadingSelectedPath = true;
        },
        [getSelectedPath.fulfilled]: (state, { payload }) => {
            state.selectedRoute = payload;
            state.loadingSelectedPath = false;
            // @ToDo: remove after route pipelines comes in the getSavedPaths response.
            state.savedPaths = {
                ...state.savedPaths,
                results: state.savedPaths?.results?.map((route) => {
                    if (route.id === payload.id) {
                        return { ...route, pipelines: payload.pipelines };
                    }
                    return route;
                }),
            };
        },
        [getSavedPricingHubs.pending]: (state, { payload }) => {
            state.savedPricingHubs = {
                ...state.savedPricingHubs,
                loading: true,
            };
        },
        [getSavedPricingHubs.fulfilled]: (state, { payload }) => {
            state.savedPricingHubs = {
                loading: false,
                count: payload.count,
                results: payload.results
                    .map((d) => d.saved)
                    .map((p) => ({
                        id: p.id,
                        title: p.properties?.name,
                        type: "PRICING HUB",
                        properties: p.properties,
                        url: `/map/pricing-hub/${p.id}`,
                    })),
            };
        },
        [getSavedPipelines.pending]: (state, { payload }) => {
            state.savedPipelines = {
                ...state.savedPipelines,
                loading: true,
            };
        },
        [getSavedPipelines.fulfilled]: (state, { payload }) => {
            state.savedPipelines = {
                loading: false,
                count: payload.count,
                results: payload.results
                    .map((d) => d.saved)
                    .map((pipe) => ({
                        id: pipe.id,
                        title: pipe.name,
                        description: pipe.notes,
                        type: "PIPELINE",
                        url: `/map/pipelines/${pipe.id}`,
                    })),
            };
        },
        [getSavedStations.pending]: (state, { payload }) => {
            state.savedStations = {
                ...state.savedStations,
                loading: true,
            };
        },
        [getSavedStations.fulfilled]: (state, { payload }) => {
            state.savedStations = {
                loading: false,
                count: payload.count,
                results: payload.results,
            };
        },
        [getSavedSearches.pending]: (state, { payload }) => {
            state.savedSearches = {
                ...state.savedSearches,
                loading: true,
            };
        },
        [getSearchHistory.pending]: (state) => {
            state.searchHistory = null;
        },
        [getSearchHistory.fulfilled]: (state, { payload }) => {
            const results = payload?.results || [];
            state.searchHistory = results.map((item) => ({
                ...item,
                url:
                    "/search?" +
                    (item.parameters?.text ? `text=${item.parameters?.text}` : "") +
                    (item.parameters?.pipeline ? `&pipeline=${item.parameters?.pipeline}` : ""),
                created: moment(item.created).format("YYYY-MM-DD"),
            }));
        },
        [getSavedSearches.fulfilled]: (state, { payload }) => {
            const parse = (search) => ({
                id: search.id,
                title: search.description,
                type: "SEARCH",
                suggested: search.public,
                url: search.public
                    ? buildSearchUrlFromSavedProperties(search.saved.parameters)
                    : `/map/search/${search.saved.id}`,
                properties: search.saved,
            });

            const suggested = payload.results.filter((d) => d.public).map(parse);
            const saved = payload.results.filter((d) => !d.public).map(parse);
            const results = saved.length ? saved : suggested;
            state.savedSearches = {
                loading: false,
                count: payload.count,
                results,
                saved,
                suggested,
            };
        },
        [getSavedForm6s.pending]: (state, { payload }) => {
            state.savedForm6s = {
                ...state.savedForm6s,
                loading: true,
            };
        },
        [getSavedForm6s.fulfilled]: (state, { payload }) => {
            //TODO: integrate with API
            state.savedForm6s = {
                loading: false,
                count: payload?.count || 0,
                indexed: payload?.results.reduce((acum, d) => {
                    acum[d.data.id] = d;
                    return acum;
                }, {}),
                results: payload?.results,
            };
        },
        [loadCompareRoutes.fulfilled]: (state, { payload }) => {
            state.compareRouteList = payload;
        },
        [getBookmarks.pending]: (state, { payload }) => {
            state.bookmarks = {
                ...state.bookmarks,
                loading: true,
            };
        },
        [getBookmarks.fulfilled]: (state, { payload }) => {
            const indexed = payload?.results?.length
                ? payload?.results.reduce((acum, obj) => {
                      if (!acum[obj.content_type.model]) {
                          acum[obj.content_type.model] = {};
                      }
                      acum[obj.content_type.model][obj.object_id] = obj;
                      return acum;
                  }, {})
                : {};

            const bookmarks = {
                loading: false,
                count: payload?.count || 0,
                results: payload?.results,
                indexed,
            };
            bookmarks.results.map((obj) => {
                if (!bookmarks[obj.content_type.model]) {
                    bookmarks[obj.content_type.model] = [];
                }
                bookmarks[obj.content_type.model].push(obj);
            });
            state.uiPipelineBookmarks = bookmarks.pipeline || [];
            state.uiTariffsBookmarks = bookmarks.tariff;
            state.bookmarks = bookmarks;
        },
    },
});

export const {
    clearSelectedRoute,
    updateOpenSelectionDrawer,
    toggleSelectedToCompare,
    updateCompareRoutesList,
    updateTariffBookmarks,
    updatePipelineBookmarks,
} = bookmarksSlice.actions;

export default bookmarksSlice.reducer;
