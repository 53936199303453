import { setModalBannerPipeline } from "../../reducers/searchSlice";
import { useDispatch, useSelector } from "react-redux";

function useVisitedModalPipelinesTracking() {
    const dispatch = useDispatch();
    const { uiPipelineBookmarks } = useSelector(({ bookmarks }) => bookmarks);

    return (id, name) => {
        const localStorageKey = `${process.env.REACT_APP_ENV}_VISITED_MODAL_PIPELINES`;
        const savedPipelines = localStorage.getItem(localStorageKey);
        const parsedPipelines = savedPipelines ? JSON.parse(savedPipelines) : {};

        if (uiPipelineBookmarks && !uiPipelineBookmarks.find((pipeline) => pipeline.object_id === id)) {
            //If not already in favorites
            if (parsedPipelines[id]) {
                parsedPipelines[id].total = parsedPipelines[id].total + 1;
                if (parsedPipelines[id].total > 1) {
                    dispatch(
                        setModalBannerPipeline({
                            id: id,
                            name: name,
                        }),
                    );
                    parsedPipelines[id].total = 0; // Reset counts after showing the banner once
                } else {
                    dispatch(setModalBannerPipeline(null));
                }
            } else {
                dispatch(setModalBannerPipeline(null));
                parsedPipelines[id] = {
                    name,
                    total: 1,
                };
            }

            localStorage.setItem(localStorageKey, JSON.stringify(parsedPipelines));
        } else {
            dispatch(setModalBannerPipeline(null));
        }
    };
}

export { useVisitedModalPipelinesTracking };
