import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Col, Row, Typography } from "antd";

import NarrowPage from "pages/templates/NarrowPage";

const { Title } = Typography;

const ErrorPage = ({ title, children }) => {
    return (
        <BrowserRouter>
            <NarrowPage>
                <Row className="error-page" style={{ paddingTop: 24, paddingBottom: 200 }}>
                    <Col span={24} style={{ paddingTop: 24 }}>
                        <Title style={{ textAlign: "center" }} level={2}>
                            {title}
                        </Title>
                    </Col>
                    <Col span={24}>{children}</Col>
                    <Col span={24} style={{ paddingTop: 24, paddingBottom: 12 }}>
                        <a href="/tracker"> Return to Arb Tracker</a>
                    </Col>
                    <Col span={24} style={{ paddingTop: 24, paddingBottom: 12 }}>
                        <a href="/"> Return to home page</a>
                    </Col>
                </Row>
            </NarrowPage>
        </BrowserRouter>
    );
};

export default ErrorPage;
