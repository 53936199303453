import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, message, Button, Col, Form, Input, Row, Modal } from "antd";

import { passwordReset } from "reducers/userSlice";
import "./User.less";

export default function PasswordReset({ showModal, onClose }) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const [form] = Form.useForm();

    const submitPasswordChange = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true);
                const { newPassword, verifyPassword } = values;
                dispatch(passwordReset([newPassword, verifyPassword]))
                    .then((resp) => {
                        if (resp.error) {
                            message.error(`There was an error when attempting to change password.`);
                            setErrorMessages(Object.values(resp.payload).shift());
                        } else {
                            setErrorMessages([]);
                            form.resetFields();
                            message.info(`Password changed successfully.`);
                            onClose();
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log("catch error", error);
                        message.error(`There was an error sending the request.`);
                        setLoading(false);
                    });
            })
            .catch((errorInfo) => {
                message.warning("Please complete all required fields.");
            });
    };

    return (
        <Modal
            visible={showModal}
            maskClosable={false}
            width={450}
            footer={null}
            closable={true}
            onCancel={onClose}
            bodyStyle={{ padding: 0 }}
            className="reset-form-modal"
            title="RESET PASSWORD"
        >
            <div style={{ padding: 30 }}>
                <Form
                    form={form}
                    name={`reset_form`}
                    className="reset-form"
                    layout="vertical"
                    onSubmit={submitPasswordChange}
                >
                    {errorMessages.length ? (
                        <Row>
                            {errorMessages.map((err, idx) => (
                                <Col key={idx} span={24} style={{ marginBottom: 24 }}>
                                    <Alert message={err} type="error" closable showIcon />
                                </Col>
                            ))}
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={24} style={{ marginBottom: 15 }}>
                            Choose a new password.
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className="password-reset__new-password"
                                name="newPassword"
                                rules={[{ required: true, message: "Please input your new password." }]}
                            >
                                <Input
                                    type="password"
                                    className="password-reset__new-password"
                                    placeholder="New Password*"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="password-reset__verify-password"
                                name="verifyPassword"
                                rules={[{ required: true, message: "Please repeat your new password." }]}
                            >
                                <Input
                                    type="password"
                                    className="password-reset__verify-password"
                                    placeholder="Confirm Password*"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <div className="flex items-center">
                                <div className="flex" style={{ flexGrow: 1 }}>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        loading={loading}
                                        className="password-reset__button"
                                        style={{
                                            width: "165px",
                                        }}
                                        onClick={submitPasswordChange}
                                    >
                                        Reset password
                                    </Button>
                                </div>
                                <div className="flex">
                                    <a
                                        className="cancel"
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onClose();
                                        }}
                                    >
                                        Cancel
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}
