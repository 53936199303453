import LinkedInTag from "react-linkedin-insight";
import amplitude from "amplitude-js";
import moment from "moment";
import TagManager from "react-gtm-module";

import { getUserProfile } from "api";

export const initLinkedInInsight = () => {
    const partnerId = process.env.REACT_APP_LINKEDIN_PARTNER_ID;
    if (partnerId) {
        LinkedInTag.init(partnerId, "dc", false);
    }
};

export const linkedInInsightTrack = () => {
    const partnerId = process.env.REACT_APP_LINKEDIN_PARTNER_ID;
    if (partnerId) {
        LinkedInTag.track("", partnerId);
    }
};

export const initAppcues = () => {
    const profile = getUserProfile();
    const { account, appcues_cohort, user } = profile;
    window.Appcues &&
        window.Appcues.identify(user.username, {
            ...user,
            account: account.name,
            appcues_cohort,
            subscription_type: profile?.subscription?.plan?.name,
        });
    amplitude.getInstance().setUserId(user.username);
    amplitude.getInstance().setUserProperties(profile);
};

export const initGtm = (id, data = {}) => {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID || "GTM-KVNWMBF",
    };

    TagManager.initialize(tagManagerArgs);
};

export const trackEvent = (id, data = {}) => {
    if (["development", "testing"].includes(process.env.NODE_ENV)) {
        console.log("trackEvent", id, data);
    }
    amplitude.getInstance().logEvent(id, {
        date: moment().toISOString(),
        ...data,
    });
};

export const trackRegisterPage = () => {
    if (window.gtag) {
        window.gtag("event", "conversion", { send_to: "AW-357649032/7FimCOuJ49sCEIiVxaoB" });
    }
};

export const trackConfirmationPage = () => {
    if (window.gtag) {
        window.gtag("event", "conversion", { send_to: "AW-357649032/11FtCM-m3tsCEIiVxaoB" });
    }
};

export const trackRegisterClick = () => {
    if (window.gtag) {
        window.gtag("event", "conversion", { send_to: "AW-357649032/11FtCM-m3tsCEIiVxaoB" });
    }
};

export default {
    initAppcues,
    initGtm,
    initLinkedInInsight,
    linkedInInsightTrack,
    trackEvent,
    trackRegisterPage,
    trackConfirmationPage,
    trackRegisterClick,
};
