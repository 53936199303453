import React from "react";
import { Col, Row } from "antd";
import { WarningOutlined as ErrorIcon } from "@ant-design/icons";

import ErrorPage from "pages/ErrorPage";

const NotFound = () => {
    return (
        <ErrorPage title="Page Not Found">
            <Row style={{ paddingBottom: 12 }}>
                <Col span={24} style={{ paddingBottom: 24, textAlign: "center" }}>
                    <ErrorIcon style={{ fontSize: 32 }} />
                </Col>
                <Col span={24} style={{ paddingLeft: 60, paddingRight: 60, textAlign: "center" }}>
                    The page you were looking for appears to have been moved, deleted or does not exist.
                </Col>
            </Row>
        </ErrorPage>
    );
};

export default NotFound;
