import React from "react";
import { StateProvider as MapProvider } from "store/mapStore";
import { StateProvider as AppProvider } from "store/appStore";
import Arbo from "./Arbo";
import { initErrorLogging } from "api/errorLogging";
import ErrorBoundary from "components/utils/ErrorBoundary";
import { initLinkedInInsight } from "api/analytics";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { QueryParamProvider } from "use-query-params";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function App() {
    initErrorLogging();
    initLinkedInInsight();
    return (
        <AppProvider>
            <MapProvider>
                <QueryClientProvider client={queryClient}>
                    <ErrorBoundary>
                        <BrowserRouter>
                            <QueryParamProvider ReactRouterRoute={Route}>
                                <Arbo />
                            </QueryParamProvider>
                        </BrowserRouter>
                    </ErrorBoundary>
                </QueryClientProvider>
            </MapProvider>
        </AppProvider>
    );
}

export default App;
