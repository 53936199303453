import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiModels from "api/models";

export const getPipelineAliases = createAsyncThunk("models/getPipelineAliases", async (params) => {
    const response = await ApiModels.getPipelineAliases(params);
    return response.data;
});

export const analyticsSlice = createSlice({
    name: "analytics",
    initialState: {
        pipelineAliases: {
            loading: false,
        },
    },
    extraReducers: {
        [getPipelineAliases.pending]: (state) => {
            state.pipelineAliases = {
                ...state.pipelineAliases,
                loading: true,
            };
        },
        [getPipelineAliases.fulfilled]: (state, { payload }) => {
            state.pipelineAliases = {
                results: [...payload.results],
                basin: payload.results.reduce((acum, obj) => {
                    acum[obj.basin] = { ...obj };
                    return acum;
                }, {}),
                pipeline: payload.results.reduce((acum, obj) => {
                    acum[obj.pipeline] = { ...obj };
                    return acum;
                }, {}),
            };
        },
    },
});

export const {} = analyticsSlice.actions;

export default analyticsSlice.reducer;
