import React, { useCallback, useEffect, useState } from "react";

import { Card } from "antd";
import { CheckOutlined, RightOutlined } from "@ant-design/icons";

import "./SelectableCard.less";

export default function SelectableCard({ label, children, selected = false, onClick }) {
    const [isSelected, setSelected] = useState(selected);
    const classes = ["selectable-card"];

    if (isSelected) {
        classes.push("selectable-card--selected");
    }

    useEffect(() => setSelected(selected), [selected]);

    const handleClick = useCallback(() => {
        setSelected(!isSelected);
    }, [isSelected]);

    return (
        <div className={classes.join(" ")} onClick={onClick || handleClick}>
            <Card>
                <>{children || label}</>
                {isSelected ? <CheckOutlined /> : <RightOutlined />}
            </Card>
        </div>
    );
}
