import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Alert, message, Button, Col, Empty, Form, Input, Row, Modal } from "antd";
import Title from "antd/es/typography/Title";

import ArboLogo from "assets/logo/Light.svg";
import { getUserProfile } from "api";
import { passwordReset } from "reducers/userSlice";
import "./User.less";

export default function PasswordReset({ showModal, onClose }) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const { groups, user } = getUserProfile() || {};
    const featureName = "Arbo";

    const [form] = Form.useForm();

    const submitPasswordChange = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true);
                const { newPassword, verifyPassword } = values;
                setErrorMessages([]);
                dispatch(passwordReset([newPassword, verifyPassword]))
                    .then((resp) => {
                        if (resp.error) {
                            message.error(`There was an error when attempting to change password.`);
                            setErrorMessages(Object.values(resp.payload).shift());
                        } else {
                            setErrorMessages([]);
                            form.resetFields();
                            message.info(`Password changed successfully.`);
                            onClose();
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log("catch error", error);
                        message.error(`There was an error sending the request.`);
                        setLoading(false);
                    });
            })
            .catch((errorInfo) => {
                message.warning("Please complete all required fields.");
            });
    };

    return (
        <Modal
            visible={showModal}
            maskClosable={false}
            width={584}
            footer={null}
            closable={false}
            onCancel={onClose}
            bodyStyle={{ padding: 0 }}
            className="reset-form-modal"
        >
            <Col span={24}>
                <Link to="/">
                    <Empty
                        className="narrow-page__header"
                        image={ArboLogo}
                        description={false}
                        imageStyle={{
                            height: 40,
                        }}
                    />
                </Link>
            </Col>
            <div style={{ padding: "52px 84px" }}>
                <Form
                    form={form}
                    name={`reset_form`}
                    className="reset-form"
                    layout="vertical"
                    onSubmit={submitPasswordChange}
                >
                    <Row>
                        <Col span={24} style={{ marginBottom: 15 }}>
                            <Title level={3}>Hello {user?.first_name}.</Title>
                            Welcome to Arbo. Create a password to access {featureName}.
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ paddingBottom: 24 }} span={24}>
                            Your email: {user?.email}
                        </Col>
                    </Row>
                    {errorMessages.length ? (
                        <Row>
                            <Col span={24}>
                                {errorMessages.map((err, idx) => (
                                    <Alert
                                        key={idx}
                                        message={err}
                                        type="warning"
                                        closable
                                        showIcon
                                        style={{ marginBottom: 15 }}
                                    />
                                ))}
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                className="password-reset__new-password"
                                name="newPassword"
                                rules={[{ required: true, message: "Please input your new password." }]}
                            >
                                <Input
                                    type="password"
                                    className="password-reset__new-password"
                                    placeholder="New Password*"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="password-reset__verify-password"
                                name="verifyPassword"
                                rules={[{ required: true, message: "Please repeat your new password." }]}
                            >
                                <Input
                                    type="password"
                                    className="password-reset__verify-password"
                                    placeholder="Confirm Password*"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <div className="flex items-center">
                                <div className="flex" style={{ flexGrow: 1 }}>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        loading={loading}
                                        className="password-reset__button"
                                        style={{
                                            width: 170,
                                        }}
                                        onClick={submitPasswordChange}
                                    >
                                        Let's go to {featureName}
                                    </Button>
                                </div>
                                <div className="flex">
                                    <a
                                        className="cancel"
                                        href="https://www.goarbo.com/contact"
                                        target="_blank"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        Contact Us
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}
