import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Tooltip } from "antd";

import { ReactComponent as Arrow } from "assets/Icon/Arrow/Left.svg";
import IconButton from "components/atoms/IconButton";

import "./BackButton.less";

export default function BackButton({ title = "Back", url, children, ...props }) {
    const history = useHistory();
    const RenderLink = ({ children }) => {
        return url ? (
            <Link {...props} to={url}>
                {children}
            </Link>
        ) : (
            <span onClick={() => history.goBack()}>{children}</span>
        );
    };
    return (
        <RenderLink>
            <IconButton>
                <Tooltip title={title}>
                    <Arrow title={title} />
                </Tooltip>
                {title || children}
            </IconButton>
        </RenderLink>
    );
}
