import React, { useState, useRef } from "react";
import "./Header.less";
import API from "api";
import clsx from "clsx";
import { Layout, Avatar, Menu, Dropdown, Button } from "antd";
import styles from "./Header.module.css";
import { trackEvent } from "api/analytics";
import { ReactComponent as ReferralCopied } from "assets/Icon/referralCopiedIcon.svg";
import { ReactComponent as UserBorder } from "assets/Icon/userBorder.svg";
import { InfoOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { copyToClipboard } from "Utils";
import { getReferralsUrl, getUserProfile } from "api";
import ReferModal from "./ReferModal";
import InAppHelpIcon from "components/support/InAppHelpIcon";
import { PLANS_COLORS } from "Utils";

const { Header } = Layout;

export default function LayoutHeader({ title, beta, isPublic }) {
    const menuTimeout = useRef();
    const [showReferModal, setShowReferModal] = useState(false);
    const [visibleMenu, setVisibleMenu] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);
    const history = useHistory();
    const { plan, user } = useSelector(({ layout }) => layout);
    const userProfile = getUserProfile();

    const menu = (
        <Menu
            className="header-menu pb-0"
            onMouseEnter={() => {
                if (menuTimeout.current) {
                    clearTimeout(menuTimeout.current);
                    menuTimeout.current = null;
                }
            }}
            onClick={() => {
                setVisibleMenu(true);
            }}
        >
            {user && userProfile ? (
                <Menu.Item className="user-section flex flex-col">
                    <label className="mb-2 block">
                        <b>
                            {user.first_name} {user.last_name}
                        </b>
                    </label>
                    <label className="mb-2 block">
                        <i>{userProfile.company_name}</i>
                    </label>
                    <label>{user.email}</label>
                </Menu.Item>
            ) : null}

            <Menu.Item
                key="account"
                className={styles.menuItem}
                onClick={() => {
                    history.push("/user/account/");
                    trackEvent("Account Mgmt - Click within Dropdown");
                }}
            >
                Favorites & Notifications
            </Menu.Item>
            <Menu.Item
                key="privacy"
                className={styles.menuItem}
                onClick={() => {
                    history.push("/privacy");
                    trackEvent("Privacy & Terms");
                }}
            >
                Privacy & Terms
            </Menu.Item>
            <Menu.Item
                key="1"
                className={styles.menuItem}
                onClick={({ domEvent }) => {
                    domEvent.stopPropagation();
                    domEvent.preventDefault();
                    copyToClipboard(getReferralsUrl());
                    setLinkCopied(true);
                    trackEvent("Account Mgmt - Copy Referral Link");
                }}
            >
                {linkCopied ? (
                    <>
                        Referral link copied{" "}
                        <ReferralCopied style={{ display: "inline-block", verticalAlign: "bottom" }} />
                    </>
                ) : (
                    "Copy Referral Link"
                )}
                <span> ({userProfile?.referrals_count || 0} sent)</span>
            </Menu.Item>
            <Menu.Item
                key="3"
                className={styles.menuItem}
                onClick={() => {
                    trackEvent("Account Mgmt - User Logout");
                    API.logout();
                }}
            >
                Log out
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className={clsx(styles.header)}>
            <ReferModal
                visible={showReferModal}
                onCancel={() => {
                    setShowReferModal(false);
                }}
            />
            <div className="flex flex-col md:flex-row">
                <div className="flex items-center flex-grow" style={{ flexGrow: 1 }}>
                    <h1 className={styles.title}>{title}</h1>
                    {beta ? (
                        <span className="beta-tag">
                            BETA
                            <span className="icon">
                                <InfoOutlined />
                            </span>
                        </span>
                    ) : null}
                </div>
                <div className="flex items-center flex-row">
                    <Button
                        type="link"
                        className={styles.referFriend}
                        onClick={() => {
                            setShowReferModal(true);
                            trackEvent("Account Mgmt - Open Refer Friends Modal");
                        }}
                    >
                        REFER FRIENDS
                    </Button>
                    <InAppHelpIcon />
                    {user || isPublic ? (
                        <>
                            {user ? (
                                <div
                                    className={clsx(styles.userName, "hidden md:inline-block")}
                                    onMouseEnter={() => {
                                        if (menuTimeout.current) {
                                            clearTimeout(menuTimeout.current);
                                            menuTimeout.current = null;
                                        }
                                        setVisibleMenu(true);
                                    }}
                                    onMouseLeave={() => {
                                        menuTimeout.current = setTimeout(() => {
                                            setVisibleMenu(false);
                                        }, 500);
                                    }}
                                >
                                    <div className="flex flex-col">
                                        <label style={{ cursor: "pointer" }}>
                                            {user.first_name} {user.last_name}
                                        </label>
                                        {plan ? (
                                            <span
                                                className="user-plan"
                                                style={{ color: PLANS_COLORS[plan.name.toUpperCase()] }}
                                            >
                                                {plan.description}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}

                            <Dropdown
                                overlay={menu}
                                onOpenChange={(visible) => {
                                    setVisibleMenu(visible);
                                    setTimeout(() => {
                                        if (!visible) setLinkCopied(false);
                                    }, 1000);
                                }}
                                open={visibleMenu}
                            >
                                {isPublic ? (
                                    <div className={styles.userWrapper}>
                                        <label className={clsx(styles.userName, "hidden md:inline-block", "mb-2")}>
                                            Jones Ferdinand
                                        </label>
                                        <Avatar className={styles.avatar} size={34}>
                                            JF
                                        </Avatar>
                                        <UserBorder
                                            style={{
                                                position: "absolute",
                                                right: -3,
                                                top: -3,
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            history.push("/user/account");
                                            trackEvent("Account Settings");
                                        }}
                                    >
                                        <div className={styles.userWrapper}>
                                            <Avatar className={styles.avatar} size={34}>
                                                {user.first_name.charAt(0)}
                                                {user.last_name.charAt(0)}
                                            </Avatar>
                                            <UserBorder
                                                style={{
                                                    position: "absolute",
                                                    right: -3,
                                                    top: -3,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Dropdown>
                        </>
                    ) : null}
                </div>
            </div>
        </Header>
    );
}
