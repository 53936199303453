import _ from "lodash";
import Api from "api";
import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { message, Alert, Col, Row, Typography } from "antd";

import { trackEvent, initAppcues } from "api/analytics";
import { Helmet } from "react-helmet";
import LoginForm from "./LoginForm";
import NarrowPage from "pages/templates/NarrowPage";
import moment from "moment";

const { Title } = Typography;

export default function Login() {
    const history = useHistory();
    const { action } = useParams();

    const [alertMessages, setAlertMessages] = useState([]);
    const [source, setSource] = useState();

    const userWelcome = (response) => {
        const name = response?.profile?.user?.first_name;
        message.success(`Welcome ${name}!`);
    };

    useEffect(() => {
        if (history.location.state?.from) {
            setSource({ ...history.location.state?.from });
        }
    }, [history]);

    const title = useMemo(() => {
        let text = "Arbo - Login";
        switch (action) {
            case "reset":
            case "password":
                text = "Arbo - Login Reset";
                break;
            case "magic":
                break;
            case "verify-account":
                break;
            default:
                text = "Arbo - Login";
        }

        return text;
    }, [action]);

    const renderLoginForm = (resetPassword) => (
        <LoginForm
            resetPassword={resetPassword}
            onLoginSuccess={(userProfile) => {
                if (source) {
                    history.push(source.pathname + source.search);
                } else {
                    history.push("/map/search");
                }
            }}
        />
    );

    const handleApiErrors = (err) => {
        const errors = [];
        if (err && _.isString(err)) {
            errors.push("The connection to the server cannot be established. Please try again.");
        } else {
            Object.values(err).forEach((error) => {
                errors.push(error);
            });
        }
        setAlertMessages(errors);
        message.error(errors);
    };

    const verifyAccountForm = () => {
        return (
            <Row className="login-form">
                <Col span={24}>
                    <Title level={2}>Verifying invitation</Title>
                </Col>
                <Col span={24} style={{ paddingBottom: 12, paddingTop: 12 }}>
                    Please wait while we verify the invitation code.
                </Col>
                <Col span={24} style={{ paddingBottom: 24, paddingTop: 12 }}>
                    <Link className="login-form__link--small" to="/login">
                        Sign in.
                    </Link>
                </Col>
            </Row>
        );
    };

    const magicForm = () => {
        return (
            <Row className="login-form__body">
                <Col span={24}>
                    <Title level={2}>Validating login key</Title>
                </Col>
                <Col span={24} style={{ paddingBottom: 12, paddingTop: 12 }}>
                    {!alertMessages?.length && "Please wait while we validate the login key."}
                </Col>
                <Col span={24} style={{ paddingBottom: 24, paddingTop: 12 }}>
                    <Link className="login-form__link--small" to="/login">
                        Back to Log in.
                    </Link>
                </Col>
            </Row>
        );
    };

    useEffect(() => {
        if (action === "magic") {
            const searchParams = new URL(document.location).searchParams;
            const key = searchParams?.get("key")?.split("?").shift();

            if (!key) {
                setAlertMessages(["The key is missing from the Login Link URL.", "Please request a new Login Link."]);
                return;
            }

            const email = searchParams?.get("email");
            if (key && email) {
                Api.emailVerify({ email: email.toLowerCase(), key })
                    .then((response) => {
                        const loginDate = moment().toISOString();
                        const profile = response.profile;

                        let patchObj = { last_login: loginDate };
                        if (!profile?.attributes?.first_login) {
                            patchObj.first_login = loginDate;
                            trackEvent("Account Mgmt - First User Login");
                        }
                        Api.patchUserAttributes(patchObj);
                        trackEvent("Account Mgmt - Log in", { method: "magic link" });
                        initAppcues();

                        userWelcome(response);
                        if (searchParams.get("password_reset")) {
                            history.push("/map/search?password_reset_modal=true");
                        } else if (searchParams.get("checkout_plan")) {
                            history.push(
                                "/plans/?checkout_plan=" +
                                    searchParams.get("checkout_plan") +
                                    "&checkout_prices=" +
                                    searchParams.get("checkout_prices"),
                            );
                        } else if (searchParams.get("target")) {
                            history.push(searchParams.get("target"));
                        } else if (searchParams.get("create_password")) {
                            history.push("/user/account/profile?create_password_modal=true");
                        } else {
                            history.push("/map/search");
                        }
                    })
                    .catch((resp) => {
                        handleApiErrors(resp);
                    });
            }
        } else if (action === "verify-account") {
            const searchParams = new URL(document.location).searchParams;
            const key = searchParams?.get("key");
            const email = searchParams?.get("email");
            const account = searchParams?.get("account");

            Api.verifyInviteLink({ account, key, email })
                .then((resp) => {
                    Api.patchUserAttributes({ last_login: moment().toISOString() });
                    trackEvent("Account Mgm - Verify Team Invite", { method: "account invite link" });
                    message.success(resp.message);
                    if (searchParams.get("create_password")) {
                        history.push("/user/account/profile?create_password_modal=true");
                    } else {
                        history.push("/map/search");
                    }
                })
                .catch((resp) => {
                    handleApiErrors(resp);
                });
        }
    }, [action, history]);

    return (
        <NarrowPage>
            <Helmet>
                <title>{title}</title>
                {action !== "reset" && action !== "password" ? (
                    <meta name="description" content="Login to Arbo's Liquids Commerce Platform" />
                ) : null}
            </Helmet>

            <Row>
                {!!alertMessages.length && (
                    <Col span={24}>
                        {alertMessages.map((msg, idx) => {
                            const type = "error";
                            return (
                                <div key={idx} style={{ marginBottom: 24 }}>
                                    <Alert message={msg} type={type} closable showIcon />
                                </div>
                            );
                        })}
                    </Col>
                )}
            </Row>
            {action === "password" || action === "reset"
                ? renderLoginForm(true)
                : action === "verify-account"
                ? verifyAccountForm()
                : action === "magic"
                ? magicForm()
                : renderLoginForm()}
        </NarrowPage>
    );
}
