import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Row } from "antd";
import Text from "antd/es/typography/Text";
import Paragraph from "antd/es/typography/Paragraph";
import { ReactComponent as Arrow } from "assets/Icon/Arrow/Right.svg";
import SubwayTiles from "components/routes/SubwayTiles";
import BookmarkStar from "components/bookmarks/BookmarkStar";

export default function SavedItemCard({ item, onClick, selected }) {
    const history = useHistory();

    return (
        <Row className={"saved-item-card pt-6" + (selected ? " selected" : "")} onClick={onClick}>
            <Col span={22}>
                {item.type ? <Text className="saved-item-card__type">{item.type}</Text> : ""}
                {item.suggested && <Text className="saved-item-card__type suggested">• SUGGESTED BY ARBO</Text>}

                <Paragraph
                    className="saved-item-card__title"
                    ellipsis={{
                        rows: 4,
                        expandable: false,
                        symbol: "more",
                    }}
                >
                    {item.title}
                </Paragraph>
                <div className="saved-item-card__label">{item.description}</div>
            </Col>
            <Col span={2} className="flex flex-col items-end">
                <BookmarkStar
                    object_id={item.id}
                    app_label={item.app_label}
                    model={item.model}
                    data={item.raw}
                    tooltip={item.type ? `Bookmark this ${item.type?.toLowerCase()}` : null}
                />
            </Col>
            <Col span={24} className="saved-item-card__footer">
                <Row style={{ verticalAlign: "bottom" }}>
                    <Col span={9}>
                        <Text className="saved-item-card__value">{item.value}</Text>
                        <Text className="saved-item-card__label">{item.label}</Text>
                    </Col>
                    <Col span={6} style={{ textAlign: "center", alignSelf: "center" }}>
                        <SubwayTiles path={item} />
                    </Col>
                    <Col span={9} style={{ textAlign: "right", alignSelf: "flex-end" }}>
                        {item.url && (
                            <Link to={item.url} style={{ textAlign: "right" }}>
                                <Button
                                    className="arrow-btn saved-item-card__action"
                                    type="link"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        history.push(item.url);
                                    }}
                                >
                                    {item.action || "VIEW ON MAP"} <Arrow />
                                </Button>
                            </Link>
                        )}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
