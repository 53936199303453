import React, { useCallback, useState } from "react";

import { message, Button, Col, Modal, Row } from "antd";
import { CopyOutlined, DownloadOutlined, FilePdfOutlined, MailOutlined } from "@ant-design/icons";

import { copyToClipboard, exportCSVFile } from "Utils";
import ShareModal from "components/molecules/ShareModal";

const ExportModal = ({ visible, onClose, data, headers, url, share = true, entityName }) => {
    const [shareModalVisible, setShareModalVisible] = useState(false);

    const handleCopy = (e) => {
        if (url) {
            const shareUrl = document.location.origin + url;
            copyToClipboard(shareUrl);
            message.info(`URL copied to clipboard.`);
        } else {
            message.error(`URL is not set properly.`);
        }
    };

    const handlePdf = useCallback((e) => {
        message.warn(`This feature is not yet implemented.`);
    }, []);

    const handleCsv = () => {
        const fileTitle = "export";
        exportCSVFile(data, fileTitle);
        message.info(`CSV download started.`);
    };

    const handleSend = useCallback((e) => {
        setShareModalVisible(true);
    }, []);

    return (
        <Modal
            visible={visible}
            width={480}
            className="arbo-modal"
            footer={null}
            destroyOnClose={true}
            onCancel={onClose}
            title={`Export ${entityName || ""}`}
        >
            <Row>
                <Col span={24} style={{ paddingLeft: 24 }}>
                    <Row>
                        {data?.length ? (
                            <Col span={24}>
                                <Button className="ant-icon-link" onClick={handleCsv} style={{ borderRadius: 24 }}>
                                    <DownloadOutlined />
                                    Download CSV
                                </Button>
                            </Col>
                        ) : (
                            ""
                        )}
                        <Col span={24}>
                            <Button className="ant-icon-link" onClick={handlePdf} style={{ borderRadius: 24 }}>
                                <FilePdfOutlined />
                                Download PDF
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Button className="ant-icon-link" onClick={handleCopy} style={{ borderRadius: 24 }}>
                                <CopyOutlined />
                                Copy to clipboard
                            </Button>
                        </Col>
                        {url && share ? (
                            <Col span={24}>
                                <Button className="ant-icon-link" onClick={handleSend} style={{ borderRadius: 24 }}>
                                    <MailOutlined />
                                    Send to someone
                                </Button>
                            </Col>
                        ) : (
                            ""
                        )}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col
                    span={24}
                    style={{
                        marginTop: 15,
                        textAlign: "right",
                    }}
                >
                    <Button type="link" onClick={onClose} className="cancel-btn">
                        Close
                    </Button>
                </Col>
            </Row>
            <ShareModal visible={shareModalVisible} onClose={() => setShareModalVisible(false)} />
        </Modal>
    );
};

export default ExportModal;
