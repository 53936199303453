import React from "react";
import { Empty, Table } from "antd";

import "./AdminTable.less";

export default function AdminTable({
    children,
    pagination,
    loading,
    columns = [],
    dataSource = [],
    title,
    rowKey = "key",
    onRow,
    width,
    scroll,
    sticky,
    ...props
}) {
    const classes = ["admin-table"];
    if (!title) {
        classes.push("admin-table__no-header");
    }
    return (
        <div className={classes.join(" ")}>
            <Table
                className="no-wrap"
                title={title}
                showHeader={true}
                pagination={pagination}
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                rowKey={rowKey}
                onRow={onRow}
                width={width}
                scroll={scroll}
                sticky={sticky}
                locale={{
                    emptyText: loading || dataSource?.length ? <></> : <Empty />,
                }}
                sortDirections={["ascend", "descend", "ascend"]}
                {...props}
            />
            {children}
        </div>
    );
}
