import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SavedItemCard from "pages/savedItems/tabs/routes/SavedItemCard";
import "./styles.scss";
import { Skeleton } from "antd";
import { getBookmarks } from "reducers/bookmarksSlice";
import { getRouteSuggestions } from "reducers/searchSlice";
const Suggestions = ({ saved }) => {
    const dispatch = useDispatch();
    const { bookmarks } = useSelector(({ bookmarks }) => bookmarks);
    const { routeSuggestions, selectedPath } = useSelector(({ search }) => search);

    useEffect(() => {
        if (!bookmarks && !bookmarks?.loading) {
            dispatch(getBookmarks());
        }
    }, [bookmarks]);

    const savedSearches = useMemo(() => {
        let searches = [];
        if (bookmarks?.asyncresponsedata) {
            bookmarks.asyncresponsedata.forEach((bookmark) => {
                if (Array.isArray(bookmark.data)) {
                    bookmark.data.forEach((item) => {
                        searches.push({
                            id: bookmark.object_id,
                            parameters: item.parameters,
                            title: item.description || bookmark.description,
                            description: bookmark.description,
                            url: `/map/search/${bookmark.object_id}`,
                            bookmark,
                            type: "SEARCH",
                            model: "asyncresponsedata",
                            app_label: "liquids",
                        });
                    });
                } else if (bookmark.data) {
                    searches.push({
                        id: bookmark.object_id,
                        parameters: bookmark.data.parameters,
                        title: bookmark.data.description || bookmark.description,
                        description: bookmark.description,
                        url: `/map/search/${bookmark.object_id}`,
                        bookmark,
                        type: "SEARCH",
                        model: "asyncresponsedata",
                        app_label: "liquids",
                    });
                }
            });
        }

        return searches;
    }, [bookmarks]);

    useEffect(() => {
        if (!routeSuggestions && !selectedPath && !savedSearches?.length) {
            dispatch(getRouteSuggestions());
        }
    }, [routeSuggestions, savedSearches, selectedPath]);

    const handleCardClick = (e, open) => {
        console.log("handleCardClick", e, open);
    };

    const mapRoutes = (path, idx) => ({
        id: path.id,
        title: `${path?.origin_station?.properties.name} to ${path?.destination_station?.properties?.name}`,
        app_label: "liquids",
        model: "path",
        type: "ROUTE",
        label: (
            <>
                Min Rate <br /> <small>US Cents/Barrel</small>
            </>
        ),
        suggested: true,
        raw: path,
        value: (+path?.total_rate || +path?.min_discounts_sum).toFixed(2),
        url: `/map/path/${path?.id}`,
    });
    const renderSavedSearches = (items) => {
        return items?.length
            ? (items || []).map((item, idx) => (
                  <SavedItemCard key={idx} item={item} onClick={() => handleCardClick(item)} />
              ))
            : [];
    };

    return (
        <div className="suggestions py-6 px-8">
            <div className="">
                {savedSearches?.length ? (
                    <div>
                        <div className="font-semibold p-4">Your saved Searches</div>
                        {renderSavedSearches(savedSearches)}
                    </div>
                ) : (
                    <div>
                        <div className="mb-6">
                            Don't know where to start? Try one of these suggested routes or searches by simply clicking
                            on the card.
                        </div>
                        {renderSavedSearches(routeSuggestions?.results?.map(mapRoutes))}
                        {renderSavedSearches(savedSearches?.suggested)}
                    </div>
                )}
                {routeSuggestions?.loading || bookmarks?.loading ? <Skeleton className="mb-6" active /> : ""}
            </div>
        </div>
    );
};

export default Suggestions;
