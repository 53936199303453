import React from "react";
import { Col, Empty, Row } from "antd";
import ArboLogo from "assets/logo/Light.svg";

import "./NarrowPage.less";

export default function NarrowPage({ children, lg = 9, xxl = 8 }) {
    return (
        <Row flex="flex" justify="center" align="middle" style={{ minHeight: "90vh" }} className="narrow-page">
            <Col xs={24} sm={12} lg={lg} xxl={xxl}>
                <a href="https://www.goarbo.com/arbo-liquids">
                    <Empty
                        className="narrow-page__header"
                        image={ArboLogo}
                        description={false}
                        imageStyle={{
                            height: 40,
                        }}
                    />
                </a>
                <div className="narrow-page__body">{children}</div>
            </Col>
        </Row>
    );
}
