import calls from "api/api.layers";

export async function getLayers() {
    try {
        const layers = await calls.getLayers();

        return layers.results.map(({ id, record_types, category, name, zipped_shape_file }) => ({
            id,
            category,
            name: zipped_shape_file?.name || name,
            type: record_types || [],
        }));
    } catch (err) {
        console.warn(err);
        return [];
    }
}

export function getLayerType(type) {
    switch (true) {
        case type === "LineRecord":
        case type === "MultiLineRecord":
        case type === "PolygonRecord":
        case type === "MultiPolygonRecord":
            return "line";
        case type === "PointRecord":
        case type === "MultiPointRecord":
            return "circle";
        default:
            return "circle";
    }
}

export function getPaintAndLayoutByType(type, { lines, points, polygons }) {
    switch (true) {
        case type === "LineRecord":
        case type === "MultiLineRecord":
            return lines;
        case type === "PointRecord":
        case type === "MultiPointRecord":
            return points;
        case type === "PolygonRecord":
        case type === "MultiPolygonRecord":
            return polygons;
        default:
            return {};
    }
}
