import axios from "axios";

const getContracts = async (id) =>
    axios.get(`/analytics/shipper-contract`, {
        params: {
            pipeline_alias: id,
            most_recent: true,
        },
    });

const getFlows = async (id) =>
    axios.get(`/analytics/flow`, {
        params: {
            pipeline_alias: id,
            page_size: 500,
            ordering: "period",
            most_recent: true,
        },
    });

const getPipelineAliases = async () =>
    axios.get(`/analytics/pipeline-alias`, {
        params: {
            page_size: 100,
        },
    });

const getMetrics = async (id) => axios.get(`/analytics/metric`);

export default {
    getContracts,
    getFlows,
    getMetrics,
    getPipelineAliases,
};
