import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "api";

export const passwordReset = createAsyncThunk("user/passwordReset", async (params, thunkAPI) => {
    try {
        const response = await Api.passwordReset(params);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const userRegister = createAsyncThunk("user/userRegister", async (params, thunkAPI) => {
    try {
        const response = await Api.userRegister(params);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const getPreferences = createAsyncThunk("user/getPreferences", async (id, thunkAPI) => {
    try {
        const response = await Api.getLocalPreferences(id);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const savePreferences = createAsyncThunk("user/savePreferences", async ({ id, data }, thunkAPI) => {
    try {
        const response = await Api.saveLocalPreferences({ id, data });
        return response[id];
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const getInviteLinks = createAsyncThunk("user/getInviteLinks", async () => {
    const response = await Api.getInviteLinks();
    return response.data;
});

export const userSlice = createSlice({
    name: "user",
    initialState: {
        errors: null,
        inviteLinks: null,
        loading: null,
        profile: null,
        settings: null,
    },
    extraReducers: {
        [userRegister.pending]: (state, action) => {
            state.loading = true;
        },
        [userRegister.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action;
        },
        [userRegister.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [passwordReset.pending]: (state, action) => {
            state.loading = true;
        },
        [passwordReset.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action;
        },
        [passwordReset.fulfilled]: (state, action) => {
            state.loading = false;
        },
        [getInviteLinks.pending]: (state, action) => {
            state.inviteLinks = {
                loading: true,
            };
        },
        [getInviteLinks.fulfilled]: (state, { payload }) => {
            state.inviteLinks = payload;
        },
        [getPreferences.pending]: (state, action) => {
            state.loading = true;
        },
        [getPreferences.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action;
        },
        [getPreferences.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.settings = payload;
        },
        [savePreferences.pending]: (state, action) => {
            state.loading = true;
        },
        [savePreferences.rejected]: (state, action) => {
            state.loading = false;
            state.errors = action;
        },
        [savePreferences.fulfilled]: (state, { payload }) => {
            state.settings = payload;
            state.loading = false;
        },
    },
});

export default userSlice.reducer;
