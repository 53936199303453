import axios from "axios";
import moment from "moment";

const ARBO_STATES_KEY = "arbo_states";

const getTariff = async (id) => axios.get(`/liquids/tariff/${id}`);
const getTariffs = async (ids) => axios.get(`/liquids/tariff?id__in=${ids}`);
const getAllRelatedTariffs = async (id) => axios.get(`/liquids/related-tariff?tariff_id=${id}`);
const getRelatedTariffs = async (id) => {
    const promises = [
        axios.get(`/liquids/related-tariff?tariff_id=${id}`),
        axios.get(`/liquids/related-tariff?related_tariff_id=${id}`),
    ];
    const response = await Promise.all(promises).then((resp) => {
        return resp.map((d) => d.data.results).flat();
    });
    return response;
};

const getPath = async (id) => axios.get(`/liquids/path/${id}`);

const getDocumentPdf = async (id) => {
    const url = `/liquids/tariff/${id}/document`;
    const response = await axios.get(url, {
        responseType: "arraybuffer",
    });
    return new Uint8Array(response.data);
};

const getLatestTariffs = async ({ tariff_status_id, page_size, page }) => {
    const params = {
        issue_date__isnull: false,
        ordering: "-issue_date",
        page_size,
        page,
        tariff_status_id,
    };
    if (!tariff_status_id) {
        params.active = true;
    }
    return axios.get("/liquids/tariff", {
        params,
    });
};

const searchTariffs = async (args = {}) => {
    const {
        all = false,
        commodities,
        commodity_type,
        filer,
        heading_types,
        jurisdiction_id,
        market,
        page = 1,
        page_size,
        pipeline = [],
        rate_authority_types,
        sort,
        status,
        tariff_type,
        text,
        year,
    } = args;
    const sortMapping = {
        pipeline: "pipeline__name",
        "-pipeline": "-pipeline__name",
        commodity: "commodity_description",
        "-commodity": "-commodity_description",
        min_rate: "min_discount_rate",
        "-min_rate": "-min_discount_rate",
        walkup_rate: "min_rate",
        "-walkup_rate": "-min_rate",
    };

    const params = {
        commodities,
        commodities__commodity_type: commodity_type,
        filer__name__icontains: filer,
        heading_types,
        jurisdiction_id,
        name__search: text,
        ordering: sortMapping[sort] || sort,
        page,
        page_size,
        pipeline_id: pipeline.id,
        station_group_id: market,
        tariff_status_id: status,
        rate_authority_types__id__in: rate_authority_types,
    };
    const nullFields = ["issue_date", "effective_date", "min_rate", "min_discount_rate"];
    const field = sortMapping[sort] || sort;
    const sortField = field && field[0] == "-" ? field.slice(1) : field;
    if (nullFields.includes(sortField)) {
        params[`${sortField}__isnull`] = false;
    }
    if (tariff_type) {
        params.tariff_types = tariff_type;
    }

    if (year) {
        const yearMoment = moment(year, "yyyy");
        const from = yearMoment.clone().startOf("year").toISOString();
        const to = yearMoment.clone().endOf("year").toISOString();

        params["effective_date__gte"] = from;
        params["effective_date__lte"] = to;
    }

    return axios.get("/liquids/tariff", {
        params,
    });
};

const getSearchSuggestions = (search, next) =>
    next
        ? axios.get(next)
        : axios.get("/liquids/tariff-autocomplete", {
              params: {
                  active: true,
                  search,
              },
          });

const getStations = (params) => axios.get("/liquids/station", { params });
const getStation = (id) => axios.get(`/liquids/station/${id}`);

const getPricingHubs = (id) => {
    const baseURL = "/liquids/pricing-hub";
    return axios.get(id ? `${baseURL}/${id}` : baseURL);
};
const getCurrencies = () => axios.get("/liquids/currency");
const getVolumeMeasurementList = () => axios.get("/liquids/volume-measurement");
const getSingleStation = (id) => axios.get(`/liquids/station/${id}`);
const getCommodityTypes = () => axios.get("/liquids/commodity-type");
const getTariffHeadingTypes = (params = {}) => axios.get("liquids/tariff-heading-type", { params });
const getTariffTypes = (params = {}) => axios.get("liquids/tariff-type", { params });
const getCommodities = (params = {}) => axios.get("liquids/commodity", { params });
const getProducts = (params = {}) => axios.get("liquids/specific-product", { params });
const getPipelines = (params = {}) => axios.get("/liquids/pipeline", { params });
const getPricingHubsPrice = (params) =>
    axios.get("/liquids/pricing-hub-price", {
        params,
    });

const getStateList = async (limit = 100) => {
    const states = localStorage.getItem(ARBO_STATES_KEY);
    const response = await axios.get(`/liquids/state?page_size=${limit}`);
    return response.data;
};

const getStateById = async (id) => {
    const states = await getStateMap();
    return states[id];
};

const getStateMap = async () => {
    const cached = localStorage.getItem(ARBO_STATES_KEY);
    if (cached) {
        return JSON.parse(cached);
    }
    const states = await getStateList();
    const map = states?.results.reduce((acum, state) => {
        acum[state.id] = state;
        return acum;
    }, {});
    localStorage.setItem(ARBO_STATES_KEY, JSON.stringify(map));
    return map;
};

const getStationsByName = (search, limit = 100) =>
    axios.get(`/liquids/station?name__icontains=${search}&page_size=${limit}`);

const getStationsGroupByName = (search, limit = 100) =>
    axios.get(`/liquids/station-group?name__icontains=${search}&page_size=${limit}`);

const getStationsGroups = (params) => {
    const defaultParams = { page_size: 100, public: true };
    return axios.get("/liquids/station-group", { params: params || defaultParams });
};
const getStationPrice = (id, limit = 1) => axios.get(`/liquids/station/${id}/prices?page_size=${limit}`);

const getSearch = (search) => axios.get(`/search/?q=${search}`);
const getSearchHistory = (params) => {
    const query = Object.keys(params)
        .map((key) => [key, params[key]].join("="))
        .join("&");
    return axios.get(`/liquids/async-response-data`, {
        params: {
            ...params,
            corridor__isnull: true,
        },
    });
};

const getBestPath = ({
    allow_current_rates,
    allow_future_rates,
    allowed_pipelines,
    committed,
    commodities,
    commodity_grade,
    commodity_types,
    currency,
    destination_name,
    destination_polygon,
    destination_station,
    destination_station_group,
    forbidden_pipelines,
    fssg_dest,
    fssg_orig,
    jurisdiction,
    max_path_steps,
    max_pipelines,
    origin_name,
    origin_polygon,
    origin_station,
    origin_station_group,
    page,
    segment,
    specific_products,
    tariff_id,
    volume,
    volume_measurement,
}) => {
    const params = {
        allowed_pipelines,
        committed: committed === "false" ? false : committed === "true" || undefined,
        commodities,
        commodity_grade,
        commodity_types: commodity_types instanceof Array ? commodity_types : [commodity_types],
        currency,
        destination_name,
        destination_polygon,
        destination_station,
        destination_station_group,
        forbidden_pipelines,
        forbidden_steps_in_destination_station_group: fssg_dest,
        max_path_steps,
        forbidden_steps_in_origin_station_group: fssg_orig,
        max_pipelines,
        jurisdiction,
        page,
        origin_name,
        origin_polygon,
        origin_station,
        origin_station_group,
        specific_products,
        tariff_id,
        volume,
        volume_measurement,
    };
    if (allow_current_rates) {
        params.allow_current_rates = allow_current_rates === "false" ? false : allow_current_rates === "true";
        params.allow_future_rates = allow_future_rates === "false" ? false : allow_future_rates === "true";
    }

    if (params.tariff_id) {
        return axios.post("/liquids/tariff-rates/", params);
    } else if (segment) {
        return axios.post("/liquids/search-rates/", params);
    } else {
        return axios.post("/liquids/best-path/", params);
    }
};

const getTariffSegments = async ({ id }) => axios.get(`/liquids/search-rates?tariff_id=${id}`);

const getAsyncPaths = async ({ id }) => axios.get(`/liquids/async-response-data/${id}/paths`);
const getAsyncResponse = async ({ id }) => axios.get(`/liquids/async-response-data/${id}`);
const getTariffRate = async (id) => axios.get(`/liquids/tariff-rate/${id}`);
const getTariffRateDiscounts = async (tariff_rate_id) => {
    return axios
        .get(`/liquids/tariff-rate-discount`, {
            params: {
                tariff_rate_id,
                page_size: 100,
            },
        })
        .then((response) => response.data);
};

const getExtraCharges = async (tariff_rate_id) => {
    return axios
        .get(`/liquids/extra-charge`, {
            params: {
                page_size: 100,
                tariff_rate_id,
            },
        })
        .then((response) => response.data);
};

const getTariffRates = async (params) => {
    const response = await axios.get("/liquids/tariff-rate", {
        params: {
            ...params,
            page_size: 100,
        },
    });
    return response.data;
};

const exportTariffRates = async ({ tariff_id__in, id__in }, fmt = "csv") => {
    const response = await axios.get("/liquids/tariff-rate/export", {
        params: {
            tariff_id__in,
            id__in,
            fmt,
        },
    });
    return response.data;
};
const exportTariffRateDiscounts = async ({ tariff_id, tariff_rate__in }, fmt = "csv") => {
    const response = await axios.get("/liquids/tariff-rate-discount/export", {
        params: {
            tariff_rate__tariff_id: tariff_id,
            tariff_rate__in,
            fmt,
        },
    });
    return response.data;
};
const exportTariffRateExtraCharges = async ({ tariff_id, tariff_rate__in }, fmt = "csv") => {
    const response = await axios.get("/liquids/extra-charge/export", {
        params: {
            tariff_rate__tariff_id: tariff_id,
            tariff_rate__in,
            fmt,
        },
    });
    return response.data;
};

const getTariffStatus = async () => {
    const response = await axios.get("/liquids/tariff-status");
    return response.data;
};

const getJurisdiction = async () => {
    const response = await axios.get("/liquids/jurisdiction", { params: { page_size: 100 } });
    return response.data;
};

const getRateAuthorityTypes = async () => {
    const response = await axios.get("/liquids/rate-authority-type", { params: { page_size: 100 } });
    return response.data;
};

const getCorridor = (id) => axios.get(`/liquids/corridor/${id}`);
const getCorridors = (params = {}) =>
    axios.get("/liquids/corridor", {
        params: {
            category__name: "ArbTracker",
            ...params,
        },
    });
const nextCorridors = (next) => axios.get(next);

export default {
    exportTariffRates,
    exportTariffRateDiscounts,
    exportTariffRateExtraCharges,
    getAsyncPaths,
    getAsyncResponse,
    getBestPath,
    getCommodities,
    getCommodityTypes,
    getCorridor,
    getCorridors,
    getCurrencies,
    getDocumentPdf,
    getExtraCharges,
    getLatestTariffs,
    getPath,
    getPipelines,
    getPricingHubs,
    getPricingHubsPrice,
    getProducts,
    getRelatedTariffs,
    getAllRelatedTariffs,
    getSearch,
    getSearchHistory,
    getSearchSuggestions,
    getSingleStation,
    getStateById,
    getStateMap,
    getStationPrice,
    getStation,
    getStations,
    getStationsByName,
    getStationsGroupByName,
    getStationsGroups,
    getTariff,
    getTariffs,
    getTariffHeadingTypes,
    getTariffTypes,
    getTariffRate,
    getJurisdiction,
    getRateAuthorityTypes,
    getTariffRateDiscounts,
    getTariffRates,
    getTariffStatus,
    getVolumeMeasurementList,
    nextCorridors,
    searchTariffs,
};
