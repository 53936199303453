import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import ApiLiquids from "../api/api.liquids";
import { getUserProfile, patchUserAttributes } from "api";
import { BILLING_FREQUENCY, getCurrentSubscription, getPlans, getProducts, PLANS } from "api/api.commerce";
import ApiBookmarks from "api/api.bookmarks";

export const getSession = createAsyncThunk("layout/getSession", async () => ApiBookmarks.getSession());
// Async actions
//TODO: move to it owns slice
export const loadStation = createAsyncThunk("layout/loadStation", async (stationId) => {
    const response = await ApiLiquids.getSingleStation(stationId);
    return response.data;
});

export const loadStationGroups = createAsyncThunk("layout/loadStationGroups", async () => {
    const response = await ApiLiquids.getStationsGroups();
    return response.data;
});

export const loadCurrencies = createAsyncThunk("layout/loadCurrencies", async () => {
    const response = await ApiLiquids.getCurrencies();
    return response.data;
});

export const loadVolumeMeasurementList = createAsyncThunk("layout/loadVolumeMeasurementList", async () => {
    const response = await ApiLiquids.getVolumeMeasurementList();
    return response.data;
});

export const loadUser = createAsyncThunk("layout/loadUser", async (force) => {
    const userProfile = force ? await patchUserAttributes() : await getUserProfile();
    return userProfile || null;
});

export const loadPlans = createAsyncThunk("layout/loadPlans", async () => getPlans());
export const loadProducts = createAsyncThunk("layout/loadProducts", async () => getProducts());

export const loadStates = createAsyncThunk("layout/loadStates", async (force) => {
    const map = await ApiLiquids.getStateMap();
    return map;
});

export const loadSubscription = createAsyncThunk("layout/loadSubscription", async () => getCurrentSubscription());

const setUserPrivileges = (state) => {
    if (state.userProfile) {
        const { groups, is_account_admin, user } = state.userProfile;
        state.isOptmUser = groups?.find((d) => d.name === "OPTM");
        state.isMapSearchUser = groups?.find((d) => d.name === "MAP-SEARCH");
        state.isTariffSearchUser = groups?.find((d) => d.name === "TARIFF-SEARCH");
        state.isArbTrackerUser = groups?.find((d) => d.name === "ARB-TRACKER");
        state.isForm6User = groups?.find((d) => d.name === "LEGACY-FORM6");
        state.isAnalyticsUser = groups?.find((d) => d.name === "FLOW-ANALYTICS");
        state.isAccountAdmin = is_account_admin;
        state.isStaffUser = user?.is_staff;
    }
};

const parsePlan = (plan) => {
    const products = plan?.prices.reduce((acum, price) => {
        acum[price.product] = {};
        acum[price.product][price.period] = price;
        return acum;
    }, {});
    const periods = _.uniq(plan?.prices.reduce((acum, d) => [...acum, d.period], []));
    const priceIndex = plan?.prices.reduce((acum, { period, product, amount }) => {
        acum[product] = acum[product] || {};
        const freq = period === BILLING_FREQUENCY.YEAR ? 12 : 1;
        const seat = Math.floor(amount / freq / (plan?.max_users || 1));
        const monthly = Math.floor(amount / freq);
        acum[product][period] = {
            amount,
            seat,
            monthly,
        };
        return acum;
    }, {});

    return {
        ...plan,
        isFree: !!products[PLANS.FREE] || !Object.keys(products).length,
        isInsights: !!products[PLANS.INSIGHTS],
        isOptm: !!products[PLANS.OPTM] || products[PLANS.TEAM],
        isPro: !!products[PLANS.PRO],
        isTeam: !!products[PLANS.TEAM],
        periods,
        priceIndex,
        products,
    };
};

export const layoutSlice = createSlice({
    name: "layout",
    initialState: {
        contentVisited: true,
        currencies: null,
        customRegions: {
            origin: null,
            destination: null,
        },
        draw: {
            isDrawing: false,
            removeAll: false,
            removeLastPolygon: false,
            removePolygon: null,
        },
        isAccountAdmin: null,
        isAnalyticsUser: null,
        isArbTrackerUser: true,
        isForm6User: undefined,
        isMapSearchUser: null,
        isOptmUser: null,
        isStaffUser: null,
        isTariffSearchUser: null,
        isTeamAdmin: false,
        layerCategories: [],
        loadingStation: false,
        modal: null,
        openCalcPanel: false,
        openHowToDrawer: false,
        openSearchPanel: false,
        taxonomyVisited: true,
        plan: null,
        plans: null,
        popupConfig: {
            shouldRender: false,
        },
        products: null,
        selectCountyOnMap: false,
        selectedDestination: {},
        selectedFeature: {},
        session: null,
        showDrawOnMap: false,
        sidebarCollapsed: false,
        statesMap: null,
        stationDetails: null,
        stationGroups: null,
        stationGroupsById: {},
        stationsPopup: null,
        subscription: null,
        tariffId: null,
        treeRoutes: null,
        user: null,
        userProfile: null,
        volumeMeasurementList: [],
    },
    reducers: {
        setModal: (state, { payload }) => {
            state.modal = payload;
        },
        setCollapsed: (state, { payload }) => {
            if (!payload) {
                // state.openSearchPanel = false;
            }
            state.sidebarCollapsed = payload;
        },
        setStationsPopup: (state, { payload }) => {
            state.stationsPopup = payload;
        },
        setPopupConfig: (state, { payload }) => {
            state.popupConfig = payload;
        },
        setTariffId: (state, { payload }) => {
            state.tariffId = payload;
        },
        setUser: (state, { payload }) => {
            if (!state.user) {
                state.user = payload.user;
                setUserPrivileges(state);
            }
        },
        updateSearchPanel: (state, { payload }) => {
            if (payload) {
                state.sidebarCollapsed = true;
            }
            state.openSearchPanel = !!payload;
        },
        clearStationDetails: (state) => {
            state.stationDetails = null;
        },
        updateShowDrawOnMap: (state, action) => {
            state.showDrawOnMap = action.payload;
            state.selectCountyOnMap = false;
        },
        updateSelectCountyOnMap: (state, action) => {
            state.selectCountyOnMap = action.payload;
            state.showDrawOnMap = false;
        },
        updateCustomRegion: (state, action) => {
            if (action.payload) {
                const { type, geometry } = action.payload;
                state.customRegions[type] = geometry;
            } else {
                state.customRegions = {
                    origin: null,
                    destination: null,
                };
            }
        },
        setDrawingConfig: (state, { payload }) => {
            state.draw = {
                ...state.draw,
                ...payload,
            };
        },
        updateLayerCategories: (state, { payload }) => {
            state.layerCategories = payload?.length ? [...payload] : [];
        },
        updateSelectedFeature: (state, { payload }) => {
            state.selectedFeature = payload;
        },
        updateSelectedDestination: (state, { payload }) => {
            state.selectedDestination = payload;
        },
        updateStationDetails: (state, { payload }) => {
            state.stationDetails = payload
                ? {
                      ...payload,
                  }
                : null;
        },
        setContentVisited: (state, { payload }) => {
            state.contentVisited = payload;
        },
        setTreeRoutes: (state, { payload }) => {
            state.treeRoutes = payload;
        },
        toggleHowToDrawer: (state) => {
            state.openHowToDrawer = !state.openHowToDrawer;
        },
    },
    extraReducers: {
        [getSession.pending]: (state) => {
            state.plans = {
                ...state.plans,
                loading: true,
            };
        },
        [getSession.fulfilled]: (state, { payload }) => {
            console.log("getSession", payload);
            // state.session =
        },
        [loadPlans.pending]: (state) => {
            state.plans = {
                ...state.plans,
                loading: true,
            };
        },
        [loadPlans.fulfilled]: (state, { payload }) => {
            const results = payload.results.map((plan) => parsePlan(plan));

            state.plans = {
                ...payload,
                results,
                indexed: results.reduce((acum, obj) => {
                    acum[obj.id] = obj;
                    return acum;
                }, {}),
                free: results.find((d) => d.name === PLANS.FREE),
                pro: results.find((d) => d.name === PLANS.PRO),
                proOptm: results.find(
                    ({ products }) => products[PLANS.PRO] && products[PLANS.OPTM] && Object.keys(products).length === 2,
                ),
                proInsights: results.find(
                    ({ products }) =>
                        products[PLANS.PRO] && products[PLANS.INSIGHTS] && Object.keys(products).length === 2,
                ),
                proOptmInsights: results.find(
                    ({ products }) =>
                        products[PLANS.PRO] &&
                        products[PLANS.OPTM] &&
                        products[PLANS.INSIGHTS] &&
                        Object.keys(products).length === 3,
                ),
                team: results.find((d) => d.name === PLANS.TEAM),
                teamInsights: results.find(
                    ({ products }) =>
                        products[PLANS.TEAM] && products[PLANS.INSIGHTS] && Object.keys(products).length === 2,
                ),
                optm: results.find((d) => d.name === PLANS.OPTM),
                insights: results.find((d) => d.name === PLANS.INSIGHTS),
                dataProducts: results.find(
                    ({ products }) =>
                        products[PLANS.OPTM] && products[PLANS.OPTM] && Object.keys(products).length === 2,
                ),
                enterprise: results.find((d) => d.name === PLANS.ENTERPRISE),
            };
        },
        [loadProducts.pending]: (state) => {
            state.products = {
                ...state.products,
                loading: true,
            };
        },
        [loadProducts.fulfilled]: (state, { payload }) => {
            const byCode = payload.results.reduce((acum, obj) => {
                acum[obj.code] = obj;
                acum[obj.code.toLowerCase()] = obj;
                return acum;
            }, {});
            state.products = {
                ...payload,
                ...byCode,
                byCode,
                indexed: payload.results.reduce((acum, obj) => {
                    acum[obj.id] = obj;
                    return acum;
                }, {}),
            };
        },
        [loadStation.pending]: (state) => {
            state.loadingStation = true;
        },
        [loadCurrencies.fulfilled]: (state, { payload }) => {
            state.currencies = payload.results.reduce((acum, obj) => {
                acum[obj.id] = obj;
                return acum;
            }, {});
        },
        [loadVolumeMeasurementList.fulfilled]: (state, { payload }) => {
            state.volumeMeasurementList = payload.results;
        },
        [loadStation.fulfilled]: (state, action) => {
            state.stationDetails = action.payload;
            state.loadingStation = false;
        },
        [loadStationGroups.fulfilled]: (state, { payload }) => {
            state.stationGroups = payload?.results.features.map((d) => ({ id: d.id, ...d.properties }));
            state.stationGroupsById = payload?.results.features.reduce((acum, obj) => {
                acum[obj.id] = obj;
                return acum;
            }, {});
        },
        [loadSubscription.fulfilled]: (state, { payload }) => {
            state.subscription = payload;
            state.plan = parsePlan(payload.plan);

            state.isOptmUser = payload.allowed_groups?.find((d) => d.name === "OPTM");
            state.isMapSearchUser = payload.allowed_groups?.find((d) => d.name === "MAP-SEARCH");
            state.isTariffSearchUser = payload.allowed_groups?.find((d) => d.name === "TARIFF-SEARCH");
            state.isArbTrackerUser = payload.allowed_groups?.find((d) => d.name === "ARB-TRACKER");
            state.isForm6User = payload.allowed_groups?.find((d) => d.name === "LEGACY-FORM6");
            state.isAnalyticsUser = payload.allowed_groups?.find((d) => d.name === "FLOW-ANALYTICS");
            state.isTeamAdmin = state.isAccountAdmin && state.plan.isTeam;
        },
        [loadStates.fulfilled]: (state, { payload }) => {
            state.statesMap = payload;
        },
        [loadUser.fulfilled]: (state, { payload }) => {
            state.userProfile = payload;
            state.user = payload.user;
            setUserPrivileges(state);
        },
    },
});

export const {
    clearStationDetails,
    setCollapsed,
    setDrawingConfig,
    setModal,
    setContentVisited,
    setPopupConfig,
    setStationsPopup,
    setTariffId,
    setTreeRoutes,
    setUser,
    updateCustomRegion,
    updateLayerCategories,
    updateSearchPanel,
    updateSelectCountyOnMap,
    updateSelectedFeature,
    updateSelectedDestination,
    updateShowDrawOnMap,
    updateStationDetails,
    toggleHowToDrawer,
} = layoutSlice.actions;

export default layoutSlice.reducer;
