import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Button } from "antd";
import Text from "antd/es/typography/Text";

import { trackEvent } from "api/analytics";
import { ReactComponent as Arrow } from "assets/Icon/Arrow/Right.svg";
import { generateRouteLabel } from "Utils";
import { getPath, setRouteDetailOpen, setSelectedPath, updateRouteDetailPanel } from "reducers/searchSlice";
import GaugeButton from "pages/models/components/GaugeButton";
import BookmarkStar from "components/bookmarks/BookmarkStar";
import clsx from "clsx";

function PathCard({ path, bestPath, idx }) {
    const dispatch = useDispatch();
    const { selectedPath, openRouteDetails, isSegmentSearch } = useSelector(({ search }) => search);
    const pipelineAliases = useSelector(({ analytics }) => analytics.pipelineAliases);
    const [alias, setAlias] = useState();
    const label = generateRouteLabel(path?.origin_station, path?.destination_station);

    useEffect(() => {
        if (pipelineAliases?.pipeline) {
            const alias = path?.path_steps
                ?.map((step) => pipelineAliases.pipeline[step.pipeline.id])
                .filter((a) => a)
                .shift();
            setAlias(alias);
        }
    }, [pipelineAliases]);

    const isSelected = () => {
        return selectedPath?.id === path?.id;
    };

    const isDetailsOpen = () => {
        return isSelected() && openRouteDetails;
    };

    const viewDetails = useCallback(
        (e, throughput) => {
            if (throughput) {
                trackEvent("Route Card - Open Throughputs Tab", {
                    ...alias,
                    isBestPath: bestPath,
                });
            } else {
                trackEvent("Route Card - Open Route Details", { isBestPath: bestPath });
            }
            e.preventDefault();
            e.stopPropagation();
            dispatch(setSelectedPath({ ...path, throughput }));
            dispatch(getPath(path.id));
            const open = window.innerWidth <= 1024 ? "expand" : true;
            dispatch(setRouteDetailOpen(open));
        },
        [selectedPath, bestPath],
    );

    const handleCardClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setRouteDetailOpen(false));
        dispatch(updateRouteDetailPanel(false));
        dispatch(setSelectedPath(path));
    };

    if (!path?.total_rate) {
        return <></>;
    }

    return (
        <Row
            className={clsx(
                "path-card",
                isSelected() && !isSegmentSearch && "selected",
                isSegmentSearch && isSelected() && "segments",
            )}
            onClick={handleCardClick}
        >
            <Col span={20} className="mb-8 flex flex-col">
                <div>
                    <Text className="path-title">{label}</Text>
                </div>
                <div>
                    {path?.path_steps?.length > 0 && (
                        <Text className="path-sub-title">
                            Via {path?.path_steps.map(({ pipeline }) => pipeline.name).join(", ")}
                        </Text>
                    )}
                </div>
            </Col>
            <Col span={4} className="flex items-end flex-col">
                <BookmarkStar
                    app_label="liquids"
                    model="path"
                    object_id={path?.id}
                    tooltip="Bookmark this route"
                    data={path}
                />
            </Col>
            <Col
                span={8}
                style={{
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Text className="total-rate">{(+path?.total_rate).toFixed(2)}</Text>
                {/*<Text className="base-rate-label">20 years | Min. 10000 bpd</Text>*/}
                <Text className="base-rate-currency">US Cents/Barrel</Text>
            </Col>
            <Col
                span={8}
                style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    textTransform: "uppercase",
                }}
            >
                {alias ? (
                    <div className="flex" style={{ alignItems: "center" }} onClick={(e) => viewDetails(e, true)}>
                        <Text className="button-text">Throughputs</Text>{" "}
                        <GaugeButton
                            onClick={(e) => viewDetails(e, true)}
                            style={{ paddingLeft: 10, paddingRight: 10 }}
                        />
                    </div>
                ) : (
                    ""
                )}
            </Col>
            <Col
                span={8}
                style={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "flex-end",
                    height: 26,
                }}
            >
                {!isDetailsOpen() && (
                    <Link to={`/path/${path?.id}`} onClick={viewDetails}>
                        <Button type="link">
                            VIEW DETAILS <Arrow />
                        </Button>
                    </Link>
                )}
            </Col>
        </Row>
    );
}

export default PathCard;
