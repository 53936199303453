import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Layout, Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import ResizeObserver from "rc-resize-observer";

import Api from "api";
import { trackEvent } from "api/analytics";
import { getUserProfile } from "api";
import { setCollapsed, setContentVisited } from "reducers/layoutSlice";

import { ReactComponent as ArrowSider } from "assets/Icon/Arrow/Arrow-Sider.svg";
import { ReactComponent as ArbTrackerIcon } from "assets/IconsV2/ArbTracker.svg";
import { ReactComponent as MapSearchIcon } from "assets/IconsV2/MapSearch.svg";
import { ReactComponent as TariffSearchIcon } from "assets/IconsV2/TariffSearch.svg";
import { ReactComponent as Form6SearchIcon } from "assets/IconsV2/Form6Search.svg";

import { ReactComponent as PrivacyIcon } from "assets/IconsV2/Privacy.svg";
import { ReactComponent as TermsIcon } from "assets/IconsV2/Terms.svg";

import { ReactComponent as GaugeIcon } from "assets/IconsV2/Throughputs.svg";
import { ReactComponent as OptmIcon } from "assets/IconsV2/Optm.svg";
import { ReactComponent as HeaderBell } from "assets/Icon/NotificationsBellSidebar.svg";
import SidebarLogo from "assets/logo/newLogo.svg";
import SmallLogo from "assets/logo/small-logo.svg";

const { Sider } = Layout;

export default function Sidebar({ option, isPublic }) {
    const history = useHistory();
    const dispatch = useDispatch();

    const sidebarCollapsed = useSelector(({ layout }) => layout.sidebarCollapsed);
    const taxonomyVisited = useSelector(({ layout }) => layout.taxonomyVisited);
    const [openKeys, setOpenKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([option]);
    const [groups, setGroups] = useState([]);
    const [isShortScreen, setShortScreen] = useState();
    const [dashboardLink, setDashboardLink] = useState();

    useEffect(() => {
        if (!isPublic) {
            const profile = getUserProfile();
            const { attributes, groups } = profile;
            dispatch(setContentVisited(attributes?.optm_visited));
            if (profile?.groups?.length) {
                setGroups(groups ? groups.map((d) => d.name) : []);
            } else {
                Api.patchUserAttributes().then(({ groups }) => {
                    setGroups(groups ? groups.map((d) => d.name) : []);
                });
            }
            setDashboardLink(profile?.user?.is_staff);
        }
    }, [dispatch, isPublic]);

    useEffect(() => {
        setOpenKeys([]);
        setSelectedKeys([option]);
    }, [option]);

    const onBreakPoint = (broken) => {
        dispatch(setCollapsed(broken));
    };

    return (
        <ResizeObserver
            onResize={({ width, height }) => {
                setShortScreen(height <= 580);
            }}
        >
            <Sider
                width={240}
                className={"main-layout-sider" + (isShortScreen ? " main-layout-sider--short" : "")}
                breakpoint="xxl"
                collapsed={sidebarCollapsed || isPublic}
                onBreakpoint={onBreakPoint}
            >
                <div
                    className="collapse-btn"
                    onClick={() => {
                        dispatch(setCollapsed(!sidebarCollapsed));
                    }}
                >
                    <ArrowSider />
                </div>
                <Empty
                    onClick={(e) => {
                        dashboardLink && history.push("/dashboard");
                    }}
                    image={sidebarCollapsed || isPublic ? SmallLogo : SidebarLogo}
                    description={false}
                    imageStyle={{
                        height: 28,
                    }}
                    className={dashboardLink ? "cursor-pointer" : ""}
                />
                <Menu
                    mode="inline"
                    theme="dark"
                    className="main-menu"
                    selectedKeys={selectedKeys}
                    openKeys={openKeys}
                    forceSubMenuRender={true}
                    triggerSubMenuAction="click"
                >
                    {groups.includes("ARB-TRACKER") || groups.includes("BASIC") ? (
                        <Menu.Item
                            key="tracker"
                            onClick={(e) => {
                                trackEvent("Arb Tracker - Main Menu");
                                history.push("/tracker");
                            }}
                            icon={<ArbTrackerIcon />}
                        >
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                to="/tracker"
                            >
                                Arb Tracker
                            </Link>
                        </Menu.Item>
                    ) : (
                        ""
                    )}
                    {groups.includes("MAP-SEARCH") || groups.includes("BASIC") ? (
                        <Menu.Item
                            key="map"
                            icon={<MapSearchIcon />}
                            onClick={() => {
                                history.push("/map/search");
                                trackEvent("Map Search - Main Menu");
                            }}
                        >
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                to="/map/search"
                            >
                                Map Search
                            </Link>
                        </Menu.Item>
                    ) : (
                        ""
                    )}

                    <Menu.Item
                        key="tariff"
                        icon={<TariffSearchIcon />}
                        onClick={() => {
                            history.push("/search");
                            trackEvent("Tariff Search - Main Menu");
                        }}
                    >
                        <Link
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            to="/search"
                        >
                            Tariff Search
                        </Link>
                    </Menu.Item>
                    {groups.includes("LEGACY-FORM6") || groups.includes("BASIC") ? (
                        <Menu.Item
                            key="form6"
                            icon={<Form6SearchIcon />}
                            onClick={() => {
                                history.push("/form6");
                                trackEvent("Form 6 - Main Menu");
                            }}
                        >
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                to="/form6"
                            >
                                Form 6 Search
                            </Link>
                        </Menu.Item>
                    ) : (
                        ""
                    )}
                    {groups.includes("FLOW-ANALYTICS") || groups.includes("BASIC") ? (
                        <Menu.Item
                            key="models"
                            icon={<GaugeIcon />}
                            onClick={() => {
                                history.push("/models/throughput");
                                trackEvent("Throughput - Main Menu");
                            }}
                        >
                            <Link
                                to="/models/throughput"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                Throughput
                            </Link>
                        </Menu.Item>
                    ) : (
                        ""
                    )}
                    <Menu.Item
                        key="optm"
                        className={!taxonomyVisited ? "menu-item-highlight" : ""}
                        icon={<OptmIcon />}
                        onClick={() => {
                            history.push("/optm");
                            trackEvent("OPTM - Main Menu");
                        }}
                    >
                        <Link
                            to="/optm"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            OPTM
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="account"
                        icon={<HeaderBell style={{ padding: "2px" }} />}
                        onClick={() => {
                            history.push("/user/account");
                            trackEvent("Account Mgmt - Main Menu");
                        }}
                    >
                        <Link
                            to="/user/account"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            Favorites & Notifications
                        </Link>
                    </Menu.Item>
                </Menu>

                <Menu mode="inline" theme="dark" className="main-menu footer">
                    <Menu.Item
                        style={{ height: 68, paddingTop: 22 }}
                        icon={
                            <PrivacyIcon
                                style={{ marginLeft: -2 }}
                                onClick={() => {
                                    history.push("/privacy");
                                }}
                            />
                        }
                    >
                        <Link to="/privacy">Privacy</Link>
                    </Menu.Item>
                    <Menu.Item
                        style={{ height: 68, paddingTop: 22 }}
                        icon={
                            <TermsIcon
                                style={{ marginLeft: -2 }}
                                onClick={() => {
                                    history.push("/terms");
                                }}
                            />
                        }
                    >
                        <Link to="/terms">Terms</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
        </ResizeObserver>
    );
}
