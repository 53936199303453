import React, { useCallback, useEffect, useState } from "react";
import { Radio, Space, Modal } from "antd";
import StarRating from "components/atoms/StarRating";

import styles from "./FeedbackSnippet.module.css";

import Api from "api";
import { trackEvent } from "api/analytics";
import { saveAction } from "api/api.bookmarks";
import AppcueFeedbackForm from "../analytics/AppcueFeedbackForm";

export default function ({
    type = "stars",
    event,
    id,
    question,
    thanks = "Thanks for your feedback!",
    data = {},
    title = "Please give us your feedback",
}) {
    const [settings, setSettings] = useState();
    const [rating, setRating] = useState();
    const [showModal, setShowModal] = useState(false);
    const storeId = `feedback-snippet-${id}`;

    const notificationProps = {
        event,
        id,
        allowFeedback: true,
        props: data,
    };

    useEffect(() => {
        if (settings?.value) {
            setRating(settings?.value);
        } else {
            Api.getLocalPreferences(storeId).then(setSettings);
        }
    }, [settings, id]);

    const ratingChange = useCallback(
        (value) => {
            setRating(value);
            trackEvent(event, {
                value,
                ...data,
            });
            Api.saveLocalPreferences({ id: storeId, data: { ...data, sent: true, value } });
            saveAction({
                action_type: "rating",
                app_label: "liquids",
                model: "asyncresponsedata",
                object_id: id,
                data: {
                    ...data,
                    event,
                    value,
                },
            });
        },
        [data, trackEvent],
    );

    useEffect(() => {
        if (rating && (rating < 5 || rating === "no")) {
            Api.getLocalPreferences(`feedback-form-${id}`).then((data) => {
                if (!data) {
                    setShowModal(true);
                }
            });
        }
    }, [rating]);

    return (
        <>
            <Modal
                visible={showModal}
                mask={false}
                style={{ top: 20 }}
                width={480}
                className="feedback-modal"
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setShowModal(false);
                }}
                title={title}
            >
                <AppcueFeedbackForm
                    {...notificationProps}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            </Modal>
            <div className="feedback-snippet">
                <div className={styles.text}>{rating ? thanks : question}</div>
                {!rating ? (
                    type === "stars" ? (
                        <StarRating size={22} onChange={ratingChange} />
                    ) : (
                        <Radio.Group className={styles.radio} onChange={(e) => ratingChange(e.target.value)}>
                            <Space direction="horizontal">
                                <Radio className={styles.radio} value="yes">
                                    Yes
                                </Radio>
                                <Radio className={styles.radio} value="no">
                                    No
                                </Radio>
                            </Space>
                        </Radio.Group>
                    )
                ) : null}
            </div>
        </>
    );
}
