import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import { message, Alert, Button, Col, Form, Input, Row, Typography, Select } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

import Api from "api";
import { trackEvent, trackRegisterPage, trackRegisterClick } from "api/analytics";
import { ReactComponent as LoginBack } from "assets/Icon/Arrow/loginBack.svg";

import { useQuery } from "react-query";

const { Title } = Typography;
const { Option } = Select;

export default function RegisterForm({ withPayment }) {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const query_ref = query.get("ref");
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const [errorMessages, setErrorMessages] = useState([]);
    const { loadingRoles, data: roles } = useQuery("getJobRoles", () => Api.getJobRoles({}));

    useEffect(() => {
        trackRegisterPage();
    }, []);

    const submitForm = (values) => {
        trackEvent("create account cta", {
            ref: query_ref,
        });
        if (query_ref) {
            trackEvent("Account Mgmt - Registration Complete", {
                ref: query_ref,
            });
            values.ref = query_ref;
        } else {
            trackEvent("Account Mgmt - Registration Complete", {
                ref: query_ref,
            });
        }
        const plan = query.get("checkout_plan");
        if (+plan > 0) {
            values.plan = +plan;
        }
        const prices = query.get("checkout_prices");
        if (typeof prices === "string") {
            values.prices = prices.split(",").map((d) => +d);
        }

        setLoading(true);
        Api.userRegister(values)
            .then(() => {
                setErrorMessages([]);
                form.resetFields();
                trackEvent("Account Mgmt - Creation Complete", {
                    "account creation is completed": true,
                });
                if (query_ref) {
                    trackEvent("Account Mgmt - Creation with Referral Link");
                }
                setLoading(false);
                history.push("/register/verify?email=" + encodeURIComponent(values.email));
            })
            .catch((errors) => {
                trackEvent("Account Mgmt - Creation Error", {
                    errors,
                });
                const messages = [];
                if (Object.values(errors).length < 100) {
                    Object.values(errors).forEach((err) => messages.push(err));
                } else {
                    messages.push("An error has occurred. Please try again.");
                }
                if (errors.prices?.find((d) => d.includes("Invalid pk"))) {
                    messages.push("An error with the prices has occurred. Please go back to the Arbo Pricing page.");
                }
                setErrorMessages(messages);
                message.error(messages);
                setLoading(false);
            });
    };

    const submitRegister = async (event) => {
        trackRegisterClick();
        form.validateFields()
            .then((values) => {
                submitForm(values);
            })
            .catch(() => {
                message.warning("Please complete all required fields.");
            });
    };

    return (
        <Form
            form={form}
            name="register_form"
            className="register-form"
            style={
                withPayment
                    ? {
                          margin: "auto",
                      }
                    : {}
            }
            layout="vertical"
        >
            <Row className="register-form__body">
                <Col span={24}>
                    <Title level={1}>Sign up {query.get("checkout_plan") ? "to continue checkout" : ""}</Title>
                </Col>
                {errorMessages.length ? (
                    <Col span={24}>
                        {errorMessages.map((msg, idx) => (
                            <div key={idx} style={{ marginBottom: 24 }}>
                                <Alert message={msg} type="error" closable showIcon />
                            </div>
                        ))}
                    </Col>
                ) : null}
                <Col span={12} style={{ paddingRight: 12 }}>
                    <Form.Item
                        className="register-form__first-name"
                        name="firstName"
                        rules={[{ required: true, message: "Please input first name." }]}
                    >
                        <Input className="register-form__first-name-input" placeholder="First name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        className="register-form__last-name"
                        name="lastName"
                        rules={[{ required: true, message: "Please input last name." }]}
                    >
                        <Input className="register-form__last-name-input" placeholder="Last name" />
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingRight: 12 }}>
                    <Form.Item
                        rules={[{ required: true, message: "Please select your job role." }]}
                        className="register-form__job-title"
                        name="jobRole"
                    >
                        <Select
                            showSearch={true}
                            size="large"
                            loading={loadingRoles}
                            placeholder="Job role"
                            onChange={(value) => {
                                form.setFieldsValue({ jobRole: value });
                            }}
                        >
                            {roles?.data?.results.map((role) => (
                                <Option key={role.id} value={role.id}>
                                    {role.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        className="register-form__company"
                        name="company"
                        rules={[{ required: true, message: "Please input company." }]}
                    >
                        <Input className="register-form__company-input" placeholder="Company" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        className="register-form__email"
                        name="email"
                        rules={[{ required: true, type: "email", message: "Please input your work Email!" }]}
                    >
                        <Input
                            className="register-form__email-input"
                            placeholder="Enter the email you will use to log in"
                            prefix={<MailOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        className="register-form__password"
                        name="password"
                        rules={[{ required: true, message: "Please input your Password!" }]}
                    >
                        <Input
                            type="password"
                            className="register-form__password-input"
                            placeholder="Password"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>
                </Col>
                {withPayment ? (
                    <Col span={24}>
                        <Form.Item>
                            <div className="ant-col ant-form-item-label">
                                <label className="ant-form-item-required" title="Credit Card">
                                    Credit Card
                                </label>
                            </div>
                        </Form.Item>
                    </Col>
                ) : null}
                <Col span={24} style={{ paddingBottom: 24 }}>
                    By clicking below, you agree to the <Link to="/terms">Terms of Service</Link> and&nbsp;
                    <Link to="/privacy">Privacy Policy</Link>.
                </Col>
                <Col span={24}>
                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            className="register-form__button"
                            onClick={submitRegister}
                        >
                            Continue
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ paddingTop: "40px" }}>
                    <Link
                        to={"/login"}
                        className="loginBackBtn"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <LoginBack />
                        Back to login
                    </Link>
                </Col>
            </Row>
        </Form>
    );
}
