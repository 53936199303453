import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { message, Button, Checkbox, Col, Collapse, Divider, InputNumber, Row, Select, Tag } from "antd";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import amplitude from "amplitude-js";
import moment from "moment";
import * as turf from "@turf/turf";

import { ReactComponent as Plus } from "assets/Icon/Plus.svg";
import { ReactComponent as Minus } from "assets/Icon/Minus.svg";
import { ReactComponent as Destination } from "assets/Icon/Destination.svg";
import { ReactComponent as Origin } from "assets/Icon/Origin.svg";
import { ReactComponent as Pipeline } from "assets/Icon/Pipeline.svg";
import { ReactComponent as LinkIcon } from "assets/Icon/Link.svg";
import { ReactComponent as MaxPipeline } from "assets/Icon/Pipeline.svg";
import { ReactComponent as Reverse } from "assets/Icon/Reverse.svg";
import SelectCommodityType from "./SelectCommodityType";
import SearchAutocomplete, { AUTOCOMPLETE_TYPES } from "./searchAutocomplete";
import { store as mapStore } from "../../store/mapStore";
import UpdateHash, { loadSearch } from "components/utils/UpdateHash";
import { numberWithCommas } from "Utils";
import { saveAction } from "api/api.bookmarks";
import ApiLiquids from "api/api.liquids";
import {
    setDrawingConfig,
    setStationsPopup,
    updateCustomRegion,
    updateSelectedFeature,
    updateSelectedDestination,
} from "reducers/layoutSlice";
import {
    loadCommodityTypes,
    loadCommodities,
    loadPipelines,
    loadProducts,
    resetSearch,
    setAllowedPipelines,
    setCommittedRates,
    setCommodityType,
    setDestination,
    setAvoidSteps,
    setExcludedPipelines,
    setMaxPipelines,
    setOrigin,
    setPage,
    setSearchUrl,
    setSelectedCommodities,
    setTariffId,
    setVolume,
    setSelectedProducts,
    setSelectedJurisdiction,
    setSelectedEffective,
    countMapSearch,
} from "reducers/searchSlice";

import { buildSearchUrlFromSavedProperties } from "Utils";
import { trackEvent } from "api/analytics";

const { Panel } = Collapse;

const ratesOptions = [
    { label: "Committed", value: "committed" },
    { label: "Uncommitted", value: "uncommitted" },
];

function SearchPanel() {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = new URLSearchParams(useLocation().search);
    const {
        dispatch: mapDispatch,
        state: { drawObject },
    } = React.useContext(mapStore);

    const { loadingOptions, data: jurisdictionsData } = useQuery("getJurisdiction", () => ApiLiquids.getJurisdiction());
    const jurisdictions = jurisdictionsData?.results.map((j) => ({ value: j.id, label: j.long_name })) || [];
    const { isMapSearchUser } = useSelector(({ layout }) => layout);
    const {
        allowedPipelines,
        avoidSteps,
        committedRates,
        commodityGrade,
        commodityType,
        commodityTypes,
        destination,
        excludedPipelines,
        isSegmentSearch,
        loading,
        maxPipelines,
        origin,
        page,
        pipelines,
        products,
        searchUrl,
        selectedCommodities,
        selectedCommodityType,
        selectedJurisdiction,
        selectedEffective,
        selectedProducts,
        tariffId,
        volume,
        mapSearchCount,
    } = useSelector(({ search }) => search);

    const [activePanel, setActivePanel] = useState(["rates", "commodities"]);
    const [defaultActiveKey, setDefaultActiveKey] = useState([]);
    const [defaultRateValue, setDefaultRateValue] = useState([]);
    const [mainSearchOrigin, setMainSearchOrigin] = useState(null);
    const [rateSearchUrl, setRateSearchUrl] = useState("");
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [showSearch, setShowSearch] = useState(true);
    const [tariffParam, setTariffParam] = useState(null);
    const [volumeTimeout, updateVolumeTimeout] = useState(null);

    useEffect(() => {
        if (origin?.id || destination?.id || query.get("id")) {
            setShowSearch(true);
        }
    }, [origin, destination, query]);

    useEffect(() => {
        if (!origin && !destination && query.get("tariff_id") && !tariffParam) {
            const tariff_id = +query.get("tariff_id");
            setTariffParam(tariff_id);
        }
    }, [origin, destination, query, tariffParam]);

    useEffect(() => {
        const key = [];
        if (allowedPipelines?.length || excludedPipelines?.length || !avoidSteps?.origin || !avoidSteps?.destination) {
            key.push("rates");
            key.push("pipelines");
            key.push("commodities");
        }
        tariffId && key.push("tariff");
        if (selectedJurisdiction) {
            key.push("jurisdiction");
        }
        if (defaultRateValue?.length == 1) {
            key.push("rates");
        }
        setDefaultActiveKey(key);
    }, [selectedEffective, allowedPipelines, excludedPipelines, avoidSteps, tariffId, defaultRateValue]);

    useEffect(() => {
        if (tariffParam) {
            saveAction({
                action_type: "map_search",
                app_label: "liquids",
                model: "tariff",
                object_id: tariffParam,
            });
            setSelectedEffective("all");
        }
    }, [tariffParam]);

    useEffect(() => {
        if (!commodityTypes.length) {
            dispatch(loadCommodityTypes());
        }
    }, [commodityTypes.length, dispatch]);

    useEffect(() => {
        if (!products.length) {
            dispatch(loadProducts());
        }
    }, [products.length]);

    useEffect(() => {
        if (!selectedTariff && tariffId) {
            ApiLiquids.getTariff(tariffId).then(({ data }) => {
                setSelectedTariff(data);
            });
            setSelectedTariff({ loading: true });
        }
    }, [tariffId, selectedTariff]);

    useEffect(() => {
        dispatch(loadCommodities(selectedCommodityType));
    }, [dispatch, showSearch, selectedCommodityType]);

    useEffect(() => {
        if (!pipelines?.results?.length && !pipelines?.loading) {
            dispatch(loadPipelines());
        }
    }, [pipelines, dispatch]);

    const handleOriginSelect = (obj) => {
        if (obj?.type === "places") {
            var poly = turf.bboxPolygon(obj.bbox);

            dispatch(setOrigin({ id: "custom", type: "polygon", name: obj?.place_name, geometry: poly.geometry }));
            dispatch(
                updateCustomRegion({
                    type: "origin",
                    geometry: poly.geometry,
                }),
            );
            mapDispatch("set bounds", turf.bbox(poly));
        } else if (obj?.type !== "suggestion") {
            dispatch(setOrigin({ id: obj?.id, type: obj?.type, name: obj?.properties?.name }));
            dispatch(updateSelectedFeature(obj?.type === "station" ? obj : {}));
        }
    };

    const handleDestinationSelect = (obj) => {
        if (obj?.type === "places") {
            var poly = turf.bboxPolygon(obj.bbox);
            dispatch(setDestination({ id: "custom", type: "polygon", name: obj?.place_name, geometry: poly.geometry }));
            dispatch(
                updateCustomRegion({
                    type: "destination",
                    geometry: poly.geometry,
                }),
            );
            mapDispatch("set bounds", turf.bbox(poly));
        } else if (obj?.type !== "suggestion") {
            dispatch(setDestination({ id: obj?.id, type: obj?.type, name: obj?.properties?.name }));
            dispatch(updateSelectedDestination(obj?.type === "station" ? obj : {}));
        }
    };

    const clearSearch = (e) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(setTariffId());
        dispatch(resetSearch());
        dispatch(updateCustomRegion());
        dispatch(setStationsPopup({ shouldRender: false }));
        dispatch(setDrawingConfig({ removeAll: true }));
        history.push("/map/search");
    };

    const handleReverse = useCallback(
        (e) => {
            dispatch(setDestination(origin ?? { id: 0 }));
            dispatch(setOrigin(destination ?? { id: 0 }));
            trackEvent("Map Search - Reverse Origin and Destination", { origin, destination });
        },
        [dispatch, origin, destination],
    );

    useEffect(() => {
        const parts = [];
        if (origin?.type === "polygon") {
            try {
                parts.push(`origin_polygon=${encodeURIComponent(JSON.stringify(origin.geometry))}`);
            } catch (err) {
                console.error(err);
            }
        } else {
            origin?.id && origin?.type && parts.push(`origin_${origin?.type}=${origin?.id}`);
        }
        if (origin?.name) {
            parts.push("origin_name=" + origin.name);
        }
        if (destination?.name) {
            parts.push("destination_name=" + destination.name);
        }

        if (destination?.type === "polygon") {
            try {
                parts.push(`destination_polygon=${encodeURIComponent(JSON.stringify(destination.geometry))}`);
            } catch (err) {
                console.error(err);
            }
        } else {
            destination?.id && destination?.type && parts.push(`destination_${destination?.type}=${destination?.id}`);
        }
        maxPipelines && parts.push(`max_pipelines=${maxPipelines}`);
        if (allowedPipelines?.length) {
            parts.push(`allowed_pipelines=${allowedPipelines}`);
        }

        if (excludedPipelines?.length) {
            parts.push(`forbidden_pipelines=${excludedPipelines}`);
        }

        if (selectedCommodities?.length) {
            parts.push(`commodities=${selectedCommodities}`);
        }

        if (selectedProducts?.length) {
            parts.push(`specific_products=${selectedProducts}`);
        }

        if (selectedJurisdiction) {
            parts.push(`jurisdiction=${selectedJurisdiction}`);
        }

        const commodity_types = commodityType || 3;
        if (commodityGrade) {
            parts.push(`commodity_grade=${commodityGrade}`);
        }
        if (committedRates !== undefined) {
            parts.push(`committed=${committedRates}`);
        }
        parts.push(`commodity_types=${commodity_types}`);
        volume && parts.push(`volume=${volume}`);

        // fssg_orig: short for forbidden_steps_in_origin_station_group.
        avoidSteps?.origin && origin?.type === "station_group" && parts.push("fssg_orig=true");
        avoidSteps?.destination && destination?.type === "station_group" && parts.push("fssg_dest=true");

        if (selectedEffective === "future") {
            parts.push("allow_current_rates=false");
            parts.push("allow_future_rates=true");
        } else if (selectedEffective === "all") {
            parts.push("allow_current_rates=true");
            parts.push("allow_future_rates=true");
        }

        if (+tariffId) {
            parts.push(`tariff_id=${tariffId}`);
        }
        const query = parts.join("&");
        const url = `/map/search?${query}`;
        dispatch(setSearchUrl(url));
        setRateSearchUrl(`${url}&max_path_steps=2&segment=true`);
    }, [
        allowedPipelines,
        avoidSteps,
        committedRates,
        commodityGrade,
        commodityType,
        destination,
        dispatch,
        excludedPipelines,
        mapDispatch,
        maxPipelines,
        origin,
        page,
        selectedCommodities,
        selectedJurisdiction,
        selectedEffective,
        selectedJurisdiction,
        selectedProducts,
        tariffId,
        volume,
    ]);

    const onRouteSearch = (e, isRateSearch) => {
        const isGroupToGroup =
            (origin?.type === "station_group" || origin?.type === "polygon") &&
            (destination?.type === "station_group" || destination?.type === "polygon");
        amplitude.getInstance().logEvent("Map Search - Route Search", {
            "account creation is completed": true,
            "route search date": moment().toISOString(),
            "is group to group": isGroupToGroup,
            "origin type": origin?.type || "",
            "origin name": origin?.name || "",
            "destination type": destination?.type || "",
            "destination name": destination?.name || "",
        });
        const types = [origin?.type, destination?.type].filter((d) => d);
        const restricted = ["station_group", "polygon"];
        if (!types.length && (!isRateSearch || !tariffId)) {
            e.preventDefault();
            message.warning("Please select Station, Station Group, or Draw a Polygon as Origin / Destination.");
        } else if (
            !isRateSearch &&
            types.length <= 1 &&
            restricted.indexOf(types[0]) >= 0 &&
            !searchUrl.includes("tariff_id")
        ) {
            e.preventDefault();
            message.warning("You must select both Origin and Destination to include a Station Group or Custom Region.");
        } else {
            e.preventDefault();
            e.stopPropagation();
            const search = isRateSearch ? rateSearchUrl : searchUrl;
            if (!isMapSearchUser) {
                dispatch(countMapSearch());
            }

            if (isMapSearchUser || mapSearchCount >= 0) {
                loadSearch(
                    {
                        search: search.replace("/map/search", ""),
                    },
                    dispatch,
                );
            }
        }
    };

    useEffect(() => {
        const defaultValue = Boolean(committedRates)
            ? ["committed"]
            : committedRates === false
            ? ["uncommitted"]
            : ["committed", "uncommitted"];
        setDefaultRateValue(defaultValue);
    }, [committedRates]);

    useEffect(() => {
        const activePanel = ["commodities"];
        if (
            (!avoidSteps?.origin && origin?.type === "station_group") ||
            (!avoidSteps?.destination && destination?.type === "station_group")
        ) {
            activePanel.concat(["rates", "commodities"]);
        }
        if (allowedPipelines?.length || excludedPipelines?.length || maxPipelines) {
            activePanel.push("pipelines");
        }
        defaultRateValue?.length != 2 && activePanel.push("rates");
        selectedEffective && activePanel.push("effective");
        tariffId && activePanel.push("tariff");
        selectedJurisdiction && activePanel.push("jurisdiction");

        setActivePanel(activePanel);
    }, [
        allowedPipelines,
        excludedPipelines,
        selectedJurisdiction,
        defaultRateValue,
        avoidSteps,
        destination,
        maxPipelines,
        origin,
        selectedEffective,
        selectedJurisdiction,
        tariffId,
    ]);

    const handleVolumeChange = useCallback(
        (value) => {
            if (volumeTimeout) {
                clearTimeout(volumeTimeout);
                updateVolumeTimeout(null);
            }
            if (value) {
                updateVolumeTimeout(
                    setTimeout(() => {
                        dispatch(setVolume(value));
                        trackEvent("Map Search - Change Volume", { value });
                    }, 350),
                );
            } else {
                dispatch(setVolume(value));
            }
        },
        [volumeTimeout],
    );

    const renderForm = (origin, destination) => {
        return (
            <>
                {!selectedTariff ? (
                    <Row className="search-group" style={{ paddingBottom: 0, paddingRight: 0 }}>
                        <Col span={24}>
                            <Row
                                align="middle"
                                justify="center"
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                <Col flex="64px">
                                    <Origin
                                        className="orange-icon"
                                        style={{
                                            margin: "auto",
                                        }}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <SearchAutocomplete
                                        placeholder="Enter Origin Name, County, State, or Province"
                                        defaultValue={origin?.name}
                                        type={AUTOCOMPLETE_TYPES.ORIGIN}
                                        onSelect={handleOriginSelect}
                                        onClear={handleOriginSelect}
                                    />
                                </Col>
                                <Col flex="64px">
                                    <Reverse
                                        onClick={handleReverse}
                                        className="reverse-icon"
                                        style={{
                                            margin: "auto",
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row
                                align="middle"
                                justify="center"
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                <Col flex="64px">
                                    <Destination
                                        className="orange-icon"
                                        style={{
                                            margin: "auto",
                                        }}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <SearchAutocomplete
                                        defaultValue={destination?.name}
                                        placeholder="Enter Destination Name, County, State, or Province"
                                        type={AUTOCOMPLETE_TYPES.DESTINATION}
                                        onSelect={handleDestinationSelect}
                                        onClear={handleDestinationSelect}
                                    />
                                </Col>
                                <Col flex="64px" />
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}

                <Divider className="mb-0" />

                <Collapse
                    className="collapse-filter"
                    defaultActiveKey={defaultActiveKey}
                    activeKey={activePanel}
                    onChange={(values) => {
                        setActivePanel(values);
                    }}
                    expandIconPosition="right"
                    expandIcon={(props) => {
                        return props.isActive ? <Minus /> : <Plus />;
                    }}
                >
                    {selectedTariff ? (
                        <Panel header="TARIFF" key="tariff" className="divider-panel">
                            <Row style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                                <Col span={24}>
                                    <Tag closable={true} onClose={clearSearch}>
                                        <Link
                                            className="px-3 py-2"
                                            to={`/tariff/search?pipeline=${selectedTariff?.pipeline?.id}`}
                                        >
                                            {selectedTariff?.pipeline?.name}
                                        </Link>
                                    </Tag>
                                    <Tag closable={true} onClose={clearSearch}>
                                        <Link className="px-3 py-2" to={`/tariff/${selectedTariff?.id}`}>
                                            {selectedTariff?.name}
                                        </Link>
                                    </Tag>
                                </Col>
                            </Row>
                        </Panel>
                    ) : (
                        ""
                    )}
                    {!selectedTariff ? (
                        <Panel header="RATES" key="rates" className="divider-panel">
                            <Row style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                                <Col span={24}>
                                    <Checkbox.Group
                                        options={ratesOptions}
                                        defaultValue={defaultRateValue}
                                        value={defaultRateValue}
                                        onChange={(values) => {
                                            let committedRates = "";

                                            if (values.length === 1) {
                                                committedRates = values.includes("committed");
                                            }
                                            trackEvent("Map Search change committed rates", { committedRates });
                                            dispatch(setCommittedRates(committedRates));
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    ) : (
                        ""
                    )}
                    {!selectedTariff ? (
                        <Panel header="PIPELINES" key="pipelines" className="divider-panel">
                            <Row
                                align="middle"
                                justify="center"
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                <Col flex="64px" style={{ alignItems: "top", marginBottom: 60 }}>
                                    <LinkIcon
                                        style={{
                                            alignSelf: "top",
                                            margin: "auto",
                                            height: 20,
                                            width: 20,
                                        }}
                                    />
                                </Col>
                                <Col flex="auto" style={{ alignItems: "center", paddingTop: 8 }}>
                                    <Row>
                                        <Col style={{ paddingBottom: 8 }} span={24}>
                                            Avoid jumps within:
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} style={{ paddingBottom: 8 }}>
                                            <Checkbox
                                                defaultChecked={avoidSteps.origin}
                                                checked={avoidSteps.origin}
                                                onChange={(e) => {
                                                    dispatch(setAvoidSteps({ origin: e.target.checked }));
                                                    trackEvent("Map Search avoid jumps within origin station groups", {
                                                        checked: e.target.checked,
                                                    });
                                                }}
                                            >
                                                Origin station group
                                            </Checkbox>
                                        </Col>
                                        <Col span={24} style={{ paddingBottom: 4 }}>
                                            <Checkbox
                                                defaultChecked={avoidSteps.destination}
                                                checked={avoidSteps.destination}
                                                onChange={(e) => {
                                                    dispatch(setAvoidSteps({ destination: e.target.checked }));
                                                    trackEvent("Map Search avoid jumps within origin station groups", {
                                                        checked: e.target.checked,
                                                    });
                                                }}
                                            >
                                                Destination station group
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                align="middle"
                                justify="center"
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                <Col flex="64px">
                                    <MaxPipeline
                                        style={{
                                            margin: "auto",
                                        }}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <InputNumber
                                        placeholder="Max. Pipelines"
                                        style={{
                                            width: "100%",
                                            height: 48,
                                            maxWidth: 374,
                                        }}
                                        defaultValue={maxPipelines}
                                        onChange={(value) => {
                                            dispatch(setMaxPipelines(value));
                                            trackEvent("Map Search - Change Max Pipelines", { value });
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row
                                align="middle"
                                justify="center"
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                <Col flex="64px">
                                    <Pipeline
                                        className="allowed-icon"
                                        style={{
                                            margin: "auto",
                                        }}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <Select
                                        mode="multiple"
                                        maxTagCount={1}
                                        options={pipelines?.results?.map((p) => ({ label: p.name, value: p.id }))}
                                        allowClear
                                        loading={pipelines?.loading}
                                        maxTagTextLength={10}
                                        placeholder="Allowed Pipelines"
                                        className="pipelines-select"
                                        optionFilterProp={"label"}
                                        defaultActiveFirstOption={false}
                                        disabled={!!excludedPipelines?.length}
                                        onChange={(newSelection) => {
                                            dispatch(setAllowedPipelines(newSelection));
                                            trackEvent("Map Search - Change Allowed Pipelines", {
                                                pipelines: pipelines?.results
                                                    ?.filter((d) => newSelection.includes(d.id))
                                                    .map((p) => p.name),
                                            });
                                        }}
                                        value={allowedPipelines || []}
                                        style={{
                                            width: "100%",
                                            height: 48,
                                            maxWidth: 374,
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row
                                align="middle"
                                justify="center"
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                <Col flex="64px">
                                    <Pipeline
                                        className="not-allowed-icon"
                                        style={{
                                            margin: "auto",
                                        }}
                                    />
                                </Col>
                                <Col flex="auto">
                                    <Select
                                        mode="multiple"
                                        maxTagCount={1}
                                        options={pipelines?.results?.map((p) => ({ label: p.name, value: p.id }))}
                                        allowClear
                                        loading={pipelines?.loading}
                                        maxTagTextLength={10}
                                        placeholder="Excluded Pipelines"
                                        className="pipelines-select"
                                        optionFilterProp={"label"}
                                        disabled={!!allowedPipelines?.length}
                                        onChange={(newSelection) => {
                                            dispatch(setExcludedPipelines(newSelection));
                                            trackEvent("Map Search - Change Excluded Pipelines", {
                                                pipelines: pipelines?.results
                                                    ?.filter((d) => newSelection.includes(d.id))
                                                    .map((p) => p.name),
                                            });
                                        }}
                                        value={excludedPipelines || []}
                                        defaultActiveFirstOption={false}
                                        style={{
                                            width: "100%",
                                            height: 48,
                                            maxWidth: 374,
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    ) : (
                        ""
                    )}
                    {!selectedTariff ? (
                        <Panel header="SPECIFIC PRODUCTS" key="products" className="divider-panel">
                            <Row style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                                <Col span={24}>
                                    <Select
                                        value={products.length ? selectedProducts || "" : ""}
                                        style={{
                                            width: "100%",
                                            maxWidth: 374,
                                        }}
                                        showSearch
                                        options={
                                            products.length
                                                ? products.map((prod) => ({ label: prod.name, value: prod.id }))
                                                : []
                                        }
                                        size="large"
                                        loading={!products.length}
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                        onSelect={(val) => {
                                            const product = products.find((p) => p.id === val);
                                            trackEvent("Map Search - Specific Products Filter", {
                                                product: product ? product.name : undefined,
                                            });
                                            dispatch(setSelectedProducts([val]));
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    ) : (
                        ""
                    )}
                    {!selectedTariff ? (
                        <Panel header="JURISDICTION" key="jurisdiction" className="divider-panel">
                            <Row style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                                <Col span={24}>
                                    <Select
                                        allowClear={true}
                                        value={loadingOptions ? "" : selectedJurisdiction || ""}
                                        style={{
                                            width: "100%",
                                            maxWidth: 374,
                                        }}
                                        showSearch
                                        options={!loadingOptions && jurisdictions ? jurisdictions : []}
                                        size="large"
                                        loading={loadingOptions}
                                        filterOption={(input, option) =>
                                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                        }
                                        onChange={(val) => dispatch(setSelectedJurisdiction(val && [val]))}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    ) : (
                        ""
                    )}
                    {!selectedTariff ? (
                        <Panel header="COMMODITY TYPE" key="commodities" className="divider-panel">
                            <Row style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                                <Col span={24}>
                                    <SelectCommodityType
                                        onChange={(value) => {
                                            dispatch(setCommodityType([value]));
                                            dispatch(setSelectedCommodities([]));
                                            dispatch(setCommodityType(value));
                                        }}
                                        value={commodityType}
                                        context="Map"
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    ) : (
                        ""
                    )}
                    <Panel header="EFFECTIVE" key="effective" className="divider-panel">
                        <Row style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                            <Col span={24}>
                                <Select
                                    value={selectedEffective || ""}
                                    style={{
                                        width: "100%",
                                        maxWidth: 374,
                                    }}
                                    size="large"
                                    loading={false}
                                    options={[
                                        { value: "", label: "Currently" },
                                        { value: "future", label: "Future" },
                                        { value: "all", label: "Current and Future" },
                                    ]}
                                    onSelect={(value) => {
                                        trackEvent("Map Search - Effective Filter", {
                                            filter: [
                                                { value: "", label: "Currently" },
                                                { value: "future", label: "Future" },
                                                { value: "all", label: "Current and Future" },
                                            ].find((option) => option.value === value).label,
                                        });
                                        dispatch(setSelectedEffective(value));
                                    }}
                                />
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>

                {!selectedTariff ? (
                    <Row
                        className="search-group"
                        align="middle"
                        justify="center"
                        style={{
                            marginTop: 20,
                            paddingTop: 0,
                        }}
                    >
                        <Col flex="auto">
                            <Row style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
                                <InputNumber
                                    size="large"
                                    style={{
                                        width: "100%",
                                        maxWidth: 374,
                                    }}
                                    value={volume}
                                    formatter={numberWithCommas}
                                    placeholder="Enter Volume"
                                    suffix="BOPD"
                                    onChange={handleVolumeChange}
                                />
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}

                <Row align="middle" justify="center" style={{ padding: 10, paddingTop: 20, paddingBottom: 20 }}>
                    <Col flex="50%" style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Link to={searchUrl} onClick={onRouteSearch}>
                            <Button
                                block
                                type={isSegmentSearch ? "secondary" : "primary"}
                                loading={loading}
                                style={{
                                    borderRadius: "20px",
                                    borderColor: "#126dff",
                                    color: isSegmentSearch ? "#126dff" : "white",
                                }}
                            >
                                Find Routes
                            </Button>
                        </Link>
                    </Col>
                    <Col flex="50%" style={{ paddingRight: 20 }}>
                        <Link
                            to={rateSearchUrl}
                            onClick={(e) => {
                                onRouteSearch(e, true);
                                e.preventDefault();
                                e.stopPropagation();
                                trackEvent("Map Search - Search Segments");
                            }}
                        >
                            <Button
                                block
                                type={isSegmentSearch ? "primary" : "secondary"}
                                loading={loading}
                                style={{
                                    borderRadius: "20px",
                                    borderColor: "#126dff",
                                    color: isSegmentSearch ? "white" : "#126dff",
                                }}
                            >
                                Search Segments
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row align="middle" justify="left" style={{ paddingBottom: 20, paddingLeft: 20 }}>
                    <Col flex="20%" align="left" style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Link to="/map/search" type="link" onClick={clearSearch} className="cancel-btn">
                            Clear
                        </Link>
                    </Col>
                </Row>
            </>
        );
    };

    const mainSearchSelect = (searchObj) => {
        if (searchObj.type === "SEARCH") {
            history.push(buildSearchUrlFromSavedProperties(searchObj.properties.saved.parameters));
            return;
        }

        setMainSearchOrigin(searchObj);
        if (searchObj.type === "station") {
            UpdateHash(history, [searchObj.type, searchObj.id]);
        } else if (searchObj.type === "station_group") {
            handleOriginSelect(searchObj);
        }
    };

    return (
        <div className="search-panel">
            {showSearch ? (
                renderForm(origin, destination)
            ) : (
                <div className="search-group-input">
                    <SearchAutocomplete
                        placeholder="Station, County, City, State, Hub"
                        isMainSearch={true}
                        setShowSearch={() => {
                            setShowSearch(true);
                            if (mainSearchOrigin) {
                                handleOriginSelect(mainSearchOrigin);
                            }
                        }}
                        onSelect={mainSearchSelect}
                    />
                </div>
            )}
        </div>
    );
}

export default SearchPanel;
