import React, { useState } from "react";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

import { ReactComponent as GaugeIcon } from "assets/Icon/sider/Gauge.svg";

import AnalyticsModal from "./AnalyticsModal";

export default function GaugeButton({ id, title, onClick, style = {}, tooltip, basin }) {
    const [modalOpen, setModalOpen] = useState(false);
    const clickHandler = (e) => {
        if (typeof onClick === "function") {
            onClick(e);
        } else {
            e.preventDefault();
            e.stopPropagation();
            setModalOpen(true);
        }
    };

    const Icon = () =>
        basin ? (
            <Link onClick={clickHandler} to={`/models/throughput/?tab=${basin}`}>
                <GaugeIcon className="gauge-icon" />
            </Link>
        ) : (
            <GaugeIcon onClick={clickHandler} className="gauge-icon" />
        );
    return (
        <div style={style}>
            {tooltip ? (
                <Tooltip title="Throughput Models">
                    <Icon />
                </Tooltip>
            ) : (
                <Icon />
            )}
            {id ? (
                <AnalyticsModal
                    basin={basin}
                    id={id}
                    title={title}
                    visible={modalOpen}
                    onCancel={() => setModalOpen()}
                />
            ) : (
                ""
            )}
        </div>
    );
}
