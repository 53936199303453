import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiContent from "api/api.content";

export const getArticles = createAsyncThunk("content/getArticles", ApiContent.getArticles);
export const getDocuments = createAsyncThunk("content/getDocuments", ApiContent.getDocuments);

export const contentSlice = createSlice({
    name: "content",
    initialState: {
        articles: null,
        documents: null,
    },
    extraReducers: {
        [getArticles.pending]: (state, { payload }) => {
            state.articles = {
                loading: true,
            };
        },
        [getArticles.fulfilled]: (state, { payload }) => {
            state.articles = {
                ...payload,
                indexed: payload?.results.reduce((acum, obj) => {
                    acum[obj.id] = obj;
                    return acum;
                }, {}),
            };
        },
        [getDocuments.pending]: (state, { payload }) => {
            state.documents = {
                loading: true,
            };
        },
        [getDocuments.fulfilled]: (state, { payload }) => {
            state.documents = {
                ...payload,
                indexed: payload?.results.reduce((acum, obj) => {
                    acum[obj.id] = obj;
                    return acum;
                }, {}),
            };
        },
    },
    reducers: {},
});

// eslint-disable-next-line
export const {} = contentSlice.actions;

export default contentSlice.reducer;
