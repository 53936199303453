import React from "react";
import { useSelector } from "react-redux";
import { message, Dropdown, Menu } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { downloadCsvFile } from "Utils";
import ApiLiquids from "api/api.liquids";
import { trackEvent } from "api/analytics";
import IconButton from "components/atoms/IconButton";

const ExportRates = ({
    children,
    tariff,
    tariffIds = "",
    tariffRateIds = "",
    onVisibleChange,
    showBanner,
    classes,
    onAction,
}) => {
    const { isArbTrackerUser } = useSelector(({ layout }) => layout);

    const exportMenu = (
        <Menu>
            <Menu.Item
                key="0"
                onClick={() => {
                    if (isArbTrackerUser) {
                        message.info("Download has started...");
                        const filename = tariffIds ? tariffIds.split(",").join("-") : tariff?.name;
                        trackEvent("Export Tariff Rates", filename);
                        const params = {
                            tariff_id__in: tariffIds || tariff?.id,
                            id__in: tariffRateIds,
                        };
                        ApiLiquids.exportTariffRates(params)
                            .then((csv) => {
                                downloadCsvFile(csv, filename);
                            })
                            .catch((error) => message.error(error));
                    } else if (showBanner) {
                        showBanner();
                    }

                    if (onAction) {
                        onAction("rates");
                    }
                }}
            >
                Export Tariff Rates
            </Menu.Item>
            <Menu.Item
                key="1"
                onClick={() => {
                    if (isArbTrackerUser) {
                        message.info("Download has started...");
                        const params = {
                            tariff_id: tariffIds || tariff?.id,
                        };
                        if (tariffRateIds) {
                            params.tariff_rate__in = tariffRateIds;
                        }
                        ApiLiquids.exportTariffRateDiscounts(params)
                            .then((csv) => {
                                downloadCsvFile(csv, `tariff-rate-discounts-${tariff?.id || "export"}`);
                            })
                            .catch((error) => message.error(error));
                    } else if (showBanner) {
                        showBanner();
                    }

                    if (onAction) {
                        onAction("discounts");
                    }
                }}
            >
                Export Tariff Rate Discounts
            </Menu.Item>
            <Menu.Item
                key="2"
                onClick={() => {
                    if (isArbTrackerUser) {
                        message.info("Download has started...");
                        const params = {
                            tariff_id: tariffIds || tariff?.id,
                            tariff_rate__in: tariffRateIds,
                        };
                        ApiLiquids.exportTariffRateExtraCharges(params)
                            .then((csv) => {
                                downloadCsvFile(csv, `tariff-extra-charges-${tariff?.id || "export"}`);
                            })
                            .catch((error) => message.error(error));
                    } else if (showBanner) {
                        showBanner();
                    }

                    if (onAction) {
                        onAction("charges");
                    }
                }}
            >
                Export Tariff Rate Extra Charges
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={exportMenu} onVisibleChange={onVisibleChange} className={classes}>
            {children || (
                <IconButton buttonStyles={{ color: "#3983f9", display: "flex", alignItems: "center" }}>
                    <DownloadOutlined className="download-icon text-xl" />
                    <span>EXPORT TABLE</span>
                </IconButton>
            )}
        </Dropdown>
    );
};

export default ExportRates;
