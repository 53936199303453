import React from "react";
import "./styles.scss";

import { Col, Layout, Row, Modal, Empty } from "antd";

import { ReactComponent as DownloadIcon } from "assets/Icon/Download.svg";

import IconButton from "components/atoms/IconButton";
import LoginForm from "../User/LoginForm";
import ArboLogo from "assets/logo/Light.svg";
import PDFImage from "./pdfScreenshot.png";
import { useHistory } from "react-router-dom";

const { Content } = Layout;

export default function Login() {
    const history = useHistory();
    return (
        <Content className="page-container">
            <Modal
                visible={true}
                maskClosable={false}
                width={400}
                footer={null}
                closable={false}
                bodyStyle={{ padding: 0 }}
                maskStyle={{ backdropFilter: "blur(2px)" }}
            >
                <Empty
                    className="narrow-page__header"
                    image={ArboLogo}
                    description={false}
                    style={{
                        borderRadius: 0,
                    }}
                    imageStyle={{
                        height: 40,
                    }}
                />
                <div style={{ padding: 30 }}>
                    <LoginForm
                        onLoginSuccess={() => {
                            history.push("/optm");
                        }}
                        title="Sign in to access Oil Pipeline Tariff Monitor"
                    />
                </div>
            </Modal>
            <div className="document-page">
                <Row>
                    <Col span={12} style={{ paddingLeft: 15 }}>
                        <label
                            style={{
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: "24px",
                                lineHeight: "15px",
                                display: "flex",
                                alignItems: "center",
                                color: "#000000",
                                marginBottom: "10px",
                            }}
                        >
                            Current Issue
                        </label>
                        <span
                            style={{
                                fontStyle: "italic",
                                fontWeight: "normal",
                                fontSize: "12px",
                                lineHeight: "15px",
                                display: "flex",
                                alignItems: "center",
                                color: "#000000",
                                marginBottom: "10px",
                            }}
                        >
                            Public 11/16/2021
                        </span>
                    </Col>
                    <Col span={12} style={{ align: "right", paddingLeft: 15 }}>
                        <div
                            className="d-flex"
                            style={{
                                textAlign: "right",
                                alignItems: "center",
                            }}
                        >
                            <IconButton className="download-btn">
                                <DownloadIcon /> Download PDF
                            </IconButton>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ paddingLeft: 15, paddingTop: 15 }}>
                        <div className="document-details__pdf">
                            <img src={PDFImage} alt="pdf" />
                        </div>
                    </Col>
                </Row>
            </div>
        </Content>
    );
}
