import React, { useEffect } from "react";
import { Modal, Spin } from "antd";

import TariffDetail from "./TariffDetail";
import FavoritesBanner from "../../pages/search/components/FavoritesBanner";
import { useSelector } from "react-redux";
import { useVisitedModalPipelinesTracking } from "../../pages/search/useVisitedModalPipelinesTracking";

export default function DocumentModal({ document, loading, visible, onCancel, enabledFavoritesBanner }) {
    const saveVisitedPipeline = useVisitedModalPipelinesTracking();
    const { modalBannerPipeline } = useSelector(({ search }) => search);

    useEffect(() => {
        if (document?.pipeline && enabledFavoritesBanner) {
            saveVisitedPipeline(document.pipeline.id, document.pipeline.name);
        }
    }, [document, enabledFavoritesBanner]);

    const showFavoritesModal = enabledFavoritesBanner && !!modalBannerPipeline;

    return (
        <Modal
            className="arbo-modal document-modal"
            destroyOnClose={true}
            footer={null}
            onCancel={onCancel}
            title="DOWNLOAD TARIFF PDF"
            visible={visible}
            width={960}
        >
            {document ? (
                <TariffDetail tariff={document}>
                    {showFavoritesModal ? <FavoritesBanner pipeline={modalBannerPipeline} /> : null}
                </TariffDetail>
            ) : (
                ""
            )}
            {loading ? <Spin /> : null}
        </Modal>
    );
}
