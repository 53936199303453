import React, { useEffect } from "react";
import ForwardButton from "../../../components/atoms/ForwardButton";
import { setBannerPipeline, setModalBannerPipeline } from "../../../reducers/searchSlice";
import { useDispatch } from "react-redux";
import ApiBookmarks from "../../../api/api.bookmarks";
import { getBookmarks } from "../../../reducers/bookmarksSlice";

export default function FavoritesBanner({ pipeline }) {
    const dispatch = useDispatch();

    useEffect(() => {
        let timeOut = setTimeout(() => {
            dispatch(setBannerPipeline(null));
            dispatch(setModalBannerPipeline(null));
        }, 10000);

        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            }
        };
    }, []);

    return (
        <div className="favorites-banner">
            <p>
                <b>Looks like you’re interested in {pipeline.name}</b>!
            </p>
            <p>Would you like to favorite this filer to be notified of new filings by email?</p>
            <ForwardButton
                onClick={() => {
                    ApiBookmarks.saveBookmark({
                        app_label: "liquids",
                        model: "pipeline",
                        object_id: pipeline.id,
                        data: {
                            filing: pipeline.name,
                        },
                    }).then((resp) => {
                        dispatch(getBookmarks());
                    });
                    dispatch(setBannerPipeline(null));
                    dispatch(setModalBannerPipeline(null));
                }}
            >
                ADD TO FAVORITES
            </ForwardButton>
        </div>
    );
}
