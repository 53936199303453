import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiLegacy from "api/legacy";

export const searchCompanies = createAsyncThunk("legacy/searchCompanies", async (params) =>
    ApiLegacy.getCompanies(params),
);
export const searchForms = createAsyncThunk("legacy/searchForms", async (params) => ApiLegacy.getForms6(params));

export const getCompanySuggestions = createAsyncThunk("legacy/getCompanySuggestions", async (params) =>
    Promise.all([
        ApiLegacy.getCompanies(params),
        // ApiLegacy.getOwnedCompanies(params)
    ]).then((responses) =>
        responses
            .flatMap((resp) => resp)
            .slice()
            .shift(),
    ),
);

export const getCompany = createAsyncThunk("legacy/getCompany", async (id) => ApiLegacy.getCompany(id));

export const getCompanyAlias = createAsyncThunk("legacy/getCompanyAlias", async (id) => ApiLegacy.getCompanyAlias(id));

export const legacySlice = createSlice({
    name: "legacy",
    initialState: {
        alias: {
            loading: false,
        },
        company: {
            loading: false,
        },
        companies: {
            loading: false,
            data: [],
        },
        companySuggestions: {
            loading: false,
            data: [],
        },
        forms: {
            loading: false,
            data: [],
        },
    },
    reducers: {
        setCompany: (state, { payload }) => {
            state.company = payload;
        },
    },
    extraReducers: {
        [getCompanyAlias.pending]: (state) => {
            state.alias = {
                loading: true,
            };
        },
        [getCompanyAlias.fulfilled]: (state, { payload }) => {
            state.alias = payload;
        },
        [getCompany.pending]: (state) => {
            state.company = {
                loading: true,
            };
        },
        [getCompany.fulfilled]: (state, { payload }) => {
            state.company = payload;
        },
        [getCompanySuggestions.pending]: (state) => {
            state.companySuggestions = {
                ...state.companySuggestions,
                loading: true,
            };
        },
        [getCompanySuggestions.fulfilled]: (state, { payload }) => {
            state.companySuggestions = payload;
        },
        [searchForms.pending]: (state) => {
            state.forms = {
                ...state.forms,
                loading: true,
            };
        },
        [searchForms.fulfilled]: (state, { payload }) => {
            state.forms = payload;
        },
        [searchCompanies.pending]: (state) => {
            state.companies = {
                ...state.companies,
                loading: true,
            };
        },
        [searchCompanies.fulfilled]: (state, { payload }) => {
            state.companies = payload;
        },
    },
});

export const { setCompany } = legacySlice.actions;

export default legacySlice.reducer;
