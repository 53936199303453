import React from "react";
import { Link } from "react-router-dom";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as Arrow } from "assets/Icon/Arrow/Right.svg";

import "./ForwardButton.less";

export default function ForwardButton({
    title,
    url,
    href,
    children,
    onClick,
    loading,
    target = "_self",
    right,
    ...props
}) {
    const RenderLink = ({ children }) => {
        return url ? (
            <Link {...props} onClick={onClick} to={url} target={target}>
                {children}
            </Link>
        ) : href ? (
            <a {...props} onClick={onClick} href={href} target={target}>
                {children}
            </a>
        ) : (
            <button {...props} onClick={onClick}>
                {children}
            </button>
        );
    };
    return (
        <RenderLink>
            <div className="forward-button">
                {title || children}
                {loading ? <Spin indicator={<LoadingOutlined spin />} /> : <Arrow />}
            </div>
        </RenderLink>
    );
}
