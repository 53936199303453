import React from "react";
import { MehOutlined as ErrorIcon } from "@ant-design/icons";

import { logError } from "api/errorLogging";
import ErrorPage from "pages/ErrorPage";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        logError(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <ErrorPage title="Something went wrong.">
                    <div style={{ padding: 24, textAlign: "center" }}>
                        <ErrorIcon style={{ fontSize: 32 }} />
                    </div>
                    <div style={{ padding: 12, textAlign: "center" }}>
                        An error was found. We will investigate this issue.
                    </div>
                </ErrorPage>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
