import React from "react";
import { useDispatch } from "react-redux";
import { Badge, Card, Col, Row } from "antd";

import { setModal } from "reducers/layoutSlice";
import ForwardButton from "components/atoms/ForwardButton";
import { trackEvent } from "api/analytics";
import "./styles.less";
import clsx from "clsx";

export default function ({ count, text, banner, contactLink, className = "" }) {
    const dispatch = useDispatch();

    if (banner) {
        return (
            <div className={clsx("free-feature-count", className)}>
                <p dangerouslySetInnerHTML={{ __html: text }} />
                {contactLink ? (
                    <ForwardButton href="http://goarbo.com/contact" className="white-btn">
                        Contact us to upgrade.
                    </ForwardButton>
                ) : (
                    <ForwardButton url="/plans" className="white-btn">
                        Compare plans
                    </ForwardButton>
                )}
            </div>
        );
    }

    return (
        <Badge
            dot={false}
            count={count > 0 ? count : 0}
            showZero={true}
            style={{ background: count > 5 ? "#126DFF" : count > 1 ? "#FE8065" : "#FF7579" }}
        >
            <Card
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                    dispatch(
                        setModal({
                            text,
                        }),
                    );
                }}
            >
                <Row>
                    <Col span={16}>
                        <div style={{ lineHeight: 1.4 }}>
                            You've got {count > 0 ? count : "no"} remaining view{count === 1 ? "" : "s"} on the free
                            plan.
                        </div>
                    </Col>
                    <Col span={8}>
                        <ForwardButton
                            onClick={(e) => {
                                e.stopPropagation();
                                trackEvent("Arb Tracker - Click See Plans");
                            }}
                            url="/plans"
                        >
                            See plans
                        </ForwardButton>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <i> Upgrade to Pro for unlimited access.</i>
                    </Col>
                </Row>
            </Card>
        </Badge>
    );
}
