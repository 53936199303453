import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import "./IconButton.less";

export default function IconButton({ url, children, onClick, loading, style, buttonStyles, ...props }) {
    const RenderLink = ({ children }) => {
        return url ? (
            <Link onClick={onClick} to={url}>
                {children}
            </Link>
        ) : (
            <span onClick={onClick}>{children}</span>
        );
    };
    return (
        <div className="icon-button" style={style} {...props}>
            <RenderLink>
                <Button className="icon-button" style={buttonStyles} loading={loading || false} type="link">
                    {children}
                </Button>
            </RenderLink>
        </div>
    );
}
