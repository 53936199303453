import React from "react";
import { Row, Col, Layout } from "antd";

import "./BasicPage.less";

const { Content } = Layout;

export default function BasicPage({ children }) {
    return (
        <>
            <Content className="basic-page">
                <Row
                    style={{
                        marginBottom: 24,
                        marginLeft: 20,
                    }}
                >
                    <Col sm={24}>{children}</Col>
                </Row>
            </Content>
        </>
    );
}
