import React from "react";
import PropTypes from "prop-types";

const initialState = {
    currencies: [],
    volumen_measurements: [],
    states: [],
};

const store = React.createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer((state, action) => {
        switch (action.type) {
            case "set currencies":
                return { ...state, currencies: action.payload };
            case "set volumen measurements":
                return { ...state, volumen_measurements: action.payload };
            case "set states":
                return { ...state, states: action.payload };
            default:
                return state;
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { store, StateProvider };
