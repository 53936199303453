import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Tag } from "antd";

import { ReactComponent as CloseX } from "assets/Icon/x.svg";

function SearchPill({ onRemove, includePrefix, label }) {
    return (
        <div
            className="ant-select main-search-autocomplete ant-select-auto-complete ant-select-single ant-select-customize-input ant-select-show-search"
            style={{ width: "100%", height: 48 }}
        >
            <div className="ant-select-selector">
                <span className="ant-select-selection-search">
                    <span
                        className="ant-select-selection-search-input ant-input-affix-wrapper ant-input-affix-wrapper-lg"
                        style={{ height: 48 }}
                    >
                        {includePrefix && (
                            <span className="ant-input-prefix">
                                <span className="anticon anticon-search">
                                    <SearchOutlined />
                                </span>
                            </span>
                        )}

                        <Tag closable={true} className="custom-region-tag" closeIcon={<CloseX />} onClose={onRemove}>
                            {label}
                        </Tag>
                    </span>
                </span>
            </div>
        </div>
    );
}

export default SearchPill;
