import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Skeleton } from "antd";
import { useQueryParams, withDefault, StringParam, NumberParam } from "use-query-params";

import {
    getAsyncResponse,
    loadNextPage,
    setAvoidSteps,
    setAllowedPipelines,
    setCommittedRates,
    setCommodityGrade,
    setCommodityType,
    setDestination,
    setExcludedPipelines,
    setMaxPipelines,
    setOrigin,
    setPage,
    setSelectedCommodities,
    setSelectedEffective,
    setSelectedJurisdiction,
    setSelectedPath,
    setSelectedProducts,
    setTariffId,
    setVolume,
} from "reducers/searchSlice";
import { updateCustomRegion } from "reducers/layoutSlice";
import PathCard from "./pathCard";
import SearchMenu from "./searchMenu";
import SearchMessage from "./searchMessage";
import { AUTOCOMPLETE_TYPES } from "components/searcher/searchAutocomplete";
import Suggestions from "components/organisms/Suggestions";
import UpdateHash, { loadSearch } from "components/utils/UpdateHash";

const AsyncResults = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [response, setResponse] = useState();
    const [nextPageUrl, setNextPageUrl] = useState("");

    const {
        allowedPipelines,
        asyncLoading,
        asyncResponse,
        asyncSearch,
        bestPaths,
        commodityType,
        excludedPipelines,
        loading,
        page,
        selectedPath,
        selectedCommodities,
        selectedProducts,
        searchUrl,
        tariffId,
    } = useSelector(({ search }) => search);

    const { corridorDetails } = useSelector(({ tracker }) => tracker);

    useEffect(() => {
        if (asyncResponse && Object.values(asyncResponse).length) {
            const search_id = window.location.pathname.split("/").pop();
            if (search_id) {
                setResponse(asyncResponse[search_id]);
            } else {
                setResponse(Object.values(asyncResponse).pop());
            }
        }
    }, [asyncResponse]);

    useEffect(() => {
        if (response?.data) {
            let origin = null;
            let destination = null;
            const { data, parameters } = response;

            if (!isNaN(parameters?.page)) {
                dispatch(setPage(parameters.page));
            }

            if (data.origin_station) {
                origin = {
                    id: data.origin_station.id,
                    name: data.origin_station.properties?.name,
                    type: "station",
                };
            } else if (data.origin_station_group) {
                origin = {
                    id: data.origin_station_group.id,
                    name: data.origin_station_group.name,
                    type: "station_group",
                };
            } else if (data.origin_polygon) {
                origin = {
                    name: parameters.origin_polygon.name || "Custom Region",
                    type: "polygon",
                    geometry: data.origin_polygon,
                };
                dispatch(
                    updateCustomRegion({
                        type: AUTOCOMPLETE_TYPES.ORIGIN,
                        geometry: data.origin_polygon,
                    }),
                );
            }

            if (data.destination_station) {
                destination = {
                    id: data.destination_station.id,
                    name: data.destination_station.properties?.name,
                    type: "station",
                };
            } else if (data.destination_station_group) {
                destination = {
                    id: data.destination_station_group.id,
                    name: data.destination_station_group.name,
                    type: "station_group",
                };
            } else if (data.destination_polygon) {
                destination = {
                    name: parameters.destination_polygon.name || "Custom Region",
                    type: "polygon",
                    geometry: data.destination_polygon,
                };
                dispatch(
                    updateCustomRegion({
                        type: AUTOCOMPLETE_TYPES.DESTINATION,
                        geometry: data.destination_polygon,
                    }),
                );
            }
            if (data.origin_station && !destination) {
                destination = { name: "All" };
            }
            if (data.destination_station && !origin) {
                origin = { name: "All" };
            }
            data.allowed_pipelines?.length && dispatch(setAllowedPipelines(data.allowed_pipelines.map((d) => +d.id)));
            data.forbidden_pipelines?.length &&
                dispatch(setExcludedPipelines(data.forbidden_pipelines.map((d) => +d.id)));

            origin && dispatch(setOrigin(origin));
            destination && dispatch(setDestination(destination));
        } else if (response?.parameters) {
            const { parameters } = response;
            parameters.origin_station && dispatch(setOrigin({ id: parameters.origin_station, type: "station" }));
            parameters.destination_station &&
                dispatch(setDestination({ id: parameters.destination_station, type: "station" }));
            parameters.origin_station_group &&
                dispatch(setOrigin({ id: parameters.origin_station_group, type: "station_group" }));
            parameters.destination_station_group &&
                dispatch(setDestination({ id: parameters.destination_station_group, type: "station_group" }));

            if (parameters.origin_polygon) {
                dispatch(
                    setOrigin({
                        geometry: parameters.origin_polygon,
                        name: parameters.origin_polygon.name || "Custom Region",
                        type: "polygon",
                    }),
                );
                dispatch(
                    updateCustomRegion({
                        type: AUTOCOMPLETE_TYPES.ORIGIN,
                        name: parameters.origin_polygon.name || "Custom Region",
                        geometry: parameters.origin_polygon,
                    }),
                );
            }
            if (parameters.destination_polygon?.coordinates) {
                dispatch(
                    setDestination({
                        geometry: parameters.destination_polygon,
                        name: parameters.destination_polygon.name || "Custom Region",
                        type: "polygon",
                    }),
                );
                dispatch(
                    updateCustomRegion({
                        type: AUTOCOMPLETE_TYPES.DESTINATION,
                        name: parameters.destination_polygon.name || "Custom Region",
                        geometry: parameters.destination_polygon,
                    }),
                );
            }
        }
        if (response?.parameters) {
            if (response?.parameters?.jurisdiction) {
                dispatch(setSelectedJurisdiction(response?.parameters.jurisdiction));
            }

            dispatch(setTariffId(response.parameters.tariff_id));
            dispatch(
                setAvoidSteps({
                    origin: response?.parameters?.forbidden_steps_in_origin_station_group,
                    destination: response?.parameters?.forbidden_steps_in_destination_station_group,
                }),
            );
            if (response?.parameters.allow_current_rates && !response?.parameters.allow_future_rates) {
                dispatch(setSelectedEffective("current"));
            } else if (!response?.parameters.allow_current_rates && response?.parameters.allow_future_rates) {
                dispatch(setSelectedEffective("future"));
            } else if (response?.parameters.allow_current_rates && response?.parameters.allow_future_rates) {
                dispatch(setSelectedEffective("all"));
            }
        }

        response?.parameters?.max_pipelines && dispatch(setMaxPipelines(response.parameters.max_pipelines));
    }, [response, dispatch]);

    useEffect(() => {
        if (response?.parameters?.commodity_grade) {
            dispatch(setCommodityGrade(response.parameters.commodity_grade));
        }
        if (response?.parameters?.committed !== undefined) {
            dispatch(setCommittedRates(response.parameters.committed));
        }
    }, [response?.parameters, dispatch]);

    useEffect(() => {
        if (response?.parameters) {
            const { parameters } = response;
            if (!commodityType && parameters.commodity_types?.length) {
                dispatch(setCommodityType(parameters.commodity_types));
            }
        }
        // eslint-disable-next-line
    }, [response?.parameters, dispatch, commodityType]);

    useEffect(() => {
        if (response?.parameters) {
            const { parameters } = response;
            if (!allowedPipelines && parameters.allowed_pipelines?.length) {
                dispatch(setAllowedPipelines(parameters.allowed_pipelines.map((id) => +id)));
            }
        }
        // eslint-disable-next-line
    }, [response?.parameters, dispatch, allowedPipelines]);

    useEffect(() => {
        if (response?.parameters) {
            const { parameters } = response;
            if (!excludedPipelines && parameters.forbidden_pipelines?.length) {
                dispatch(setExcludedPipelines(parameters.forbidden_pipelines.map((id) => +id)));
            }
        }
        // eslint-disable-next-line
    }, [response?.parameters, dispatch, excludedPipelines]);

    useEffect(() => {
        if (response?.parameters) {
            const { parameters } = response;
            if (!selectedCommodities && parameters.commodities?.length) {
                dispatch(setSelectedCommodities(parameters.commodities));
            }
        }
        // eslint-disable-next-line
    }, [response?.parameters, dispatch, selectedCommodities]);

    useEffect(() => {
        if (response?.parameters) {
            const { parameters } = response;
            if (!selectedProducts && parameters.specific_products?.length) {
                dispatch(setSelectedProducts(parameters.specific_products));
            }
        }
        // eslint-disable-next-line
    }, [response?.parameters, dispatch, selectedProducts]);

    useEffect(() => {
        if (response?.parameters) {
            const { parameters } = response;
            if (parameters.volume) {
                dispatch(setVolume(parameters.volume));
            }
        }
        // eslint-disable-next-line
    }, [response?.parameters, dispatch]);

    useEffect(() => {
        if (asyncSearch) {
            const [id] = Object.keys(asyncSearch);
            const search_id = location.pathname.split("/").pop();
            if (id && +search_id !== +id) {
                window.history.replaceState(null, "Map Search", `/map/search/${id}`);
                dispatch(getAsyncResponse({ id }));
                dispatch(setSelectedPath());
            }
        }
    }, [asyncSearch, location, history]);

    useEffect(() => {
        loadNextUrl();
    }, [searchUrl, response]);

    const loadNextUrl = useCallback(() => {
        if (!response) {
            return;
        }
        const { data, parameters } = response;
        const page = parameters?.page || 0;
        const page_size = data?.max_returned_paths || 25;
        const offset = page_size * page;
        const next_count = offset + page_size;
        let nextPageUrl;
        if (parameters?.max_path_steps && data?.path_count > next_count) {
            const url = new URL(document.location.origin + searchUrl);
            const parts = url.search.slice(1).split("&");
            const params = parts.reduce((acum, obj) => {
                const [key, val] = obj.split("=");
                acum[key] = val;
                return acum;
            }, {});
            params.page = page + 1;
            params.max_path_steps = 2;
            const nextUrl = Object.keys(params)
                .reduce((acum, key) => {
                    acum.push([key, params[key]].join("="));
                    return acum;
                }, [])
                .join("&");
            nextPageUrl = "/map/search?" + nextUrl;
        }
        setNextPageUrl(nextPageUrl);
    }, [searchUrl, response, location]);

    useEffect(() => {
        if (response?.id && !response?.data && response?.success !== false) {
            setTimeout(() => {
                dispatch(getAsyncResponse({ id: response.id }));
            }, 2000);
        }
        // eslint-disable-next-line
    }, [response, dispatch]);

    return (
        <>
            {response?.data && !asyncLoading && !loading ? <SearchMenu searchData={bestPaths} /> : ""}
            <SearchMessage />
            {corridorDetails?.loading || loading || asyncLoading ? <Skeleton className="p-12" active /> : ""}
            {bestPaths?.length
                ? bestPaths.map((path, idx) => <PathCard key={idx} path={path} idx={idx} bestPath={idx === 0} />)
                : ""}
            {nextPageUrl && bestPaths?.length ? (
                <div className="p-4 text-center">
                    <Link
                        to={nextPageUrl}
                        onClick={(e) => {
                            if (nextPageUrl) {
                                const location = new URL(e.target.href);
                                loadSearch(location, dispatch);
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                    >
                        Load more
                    </Link>
                </div>
            ) : (
                ""
            )}

            {/* @ToDo: improve logic to select what to display on left search drawer */}
            {!Object.values(asyncResponse || {}).length &&
                !loading &&
                !asyncLoading &&
                !selectedPath &&
                !corridorDetails && <Suggestions />}
        </>
    );
};

export default AsyncResults;
