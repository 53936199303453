import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row } from "antd";

import { setModal } from "reducers/layoutSlice";
import ForwardButton from "components/atoms/ForwardButton";

export default function ({ text }) {
    const dispatch = useDispatch();
    const modal = useSelector(({ layout }) => layout.modal);
    const [textItems, setItems] = useState();
    const [hidePlans, setHidePlans] = useState(false);

    useEffect(() => {
        return () => dispatch(setModal());
    }, []);

    useEffect(() => {
        const value = modal?.text || text;
        const items = value instanceof Array ? value : [value];

        setHidePlans(modal?.hidePlans || false);
        setItems(items);
    }, [modal, text]);

    const footer = (
        <>
            {modal?.contactus ? (
                <div className="mb-4">
                    <a href="https://www.goarbo.com/contact" target="_blank">
                        Contact us
                    </a>{" "}
                    for pricing.
                </div>
            ) : (
                <Row>
                    <Col span={12}>
                        <ForwardButton href="https://www.goarbo.com/contact" target="_blank">
                            CONTACT US
                        </ForwardButton>
                    </Col>
                </Row>
            )}
        </>
    );

    return modal ? (
        <Modal
            className="arbo-modal"
            title={modal?.title || "SUBSCRIBE TO ARBO"}
            footer={null}
            visible={modal}
            onCancel={() => {
                dispatch(setModal(false));
            }}
        >
            {textItems.map((item, idx) => (
                <div key={idx} className="mb-5">
                    {item}
                </div>
            ))}
            {modal?.footer !== undefined ? modal.footer : footer}
        </Modal>
    ) : null;
}
