import { configureStore } from "@reduxjs/toolkit";
import analyticsReducer from "reducers/analyticsSlice";
import bookmarksReducer from "reducers/bookmarksSlice";
import contentReducer from "reducers/contentSlice";
import layoutReducer from "reducers/layoutSlice";
import legacyReducer from "reducers/legacySlice";
import mapReducer from "reducers/mapSlice";
import searchReducer from "reducers/searchSlice";
import settingsSlice from "reducers/settingsSlice";
import tariffSlice from "reducers/tariffSlice";
import trackerSlice from "reducers/trackerSlice";
import userSlice from "reducers/userSlice";

export default configureStore({
    reducer: {
        analytics: analyticsReducer,
        bookmarks: bookmarksReducer,
        content: contentReducer,
        layout: layoutReducer,
        legacy: legacyReducer,
        map: mapReducer,
        search: searchReducer,
        settings: settingsSlice,
        tariff: tariffSlice,
        tracker: trackerSlice,
        user: userSlice,
    },
});
