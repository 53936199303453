import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiLiquids from "../api/api.liquids";
import ApiBookmarks from "../api/api.bookmarks";

export const loadBasinStationGroups = createAsyncThunk("settings/loadBasinStationGroups", async () => {
    const response = await ApiLiquids.getStationsGroups({
        public: true,
        limit: 100,
        category__name: "Basin",
    });
    return response.data;
});

export const loadPricingStationGroups = createAsyncThunk("settings/loadPricingStationGroups", async () => {
    const response = await ApiLiquids.getStationsGroups({
        public: true,
        limit: 100,
        category__name: "Market Hub",
    });
    return response.data;
});

export const getPreferences = createAsyncThunk("settings/getPreferences", async () => {
    const response = await ApiBookmarks.getPreferences();
    return response.data;
});

export const getSavedCommoditiesTypes = createAsyncThunk("settings/getSavedCommoditiesTypes", async () => {
    const response = await ApiBookmarks.getSavedCommodityTypes();
    return response.data.results;
});

export const getSavedPipelines = createAsyncThunk("settings/getSavedPipelines", async () => {
    const response = await ApiBookmarks.getSavedPipelines();
    return response.data.results;
});

export const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        commodities: null,
        role: null,
        basins: null,
        basinStationGroups: null,
        loadingBasinStationGroups: false,
        pricingStationGroups: null,
        savedStationGroups: null,
        savedCommoditiesTypes: null,
        loadingCommoditiesTypes: false,
        savedPipelines: null,
        loadingPipelines: null,
        preferences: null,
    },
    reducers: {
        setCommodities: (state, { payload }) => {
            state.commodities = payload;
        },
        setRole: (state, { payload }) => {
            state.role = payload;
        },
        setBasins: (state, { payload }) => {
            state.basins = payload;
        },
    },
    extraReducers: {
        [loadBasinStationGroups.pending]: (state) => {
            state.loadingBasinStationGroups = true;
        },
        [loadBasinStationGroups.fulfilled]: (state, { payload }) => {
            state.basinStationGroups = payload?.results.features.map((d) => ({ id: d.id, ...d.properties }));
            state.loadingBasinStationGroups = false;
        },
        [loadPricingStationGroups.pending]: (state) => {
            state.pricingStationGroups = {
                loading: true,
            };
        },
        [loadPricingStationGroups.fulfilled]: (state, { payload }) => {
            state.pricingStationGroups = {
                loading: false,
                results: payload?.results.features
                    .map((d) => ({ id: d.id, ...d.properties }))
                    .filter((d) => d.category === "Market Hub"),
            };
        },
        [getPreferences.pending]: (state) => {
            state.preferences = {
                ...state.preferences,
                loading: true,
            };
        },
        [getPreferences.fulfilled]: (state, { payload }) => {
            const preferences = {
                ...payload,
                indexed: payload?.results.reduce((acum, obj) => {
                    if (!acum[obj.content_type.model]) {
                        acum[obj.content_type.model] = {};
                    }
                    acum[obj.content_type.model][obj.object_id] = obj;
                    return acum;
                }, {}),
            };
            preferences.results.map((obj) => {
                if (!preferences[obj.content_type.model]) {
                    preferences[obj.content_type.model] = [];
                }
                preferences[obj.content_type.model].push(obj);
            });
            state.preferences = preferences;
        },
        [getSavedCommoditiesTypes.pending]: (state) => {
            state.loadingCommoditiesTypes = true;
        },
        [getSavedCommoditiesTypes.fulfilled]: (state, { payload }) => {
            state.loadingCommoditiesTypes = false;
            state.savedCommoditiesTypes = payload;
        },
        [getSavedPipelines.pending]: (state) => {
            state.loadingPipelines = true;
        },
        [getSavedPipelines.fulfilled]: (state, { payload }) => {
            state.loadingPipelines = false;
            state.savedPipelines = payload;
        },
    },
});

export const { setCommodities, setRole } = settingsSlice.actions;

export default settingsSlice.reducer;
