import React from "react";

import { Button as AntButton } from "antd";

import "./Button.less";

export default function Button({ children, text, type = "primary", onClick, round = true, disabled, loading }) {
    const classes = ["ant-btn", "button", `button--${type}`];
    if (round) {
        classes.push("button--round");
    }
    return (
        <AntButton type={type} className={classes.join(" ")} onClick={onClick} disabled={disabled} loading={loading}>
            {[text, children]}
        </AntButton>
    );
}
