import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "antd";

import Text from "antd/es/typography/Text";
import { setMessage } from "reducers/searchSlice";

const SearchMessage = () => {
    const dispatch = useDispatch();
    const { asyncResponse, asyncSearch, asyncLoading, message } = useSelector(({ search }) => search);

    useEffect(() => {
        if (asyncSearch?.message) {
            dispatch(
                setMessage({
                    title: asyncSearch?.message?.title || "Request sent successfully",
                    body: [asyncSearch.message],
                }),
            );
        }
    }, [asyncSearch, dispatch]);

    useEffect(() => {
        if (asyncLoading) {
            dispatch(
                setMessage({
                    title: "Request sent successfully",
                    body: ["Your request is being processed."],
                }),
            );
        } else {
            const response = Object.values(asyncResponse).pop();
            if (response?.data) {
                const { data, paths } = response;
                const message =
                    paths?.length || data?.paths?.length
                        ? null
                        : {
                              title: "No Results",
                              body: [
                                  "There are no connecting routes between your origin and destination selections.",
                                  "Choose a compatible station, change the commodity dropdown, or expand your search by drawing your origin or destination group on the map.",
                              ],
                          };
                dispatch(setMessage(message));
            }
        }
    }, [asyncResponse, asyncLoading, dispatch]);

    const messageCard = useCallback(() => {
        return message ? (
            <Row className="message-card" style={{ padding: 12, borderBottom: "none" }}>
                <Text className="message-card__title" style={{ padding: 12 }}>
                    {message?.title}
                </Text>
                <Text className="message-card__body" style={{ padding: 12 }}>
                    {message?.body?.map((text, idx) => (
                        <div key={idx} style={{ paddingBottom: 12 }}>
                            {text}
                        </div>
                    ))}
                </Text>
            </Row>
        ) : null;
    }, [message]);

    return messageCard();
};

export default SearchMessage;
