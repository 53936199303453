import axios from "axios";

const baseURL = `${process.env.REACT_APP_API_BASE_URL}api`;
const searchApi = axios.create({
    baseURL,
});

const getDocument = async (id) => axios.get(`/liquids/tariff/${id}`);

const getDocumentPdf = async (id) => {
    const url = `/liquids/tariff/${id}/document`;
    const response = await axios.get(url, {
        responseType: "arraybuffer",
    });
    return new Uint8Array(response.data);
};

const searchDocuments = async (params) => {
    const { query, pipeline, page = 1, page_size, status = undefined, type, commodities } = params;
    const extra = {
        status,
        pipeline,
        commodities,
        heading_types: type ? +type : undefined,
    };
    return axios.post("/search/documents/", {
        app: "arbo",
        model: "tariff",
        query,
        extra,
        page,
        page_size,
    });
};

const getSearchSuggestions = (search, params = {}) =>
    axios.post("/search/documents/", {
        app: "arbo",
        query: search,
        model: "tariff",
        ...params,
    });

export default {
    getDocument,
    getDocumentPdf,
    getSearchSuggestions,
    searchDocuments,
};
