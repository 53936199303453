import React, { useState } from "react";

import { message, Alert, Button, Col, Input, Modal, Row } from "antd";
import Text from "antd/es/typography/Text";

import { capitalizeFirstLetter } from "Utils";
import API from "api/";
import amplitude from "amplitude-js";
import moment from "moment";
import { useSelector } from "react-redux";

const { TextArea } = Input;

const ShareModal = ({ visible, onClose, entityName, url }) => {
    const [values, setValues] = useState();
    const [messages, setMessages] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const user = useSelector(({ layout }) => layout.user);

    const SHARE_DISABLED = true;

    const sendAction = async (e, value) => {
        const emails =
            values &&
            values
                .split("\n")
                .map((e) => e.split(","))
                .flatMap((e) => e)
                .map((e) => e.split(" "))
                .flatMap((e) => e)
                .map((e) => e.trim())
                .filter((d) => d);
        if (!values?.length) {
            message.error("Please enter a list of emails.");
            return;
        }
        setLoading(true);

        API.emailShare({ emails })
            .then((resp) => {
                setLoading(false);
                message.info("Email sent.");
                amplitude.getInstance().logEvent("referral sent", {
                    "refferer id": user.id,
                    user: emails,
                    "referral sent date": moment().toISOString(),
                });
                onClose();
            })
            .catch((errors) => {
                const msgs = [];
                Object.keys(errors).forEach((key) => {
                    const label = key === "non_field_errors" ? "" : capitalizeFirstLetter(key) + ": ";
                    const msg = label + errors[key];
                    msgs.push(msg);
                    message.warning(msg);
                });
                setErrorMessages(msgs);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            visible={visible}
            width={480}
            className="arbo-modal"
            footer={null}
            destroyOnClose={true}
            afterClose={() => {
                setValues(undefined);
            }}
            onCancel={onClose}
            title={`Share ${entityName || ""}`}
        >
            <Row>
                <Col span={24}>
                    <Row>
                        {!!errorMessages.length && (
                            <Col span={24}>
                                {errorMessages.map((err, idx) => (
                                    <div key={idx} span={24} style={{ marginBottom: 24 }}>
                                        <Alert message={err} type="error" closable showIcon />
                                    </div>
                                ))}
                            </Col>
                        )}
                        <Col span={24}>
                            <Text className="input-label">Recipient/s</Text>
                            <Input
                                autoFocus
                                rows={4}
                                style={{
                                    marginTop: 8,
                                }}
                                onChange={(e) => {
                                    setValues(e.target.value);
                                }}
                                value={values}
                            />
                            <Text style={{ marginBottom: 12 }} className="input-description">
                                Enter a list of emails, comma separated or one email per line.
                            </Text>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <Text className="input-label">Message</Text>
                            <TextArea
                                rows={4}
                                style={{
                                    marginTop: 8,
                                }}
                                onChange={(e) => {
                                    setMessages(e.target.value);
                                }}
                                value={messages}
                            />
                        </Col>
                        <Col span={12} style={{ marginTop: 12 }}>
                            <Button
                                type="primary"
                                loading={loading}
                                onClick={sendAction}
                                disabled={SHARE_DISABLED}
                                style={{
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    marginRight: 15,
                                }}
                            >
                                Send
                            </Button>
                        </Col>
                        <Col
                            span={12}
                            style={{
                                marginTop: 15,
                                textAlign: "right",
                            }}
                        >
                            <Button type="link" onClick={onClose} className="cancel-btn">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default ShareModal;
