import React, { lazy, Suspense } from "react";
import { Col, Modal, Row, Spin } from "antd";

import "./AnalyticsModal.less";

const ThroughputModelCard = lazy(() => import("pages/models/components/ThroughputModelCard"));
export default function AnalyticsModal({ basin, id, title, visible, onCancel }) {
    return (
        <Modal
            className="analytics-modal"
            destroyOnClose={true}
            footer={null}
            onCancel={(e) => {
                onCancel();
                e.stopPropagation();
            }}
            title={`Throughput Model`}
            visible={visible}
            width="60%"
            height="60%"
        >
            <Row>
                <Col span={24}>
                    <Suspense fallback={<Spin />}>
                        <ThroughputModelCard
                            basin={basin}
                            alias_id={id}
                            title={title}
                            modal={true}
                            onCancel={() => onCancel()}
                        />
                    </Suspense>
                </Col>
            </Row>
        </Modal>
    );
}
